// - Variavéis Globais do aplicativo

// Taxa 3,5%
const Percentage = [0.221481, 0.187668, 0.163544, 0.145476, 0.131446, 0.120241, 0.111091, 0.103483];

/*------------------------------------------------------------------------------------------------------------
  Taxa 3,0% -> [0.21835457, 0.1845975, 0.16050635, 0.14245639, 0.12843386, 0.11723051, 0.10807745, 0.10046209]
--------------------------------------------------------------------------------------------------------------*/

const date = new Date();

const yesterdayDate = `${String(date.getDate()).length === 1
  ? `0${date.getDate()}`
  : `${date.getDate()}`
  }/${String(date.getMonth() + 1).length === 1
    ? `0${date.getMonth() + 1}`
    : `${date.getMonth() + 1}`
  }/${date.getFullYear()} ${date.toTimeString().split(" ")[0]}`;

module.exports = { Percentage, yesterdayDate };
