import React, { useState, useEffect } from 'react';
import Card from '../../../../components/Card/Card.jsx';
import Button from '../../../../components/CustomButton/CustomButton.jsx';
import api from '../../../../services/api.jsx';
import down from '../../../../assets/img/down-arrow.png';
import { getToken } from '../../../../services/autenticacoes.jsx';
import MyModal from '../../../../components/Modal/MyModal.jsx';
import ModalConfirmacao from '../../../../components/Modal/ModalConfirmacao.jsx';

import {
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Grid,
  Image,
  Row,
  Table,
} from 'react-bootstrap';

export default function Correcao() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [ajuste, setAjuste] = useState(0);
  const [error, setError] = useState(false);
  const [visivel, setVisivel] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    async function listarEmpresas() {
      try {
        const resposta = await api.get('/administrativo/empresas', {
          headers: { Authorization: `Bearer ${getToken()}`, acess: 0 },
        });
        if (resposta.data.error) {

        } else {
          let empresas = resposta.data.map((item) => {
            return {...item, ajuste }
          });
          setData(empresas);
          setFilteredData([]);
        }
      } catch (error) {
        setError(true);
      }
    }
    listarEmpresas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function refreshScreen() {
      setRefresh(false);
    }
    refreshScreen();
  }, [refresh]);

  const ajustarLimite = () => {
    let empresas = data.map((item) => {
      return {...item, ajuste}
    });
    setData(empresas);
    setRefresh(true);
  }
  
  const setarLimiteEmpresa = (ajuste, key) => {
    let empresas = data;
    data[key].ajuste = ajuste;
    setData(empresas);
    setRefresh(true);
  }

  const filtrar = (coluna, valor) => {
    setSearchTerm(valor);
    setFilteredData(valor.trim() !== ''? data.filter((item) => item[coluna].indexOf(valor) >= 0 ) : data);
    setRefresh();
  }

  const getHighlightedText = (text) => {    
    const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'));
    return <span> { parts.map((part, i) => 
        <span key={i} style={part.toLowerCase() === searchTerm.toLowerCase() ? { fontWeight: 'bold', backgroundColor: 'yellow' } : {} }>
            { part }
        </span>)
    } </span>;
  }

  async function baixarListaConferencia() {
    try {
      const { data } = await api.get(
        `/administrativo/empresas/conferencia-limites`,
        { responseType: 'blob', headers: { Authorization: `Bearer ${getToken()}`, acess: 0 } }
      );

      if (data.error) {
        alert(data.message);
      } else {
        const blob = new Blob([data], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = url;
        link.download = 'conferencia-limites.csv';
        link.click();

        window.URL.revokeObjectURL(url);
      }

    } catch (error) {
      console.log(error);
    }
  }

  async function ajustarLimites() {
    const body = data.map((empresa) => {
      return {
        _id: empresa._id,
        ajuste: empresa.ajuste ?? 0
      }
    })
    const { result } = await api.post(
      `/administrativo/empresas/ajustar-limites`, {empresas: body},
      { headers: { Authorization: `Bearer ${getToken()}`, acess: 0 } }
    );

    if (result.error) {
      alert(`Erro: ${result.message}`);
    } else {
      alert(`${result.message}, exporte a listagem para conferência`);
    }
  }

  const atualizarResult = (result) => {
    setVisivel(false);
    if ( result ) {
      ajustarLimites();
    }
  }

  return (
    <div className='content'>
      <Grid fluid>
        <MyModal show={error} onHide={() => setError(false)} />
        <ModalConfirmacao
          show={visivel}
          onHide={() => setVisivel(false)}
          title="Corrigir Limites"
          pergunta="Confirma definição do ajuste dos limites para todos os funcionários?"
          confirma="Confirmar"
          cancela="Cancelar"
          onConfirma={() => { atualizarResult(true) }}
          onCancela={() => { atualizarResult(false) }}
        />
        <Row>
          <Col md={12}>
            <Card              
              content={
                <div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignContent: 'start' }}>
                    <span style={{ fontSize: '22px', marginLeft: '4px' }}>Ajustar Limite Empresas</span>
                    <div>
                      <Button
                          onClick={() => { baixarListaConferencia() }}
                          type='submit'
                          bsStyle='success'
                          variant='contained'
                          style={{
                            marginLeft: '5px',
                            marginBottom: '10px',
                            background: 'rgba(35,142,35, .8)',
                            border: 0,
                            borderRadius: 3,
                            boxShadow: '0 1px 1px 1px rgba(0, 0, 0, .2)',
                            color: 'white',
                            fontSize: '14px',
                          }}
                        >
                        Arquivo para Conferência  
                      </Button>
                      <Button
                          onClick={() => { setVisivel(true) }}
                          type='submit'
                          bsStyle='success'
                          variant='contained'
                          style={{
                            marginLeft: '5px',
                            marginBottom: '10px',
                            background: 'rgba(35,142,35, .8)',
                            border: 0,
                            borderRadius: 3,
                            boxShadow: '0 1px 1px 1px rgba(0, 0, 0, .2)',
                            color: 'white',
                            fontSize: '14px',
                          }}
                        >
                        Definir novo Limite  
                      </Button>
                    </div>
                  </div>
                  <hr/>
                  <Row>
                    <Table striped hover>
                      <thead align='center'>
                        <tr>
                          <td>
                            <b>Status</b>
                          </td>
                          <td>
                            <FormControl
                              type='text'
                              required={true}
                              placeholder='Razão Social'
                              onChange={(event) => { filtrar('razao_social', event.target.value) }}
                              style={{ maxWidth: '50%', textAlign: 'center' }}
                            />
                          </td>
                          <td>
                            <FormControl
                              type='text'
                              required={true}
                              placeholder='Nome Fantasia'
                              onChange={(event) => { filtrar('nome_fantasia', event.target.value) }}
                              style={{ maxWidth: '80%', textAlign: 'center' }}
                            />
                          </td>
                          <td>
                            <b>Func.</b>
                          </td>
                          <td>
                            <b>Limite Md.</b>
                          </td>
                          <td>
                            <b>Compras</b>
                          </td>
                          <td>
                            <b>Valor Md.</b>
                          </td>
                          <td>
                            <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'start' }}>
                              <ControlLabel style={{ color: '#000' }}>
                                <b>Ajuste(%)</b>
                              </ControlLabel>
                              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start' }}>
                                <FormGroup>
                                  <FormControl
                                    type='number'
                                    required={true}
                                    placeholder='Exemplo: 7,5'
                                    defaultValue={ajuste}
                                    onChange={(event) => { setAjuste(event.target.value) }}
                                    style={{ maxWidth: '68px', textAlign: 'right' }}
                                  />
                                </FormGroup>
                                <Button
                                  onClick={() => ajustarLimite() }
                                  style={{
                                    border: 0,
                                    color: 'white',
                                    borderRadius: 3,
                                    background: 'rgba(35,142,35, .8)',
                                    boxShadow: '0 1px 1px 1px rgba(0, 0, 0, .2)',
                                  }}
                                >
                                  <Image
                                    src={down}
                                    style={{ width: '22px', height: '22px' }}
                                  ></Image>
                                </Button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </thead>
                      <tbody align='center'>
                        { filteredData.length > 0 ? (
                          filteredData.map((empresa, key) => {
                            return (
                              <tr key={key}>
                                <td>{empresa.ativo ? 'ATIVO' : 'SUSPENSO'}</td>
                                <td>{getHighlightedText(empresa.razao_social)}</td>
                                <td>{getHighlightedText(empresa.nome_fantasia)}</td>
                                <td>{empresa.contagem_funcionarios}</td>
                                <td>{`R$ ${(empresa.soma_limites/empresa.contagem_funcionarios || 0).toFixed(2)}` || ''}</td>
                                <td>{empresa.contagem_compras}</td>
                                <td>{`R$ ${(empresa.soma_compras/empresa.contagem_compras || 0).toFixed(2)}` || ''}</td>
                                <td>
                                  <FormControl
                                    type='number'
                                    required={true}
                                    bsClass='form-control'
                                    placeholder='Exemplo: 3.5'
                                    defaultValue={empresa.ajuste}
                                    onChange={(event) => { setarLimiteEmpresa(event.target.value, key) }}
                                    style={{ maxWidth: '70px', textAlign: 'right' }}
                                  />
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          data.map((empresa, key) => {
                            return (
                              <tr key={key}>
                                <td>{empresa.ativo ? 'ATIVO' : 'SUSPENSO'}</td>
                                <td>{getHighlightedText(empresa.razao_social)}</td>
                                <td>{getHighlightedText(empresa.nome_fantasia)}</td>
                                <td>{empresa.contagem_funcionarios}</td>
                                <td>{`R$ ${(empresa.soma_limites/empresa.contagem_funcionarios || 0).toFixed(2)}` || ''}</td>
                                <td>{empresa.contagem_compras}</td>
                                <td>{`R$ ${(empresa.soma_compras/empresa.contagem_compras || 0).toFixed(2)}` || ''}</td>
                                <td>
                                  <FormControl
                                    type='number'
                                    required={true}
                                    bsClass='form-control'
                                    placeholder='Exemplo: 3.5'
                                    defaultValue={empresa.ajuste}
                                    onChange={(event) => { setarLimiteEmpresa(event.target.value, key) }}
                                    style={{ maxWidth: '70px', textAlign: 'right' }}
                                  />
                                </td>
                              </tr>
                            );
                          })
                        )}                        
                      </tbody>
                    </Table>
                  </Row>
                </div>
              }
              legend={
                <div>
                </div>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}
