import React, { useState } from 'react';
import * as C from 'react-bootstrap';
import Card from '../../../../components/Card/Card.jsx';
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import Button from "../../../../components/CustomButton/CustomButton.jsx";
import { DateTime } from "luxon";
import { Formatacao } from "../../../../utils/Functions/Formatacoes.jsx";
import { Grid, Row, Col } from 'react-bootstrap';
import RamicashModal from '../../Components/RamicashModal';


export default function Ramicash({ refresh, data }) {  
  const [showHide, setShowHide] = useState(false);
  const [idxData, setIdxData] = useState(0);

  const headers = [
    'Funcionário',
    'CPF',
    'Chave pix',
    'Valor',
    'Data da Solicitação',    
    'Ações',
  ];

  const pagarRamicash = (key) => {
    setIdxData(key)
    setShowHide(true)    
  }

  const closeModal = () => {
    setShowHide(false)
    refresh()
  }

  const bodyData = () => {    
    return data.lastRequests.length === 0 ? (
      <tr>
        <td colSpan={headers.length} style={{ fontSize: 16, padding: 24 }}>
          Nenhuma solicitação de Ramicash nova a ser enviada
        </td>
      </tr>
    ) : (
      data.lastRequests.map((ramicash, key) => (
        <tr key={key}>
          <td>{ramicash?.employee_id.nome || ''}</td>
          <td>{Formatacao("CPF", ramicash?.employee_id.cpf) || ''}</td>
          <td>{ramicash?.pix_key || ''}</td>
          <td>{Number(ramicash?.amount).toFixed(2).replace('.', ',') || ''}</td>
          <td>{DateTime.fromSeconds(Number(ramicash?.created_at)).toFormat('dd/MM/yyyy HH:ss')}</td>
          <td>
          <div className="actions-center">
              <OverlayTrigger placement="top" overlay={<Tooltip id="remove_tooltip">Pagar Ramicash</Tooltip>}>
                <Button
                  onClick={() => pagarRamicash(key) }
                  bsStyle="success"
                  simple
                  type="button"
                  bsSize="lg"
                >
                  <i
                    className="fa fa-qrcode"
                    aria-hidden="true"
                  />
                </Button>
              </OverlayTrigger>
            </div>
          </td>
        </tr>
      ))
    );
  };

  return (
    <Grid fluid>
      <RamicashModal show={showHide} closeModal={() => closeModal()} data={data.lastRequests.slice(idxData)}/>
        <Row>
          <Col lg={12}>
            <Card
              title='Solicitações Pendentes'
              content={
                <div align='left' style={{ margin: 20 }}>
                  <C.Table striped hover>
                    <thead>
                      <tr>
                        {headers.map((prop, key) => {
                          return (
                            <th
                              key={key}
                              style={{
                                textAlign: 'center',
                                fontWeight: 'bold',
                                color: 'black',
                              }}
                            >
                              {prop}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody align='center'>{bodyData()}</tbody>
                  </C.Table>
                </div>
              }
            />
          </Col>
        </Row>        
      </Grid>
  );
}
