import React, { Component } from "react";
import { NavItem, Nav } from "react-bootstrap";
import {logout} from '../../services/autenticacoes.jsx';

class AdminNavbarLinks extends Component {
  render() {
    return (
      <div>
        <Nav pullRight>
          <NavItem eventKey={3} onClick={()=> logout()} href="/login/Administrativo">
            Sair
          </NavItem>
        </Nav>
      </div>
    );
  }
}

export default AdminNavbarLinks;
