import axios from 'axios';
import moment from 'moment';
import Capitalize from 'capitalize-pt-br';
import api from '../../../../services/api.jsx';
import React, { useEffect, useState } from 'react';
import Search from '../../../../assets/img/search.png';
import Card from '../../../../components/Card/Card.jsx';
import { getToken } from '../../../../services/autenticacoes.jsx';
import { Formatacao } from '../../../../utils/Functions/Formatacoes.jsx';
import Button from '../../../../components/CustomButton/CustomButton.jsx';
import { FormInputs } from '../../../../components/FormInputs/FormInputs.jsx';
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Image,
  Radio
} from 'react-bootstrap';

import { ToastProvider } from "react-toast-notifications";
import Notifications from "../../Components/Toast.jsx";

export const Cadastrar = (props) => {
  const [update, setUpdate] = useState(false);
  const [CEP, setCEP] = useState({
    cep: Formatacao('CEP', props.dados_funcionario.cep),
  });
  const [CPF, setCPF] = useState({
    cpf: Formatacao('CPF', props.dados_funcionario.cpf),
  });
  const [Celular, setCelular] = useState({
    celular: Formatacao('CEL', props.dados_funcionario.celular),
  });
  const [Telefone, setTelefone] = useState({
    telefone: Formatacao('TEL', props.dados_funcionario.telefone),
  });
  const [DataNascimento, setData] = useState({
    data: Formatacao('DATA', props.dados_funcionario.data_nascimento),
  });

  const [Empresa, setEmpresa] = useState({
    cnpj: '',
    nome: '',
    dia_fechamento: '',
    canais_liberados: undefined,
  }); 

  const [error, setError] = React.useState([]);

  const validarDados = async (event) => {
    event.preventDefault();
          
    const fields = { CPF: CPF.cpf.replace(/\D/g, ''), RG: props.dados_funcionario.rg.replace(/\D/g, '') };

    try {
      const funcionario = await api.post(
        `/administrativo/check_in_use`,
        fields,
        { headers: { Authorization: `Bearer ${getToken()}`, acess: 0 } }
      );

      if (funcionario.data.erro) {
        alert(funcionario.data.message);
      } else {
        const docsInUse = funcionario.data.docsInUse
        if ( docsInUse !== '' ) {
          let errors = []
          if ( docsInUse.indexOf('CPF') >= 0 ) {
            errors.push({ message: "Já existe um usuário com este CPF em nosso cadsatro!", appearance: "error", auto_dismiss: true })
          }
          if ( docsInUse.indexOf('RG') >= 0 ) {
            errors.push({ message: "Ja existe um usuário com este RG em nosso cadastro!", appearance: "error", auto_dismiss: true })
          }

          setError(errors);
        } else {
          Cadastrar(event);
        }
      }
    } catch (error) {
      alert(error);
    }
  }

  const Cadastrar = async (event) => {
    event.preventDefault();

    let CanaisLiberados = [];

    props.dados_funcionario.cep = CEP.cep.replace(/\D/g, '');
    props.dados_funcionario.cpf = CPF.cpf.replace(/\D/g, '');
    props.dados_funcionario.sexo =
      props.dados_funcionario.sexo === undefined
        ? 'M'
        : String(props.dados_funcionario.sexo)[0].toLocaleUpperCase();

    props.dados_funcionario.ramicard.limite = parseFloat(
      String(props.dados_funcionario.ramicard.limite)
        .replace('R', '')
        .replace('$', '')
        .replace('.', '')
        .replace(',', '.')
    );
    
    Empresa.canais_liberados.map((item) =>
      item.canal === 'Alimentação'
        ? CanaisLiberados.push({
            canal: item.canal,
            parcelas_max: item.parcelas_max,
            habilitado: item.habilitado,
            termos_ramicard: false,
            termo_aceito: '',
          })
        : CanaisLiberados.push({
            ...item,
            termos_ramicard: true,
            termo_aceito: `Termo aceito em: ${moment().format(
              'DD/MM/YYYY hh:mm:ss'
            )}`,
          })
    );

    props.dados_funcionario.ramicard.canais = CanaisLiberados;

    try {
      const funcionario = await api.post(
        `/administrativo/${props.dados_funcionario.empresa_responsavel}/funcionario`,
        props.dados_funcionario,
        { headers: { Authorization: `Bearer ${getToken()}`, acess: 0 } }
      );

      if (funcionario.data.erro) {
        alert(funcionario.data.message);
      } else {
        alert(
          `${Capitalize(funcionario.data.nome)} cadastrado (a) com sucesso!`
        );
      }
    } catch (error) {
      alert(error);
    }
  };

  const BuscarEmpresa = async (Empresa) => {
    const CNPJ = `${Empresa}`.replace(/\D/g, '');

    let liberado_empresa = [];

    if (CNPJ) {
      try {
        const retorno = await api.get('/administrativo/empresa', {
          headers: { Authorization: `Bearer ${getToken()}`, acess: 0 },
        });

        const EmpresaSelecionada = retorno.data.filter(
          (item) => `${item.cnpj}` === CNPJ
        )[0];

        EmpresaSelecionada.servicos_autorizados.map((item) =>
          liberado_empresa.push({
            ...item,
            habilitado: true,
          })
        );

        setEmpresa({
          cnpj: EmpresaSelecionada.cnpj,
          nome: EmpresaSelecionada.nome_fantasia,
          dia_fechamento: EmpresaSelecionada.dia_fechamento,
          canais_liberados: liberado_empresa,
        });

        props.dados_funcionario.empresa_responsavel = EmpresaSelecionada._id;

        setUpdate(!update);
      } catch (error) {
        alert('Empresa não localizada!');
      }
    } else {
      alert('Preencha o campo Empresa Responsável');
    }
  };

  const Ativo = (canal) => {
    const mudarStatus = (key, status) =>
      (Empresa.canais_liberados[key].habilitado = status);

    Empresa.canais_liberados.map((item, key) =>
      `${item.canal}` === String(canal)
        ? mudarStatus(key, !item.habilitado)
        : null
    );

    setUpdate(!update);
  };

  useEffect(() => {}, [update]);

  async function cep(n_cep) {
    setCEP({ cep: Formatacao('CEP', n_cep) });

    try {
      const informacoes = await axios.get(
        `https://viacep.com.br/ws/${String(n_cep).replace(/\D/g, '')}/json/`
      );

      props.dados_funcionario.endereco =
        informacoes.data.logradouro === undefined
          ? ''
          : informacoes.data.logradouro;
      props.dados_funcionario.bairro =
        informacoes.data.bairro === undefined ? '' : informacoes.data.bairro;
      props.dados_funcionario.cidade =
        informacoes.data.localidade === undefined
          ? ''
          : informacoes.data.localidade;
      props.dados_funcionario.estado =
        informacoes.data.uf === undefined ? '' : informacoes.data.uf;

      setUpdate(!update);
    } catch (err) {
      alert(`CEP inválido! ${n_cep} ${err}`);
    }
  }

  return (
    <div className='content'>
      <ToastProvider>
        <Notifications msg={error} clear={() => setError([])} />
      </ToastProvider>
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title={
                <>
                  Funcionários
                  <br />
                  <br />
                  <Button
                    onClick={props.voltar}
                    style={{
                      backgroundColor: `${'#008000'}`,
                      color: '#FFF',
                      marginBottom: '20px',
                    }}
                    bsStyle='success'
                    simple={true}
                    type='button'
                  >
                    {' '}
                    Voltar
                  </Button>
                </>
              }
              content={
                <form onSubmit={validarDados}>
                  <FormInputs
                    {...props.dados_funcionario}
                    ncols={['col-md-4', 'col-md-3', 'col-md-3', 'col-md-2']}
                    properties={[
                      {
                        disabled: props.dados_funcionario.suspenso.status,
                        label: 'Funcionário',
                        type: 'text',
                        required: true,
                        bsClass: 'form-control',
                        placeholder: 'Nome Completo',
                        onChange: (event) =>
                          (props.dados_funcionario.nome = event.target.value),
                        defaultValue: Capitalize(props.dados_funcionario.nome),
                      },
                      {
                        disabled: props.dados_funcionario.suspenso.status,
                        label: 'RG',
                        type: 'text',
                        required: true,
                        bsClass: 'form-control',
                        placeholder: 'RG',
                        onChange: (event) =>
                          (props.dados_funcionario.rg = event.target.value),
                        defaultValue: props.dados_funcionario.rg,
                      },
                      {
                        disabled: props.dados_funcionario.suspenso.status,
                        label: 'CPF',
                        type: 'text',
                        required: true,
                        bsClass: 'form-control',
                        placeholder: 'CPF',
                        onChange: (event) =>
                          setCPF({
                            cpf: Formatacao('CPF', event.target.value),
                          }),
                        value: (props.dados_funcionario.cpf = CPF.cpf),
                      },
                      {
                        disabled: props.dados_funcionario.suspenso.status,
                        label: 'Nascido na data',
                        type: 'text',
                        required: true,
                        bsClass: 'form-control',
                        placeholder: 'Nascido na data',
                        onChange: (event) =>
                          setData({
                            data: Formatacao('DATA', event.target.value),
                          }),
                        value: (props.dados_funcionario.data_nascimento =
                          DataNascimento.data),
                      },
                    ]}
                  />

                  <Row>
                    <Col md={4}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Email
                        </ControlLabel>
                        <FormControl
                          type='text'
                          bsClass='form-control'
                          placeholder='Email'
                          disabled={props.dados_funcionario.suspenso.status}
                          onChange={(event) =>
                            (props.dados_funcionario.email = event.target.value)
                          }
                          defaultValue={props.dados_funcionario.email}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Telefone
                        </ControlLabel>
                        <FormControl
                          type='text'
                          disabled={props.dados_funcionario.suspenso.status}
                          bsClass='form-control'
                          placeholder='Telefone'
                          onChange={(event) =>
                            setTelefone({
                              telefone: Formatacao('TEL', event.target.value),
                            })
                          }
                          value={
                            (props.dados_funcionario.telefone =
                              Telefone.telefone)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Celular
                        </ControlLabel>
                        <FormControl
                          type='text'
                          disabled={props.dados_funcionario.suspenso.status}
                          bsClass='form-control'
                          placeholder='Celular'
                          onChange={(event) =>
                            setCelular({
                              celular: Formatacao('CEL', event.target.value),
                            })
                          }
                          value={
                            (props.dados_funcionario.celular = Celular.celular)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={1} align='Center'>
                      <FormGroup>
                        <ControlLabel
                          style={{ color: '#000', textAlign: 'center' }}
                        >
                          Whatsapp
                        </ControlLabel>
                        <div style={{ marginTop: '10px', textAlign: 'center' }}>
                          <input
                            disabled={props.dados_funcionario.suspenso.status}
                            type='checkbox'
                            name='q1_myOptions[]'
                            id='input_1_4'
                            defaultChecked={props.dados_funcionario.whatsapp}
                            onClick={() =>
                              (props.dados_funcionario.whatsapp =
                                !props.dados_funcionario.whatsapp)
                            }
                          />
                          <label htmlFor='input_1_4'></label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' , display: 'block'}}>
                          Sexo
                        </ControlLabel>

                        <Radio name="radioSexo" inline
                          disabled={props.dados_funcionario.suspenso.status} 
                          onChange={(event) =>
                            (props.dados_funcionario.sexo = 'M')
                          }>
                          M
                        </Radio>{' '}
                        <Radio name="radioSexo" inline 
                          disabled={props.dados_funcionario.suspenso.status}
                          onChange={(event) =>
                            (props.dados_funcionario.sexo = 'F')
                          }>
                          F
                        </Radio>{' '}
                        
                        {/* <FormControl
                          type='text'
                          disabled={props.dados_funcionario.suspenso.status}
                          bsClass='form-control'
                          placeholder='M/F'
                          onChange={(event) =>
                            (props.dados_funcionario.sexo = event.target.value)
                          }
                          defaultValue={
                            props.dados_funcionario.sexo === 'F'
                              ? 'Feminino'
                              : props.dados_funcionario.sexo === undefined
                              ? ''
                              : 'Masculino'
                          }
                        /> */}
                      </FormGroup>
                    </Col>
                  </Row>

                  <FormInputs
                    ncols={[
                      'col-md-2',
                      'col-md-2',
                      'col-md-2',
                      'col-md-2',
                      'col-md-1',
                      'col-md-3',
                    ]}
                    properties={[
                      {
                        disabled: props.dados_funcionario.suspenso.status,
                        label: 'CEP',
                        type: 'text',
                        bsClass: 'form-control',
                        placeholder: 'CEP',
                        onChange: (event) =>
                          String(event.target.value).length > 8
                            ? cep(Formatacao('CEP', event.target.value))
                            : setCEP({
                                cep: Formatacao('CEP', event.target.value),
                              }),
                        value: (props.dados_funcionario.cep = CEP.cep),
                      },
                      {
                        disabled: props.dados_funcionario.suspenso.status,
                        label: 'Cidade',
                        type: 'text',
                        bsClass: 'form-control',
                        placeholder: 'Cidade',
                        onChange: (event) =>
                          (props.dados_funcionario.cidade = event.target.value),
                        defaultValue: props.dados_funcionario.cidade,
                      },
                      {
                        disabled: props.dados_funcionario.suspenso.status,
                        label: 'Bairro',
                        type: 'text',
                        bsClass: 'form-control',
                        placeholder: 'Cidade',
                        onChange: (event) =>
                          (props.dados_funcionario.bairro = event.target.value),
                        defaultValue: props.dados_funcionario.bairro,
                      },
                      {
                        disabled: props.dados_funcionario.suspenso.status,
                        label: 'UF',
                        type: 'text',
                        bsClass: 'form-control',
                        placeholder: 'Estado',
                        onChange: (event) =>
                          (props.dados_funcionario.estado = event.target.value),
                        defaultValue: props.dados_funcionario.estado,
                      },
                      {
                        disabled: props.dados_funcionario.suspenso.status,
                        label: 'Nº',
                        type: 'text',
                        bsClass: 'form-control',
                        placeholder: 'Numero',
                        onChange: (event) =>
                          (props.dados_funcionario.numero = event.target.value),
                        defaultValue: props.dados_funcionario.numero,
                      },
                      {
                        disabled: props.dados_funcionario.suspenso.status,
                        label: 'Endereço',
                        type: 'text',
                        bsClass: 'form-control',
                        placeholder: 'Endereço',
                        onChange: (event) =>
                          (props.dados_funcionario.endereco =
                            event.target.value),
                        defaultValue: props.dados_funcionario.endereco,
                      },
                    ]}
                  />

                  <Row>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Limite do Cartão
                        </ControlLabel>
                        <FormControl
                          type='text'
                          bsClass='form-control'
                          placeholder='Limite do Cartão'
                          onChange={(event) =>
                            (props.dados_funcionario.ramicard.limite =
                              event.target.value)
                          }
                          defaultValue={Number(
                            props.dados_funcionario.ramicard.limite
                          ).toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Valor de Rescisão
                        </ControlLabel>
                        <FormControl
                          readOnly
                          type='text'
                          bsClass='form-control'
                          placeholder='Valor de Rescisão'
                          defaultValue='R$ 0,00'
                        />
                      </FormGroup>
                    </Col>

                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Empresa Responsável
                        </ControlLabel>
                        <FormControl
                          type='text'
                          bsClass='form-control'
                          required={true}
                          placeholder='CNPJ da Empresa'
                          onChange={(event) =>
                            setEmpresa({
                              nome: Empresa.nome,
                              cnpj: Formatacao('CNPJ', event.target.value),
                            })
                          }
                          value={Formatacao('CNPJ', Empresa.cnpj)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={1}>
                      <Button
                        onClick={() => BuscarEmpresa(Empresa.cnpj)}
                        variant='contained'
                        bsStyle='info'
                        style={{
                          border: 0,
                          color: 'white',
                          borderRadius: 3,
                          fontSize: '14px',
                          marginLeft: '10px',
                          marginTop: '26px',
                          background: 'rgba(35,142,35, .8)',
                          boxShadow: '0 1px 1px 1px rgba(0, 0, 0, .2)',
                        }}
                      >
                        <Image
                          src={Search}
                          style={{ width: '22px', height: '22px' }}
                        ></Image>
                      </Button>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Empresa Selecionada
                        </ControlLabel>
                        <FormControl
                          readOnly
                          type='text'
                          bsClass='form-control'
                          placeholder='Nome da Empresa'
                          defaultValue={Empresa.nome}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={1}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Fechamento
                        </ControlLabel>
                        <FormControl
                          readOnly
                          type='text'
                          bsClass='form-control'
                          placeholder='Dia'
                          onChange={(event) =>
                            (props.dados_funcionario.empresa_responsavel =
                              event.target.value)
                          }
                          defaultValue={Empresa.dia_fechamento}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  {Empresa.canais_liberados !== undefined ? (
                    <Card
                      title='Canais liberados pela empresa'
                      content={Empresa.canais_liberados.map((item, key) => (
                        <Row key={key}>
                          <Col md={2}>
                            <FormGroup>
                              <ControlLabel style={{ color: '#000' }}>
                                Nome do Serviço
                              </ControlLabel>
                              <FormControl
                                type='text'
                                bsClass='form-control'
                                placeholder='Nome do Serviço'
                                value={item.canal}
                                readOnly
                              />
                            </FormGroup>
                          </Col>
                          <Col md={2}>
                            <FormGroup>
                              <ControlLabel style={{ color: '#000' }}>
                                Parcelamento máx.
                              </ControlLabel>
                              <FormControl
                                type='text'
                                bsClass='form-control'
                                placeholder='Parcelas'
                                disabled={item.canal}
                                defaultValue={`${item.parcelas_max} ${
                                  item.parcelas_max > 1 ? 'vezes' : 'vez'
                                }`}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={1}>
                            <FormGroup align='center'>
                              <ControlLabel style={{ color: '#000' }}>
                                Habilitado
                              </ControlLabel>
                              <div style={{ marginTop: '10px' }}>
                                <input
                                  type='checkbox'
                                  checked={item.habilitado}
                                  onChange={() => Ativo(item.canal)}
                                />
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      ))}
                    />
                  ) : (
                    <Card
                      title='Canais liberados pela empresa'
                      content={
                        <Row>
                          <Col md={12}>
                            Empresa responsável não foi selecionada!
                          </Col>
                        </Row>
                      }
                    />
                  )}

                  <Row>
                    <Col md={12}>
                      <FormGroup controlId='formControlsTextarea'>
                        <ControlLabel style={{ color: '#000' }}>
                          Observações
                        </ControlLabel>
                        <FormControl
                          disabled={props.dados_funcionario.suspenso.status}
                          rows='3'
                          componentClass='textarea'
                          bsClass='form-control'
                          placeholder='Anotação'
                          onChange={(event) =>
                            (props.dados_funcionario.observacoes =
                              event.target.value)
                          }
                          defaultValue={props.dados_funcionario.observacoes}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Button
                    type='submit'
                    variant='contained'
                    bsStyle='info'
                    style={{
                      marginTop: '35px',
                      marginRight: '10px',
                      background: 'rgba(35,142,35, .8)',
                      border: 0,
                      borderRadius: 3,
                      boxShadow: '0 1px 1px 1px rgba(0, 0, 0, .2)',
                      color: 'white',
                      fontSize: '14px',
                    }}
                  >
                    {' '}
                    Cadastrar
                  </Button>
                  <Button
                    onClick={props.voltar}
                    variant='contained'
                    bsStyle='info'
                    style={{
                      marginTop: '35px',
                      marginRight: '10px',
                      background: 'rgba(35,142,35, .8)',
                      border: 0,
                      borderRadius: 3,
                      boxShadow: '0 1px 1px 1px rgba(0, 0, 0, .2)',
                      color: 'white',
                      fontSize: '14px',
                    }}
                  >
                    {' '}
                    {!props.dados_funcionario.suspenso.status
                      ? 'Cancelar'
                      : 'Ativar'}
                  </Button>
                </form>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};
