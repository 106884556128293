import React from 'react';
import * as S from './Styles';
import { DateTime } from 'luxon';
import CustomButton from '../Button';
import ReactJson from 'react-json-view';
import capitalize from 'capitalize-pt-br';
import MyAPI from '../../../../connections/Api.jsx';
import { Modal, FormControl } from 'react-bootstrap';
import IconAddNote from '../../../../assets/img/icon_add_note.png';
import IconNewRegister from '../../../../assets/img/icon_new_register.png';

export default function HistoricModal({ closeModal, data, ...props }) {
  const [historic, setHistoric] = React.useState([]);
  const [inputValue, setInputValue] = React.useState('');
  const [callAgain, setCallAgain] = React.useState(false);

  React.useEffect(() => {
    async function fetchData() {
      try {
        const response = await MyAPI.getListHistory(data._id);

        setHistoric(response.data);
      } catch (e) {
        console.log(e);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callAgain, closeModal]);

  const AddNew = async () => {
    try {
      const dataSend = {
        system_made: false,
        description: inputValue,
        changed_id: data._id,
        from: {},
        to: {},
      };

      if (inputValue.split('').length > 0) {
        await MyAPI.postNewItemHistory(dataSend);

        setCallAgain(!callAgain);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal {...props} centered={true} bsSize='large' style={{ margin: 'auto' }}>
      <Modal.Header closeButton onHide={closeModal}>
        <Modal.Title>Histórico: {capitalize(data.nome)}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          height: '600px',
          overflowY: 'scroll',
          whiteSpace: 'nowrap',
        }}
      >
        {historic.map((item, key) => (
          <>
            <S.Header>
              <S.Image
                src={item.system_made ? IconNewRegister : IconAddNote}
                alt='Logo'
              />
              <div>
                <span style={{ fontWeight: 'bold' }}>
                  {item.modified_by?.nome || ''}
                </span>
                <span>
                  {' '}
                  {item.system_made
                    ? 'modificou este documento.'
                    : 'anexou um novo evento a este documento.'}
                </span>
              </div>
            </S.Header>
            <S.Body>
              <span style={{ color: 'darkgray' }}>
                {capitalize(
                  DateTime.fromISO(item.created_at)
                    .setLocale('pt-br')
                    .toFormat('DDDD, HH:mm:ss')
                )}
              </span>
              <S.Card>
                <h5 style={{ fontWeight: 'bold' }}>{item.description}</h5>

                {!item.from && (
                  <S.JSON tagColor='rgba(0, 0, 255, .4)'>
                    <span>Anexo inserido de forma manual.</span>
                  </S.JSON>
                )}

                {!!item.from && (
                  <S.JSON tagColor='rgba(255, 0, 0, .4)'>
                    <ReactJson
                      src={item.from}
                      indentWidth={2}
                      collapsed={true}
                      enableClipboard={false}
                      displayDataTypes={false}
                      name='Documento Original'
                      displayObjectSize={false}
                    />
                  </S.JSON>
                )}

                {!!item.to && (
                  <S.JSON tagColor='rgba(0, 128, 0, .4)'>
                    <ReactJson
                      src={item.to}
                      indentWidth={2}
                      collapsed={true}
                      enableClipboard={false}
                      displayDataTypes={false}
                      name='Documento Alterado'
                      displayObjectSize={false}
                    />
                  </S.JSON>
                )}
              </S.Card>
            </S.Body>
          </>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <S.Footer>
          <FormControl
            type='text'
            bsClass='form-control'
            placeholder='Adicionar novo evento ao histórico'
            onChange={(event) => setInputValue(event.target.value)}
          />
          <CustomButton
            onClick={() => AddNew()}
            style={{ marginLeft: '15px', marginBottom: '0px' }}
            content='Adicionar ao Histórico'
          />
        </S.Footer>
      </Modal.Footer>
    </Modal>
  );
}
