import React, { useState } from 'react';
import Loading from '../../../Components/Loading';
import MyAPI from '../../../../../connections/Api.jsx';
import DatePicker from 'react-16-bootstrap-date-picker';
import Notifications from '../../../Components/Toast.jsx';
import Card from '../../../../../components/Card/Card.jsx';
import Button from '../../../../../components/CustomButton/CustomButton.jsx';

import { MultiSelect } from 'primereact/multiselect';
import { ToastProvider } from 'react-toast-notifications';
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  Table,
} from 'react-bootstrap';
import { DatePikerConfig } from '../../../../../utils/Abstractions/Variables.jsx';
import {
  anyIso,
  isoShortDate,
} from '../../../../../utils/Abstractions/Functions.jsx';
import { ReceberEmpresasXlsx } from '../../../../../services/Excel/Exportacoes-xlsx.jsx';

import * as S from './Styles';

export default function Predictions({ goTo }) {
  const [data, setData] = useState([]);
  const [futureData, setFutureData] = useState([]);

  const [selected, setSelect] = useState([]);
  const [error, setError] = React.useState([]);
  const [finalDate, setFinalDate] = React.useState('');
  const [isLoading, setLoading] = React.useState(false);
  const [initialDate, setInitialDate] = React.useState('');
  const [onSelectedList, setOnSelectedList] = useState(false);

  const fetchData = async () => {
    let errors = 0;

    if (selected.length === 0) {
      errors++;
      setError([
        {
          message: 'Selecione pelo menos um canal!',
          appearance: 'warning',
          auto_dismiss: true,
        },
        ...error,
      ]);
    }

    if (finalDate === '') {
      errors++;
      setError([
        {
          message: 'Insira o período final!',
          appearance: 'warning',
          auto_dismiss: true,
        },
        ...error,
      ]);
    }

    if (initialDate === '') {
      errors++;
      setError([
        {
          message: 'Insira o período inicial!',
          appearance: 'warning',
          auto_dismiss: true,
        },
        ...error,
      ]);
    }

    try {
      if (errors === 0) {
        setLoading(true);
        const response = await MyAPI.getForecastReport({
          initialDate,
          finalDate,
          channels: selected.map((e) => e.name),
        });

        setData(response.data.currentValues);
        setFutureData(response.data.futureValues);

        setLoading(false);
      }
    } catch (e) {
      setError([
        {
          message: 'Não foi possível buscar os dados de sua empresa!',
          appearance: 'error',
          auto_dismiss: true,
        },
      ]);
    }
  };

  const TaxaAlimentacao = 0.05;

  const cabecalhos = ['Código', 'Empresa', 'Valor no Período', 'Valor Futuro'];

  const cartoes = [
    { name: 'Alimentação', code: 'AL' },
    { name: 'Produtos', code: 'PR' },
    { name: 'Serviços', code: 'SE' },
    { name: 'Combustível', code: 'CO' }
  ];

  const duplicateFilter = (array) => {
    return array.filter(function (obj) {
      return (
        !this[JSON.stringify(obj._id)] && (this[JSON.stringify(obj._id)] = true)
      );
    }, Object.create(null));
  };

  const aplicarFormatacao = (valorString) => {
    let valorFloat;
    const tamanhoDoValor = valorString.length;

    if (1 === tamanhoDoValor) {
      valorFloat = parseFloat('0.0' + valorString);
    } else if (2 === tamanhoDoValor) {
      valorFloat = parseFloat('0.' + valorString);
    } else if (tamanhoDoValor > 2) {
      const centavos = valorString.slice(-2);
      const parteInteira = valorString.slice(0, tamanhoDoValor - 2);

      valorFloat = parseFloat(parteInteira.concat(centavos));
    }

    return isNaN(valorFloat)
      ? valorString
      : valorFloat.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
        });
  };

  const formatarMoeda = (valor) => {
    switch (typeof valor) {
      case 'string':
        const valorSemEspacos = valor.trim();
        return isNaN(parseFloat(valorSemEspacos))
          ? valor
          : aplicarFormatacao(valorSemEspacos);

      case 'number':
        return aplicarFormatacao(String(valor));
      default:
        return valor;
    }
  };

  const valorTotalEmpresaPeriodo = (cnpj_cpf) => {
    let AgrupamentoTaxa = 0;

    const CartaoAlimentacao = (ValorComTaxa) => {
      AgrupamentoTaxa += ValorComTaxa;
      return 0;
    };

    const ValorFinal = data
      .filter((e) => e.empresa_responsavel_id.cnpj === cnpj_cpf)
      .map((e) =>
        e.canal === 'Alimentação' || e.canal === 'Combustível'
          ? CartaoAlimentacao(
              e.taxa_funcionario
                ? e.valor * Number(e.taxa_funcionario)
                : e.valor / e.parcelas
            )
          : e.taxa_funcionario
          ? e.valor * Number(e.taxa_funcionario)
          : e.valor / e.parcelas
      )
      .reduce((acumulador, elementoAtual) => acumulador + elementoAtual, 0);

    return (
      AgrupamentoTaxa * TaxaAlimentacao +
      AgrupamentoTaxa +
      ValorFinal
    ).toFixed(2);
  };

  const valorTotalEmpresasTotal = () => {
    let AgrupamentoTaxa = 0;

    const CartaoAlimentacao = (ValorComTaxa) => {
      AgrupamentoTaxa += ValorComTaxa;
      return 0;
    };

    const ValorFinal = data
      .map((e) =>
        e.canal === 'Alimentação' || e.canal === 'Combustível'
          ? CartaoAlimentacao(
              e.taxa_funcionario
                ? e.valor * Number(e.taxa_funcionario)
                : e.valor / e.parcelas
            )
          : e.taxa_funcionario
          ? e.valor * Number(e.taxa_funcionario)
          : e.valor / e.parcelas
      )
      .reduce((acumulador, elementoAtual) => acumulador + elementoAtual, 0);

    return (
      AgrupamentoTaxa * TaxaAlimentacao +
      AgrupamentoTaxa +
      ValorFinal
    ).toFixed(2);
  };

  const valorTotalEmpresaFuturo = (cnpj_cpf) => {
    let AgrupamentoTaxa = 0;

    const CartaoAlimentacao = (ValorComTaxa) => {
      AgrupamentoTaxa += ValorComTaxa;
      return 0;
    };

    const ValorFinal = duplicateFilter(futureData)
      .filter((e) => e.empresa_responsavel_id.cnpj === cnpj_cpf)
      .map((e) =>
        e.canal === 'Alimentação' || e.canal === 'Combustível'
          ? CartaoAlimentacao(
              e.taxa_funcionario
                ? e.valor * Number(e.taxa_funcionario)
                : e.valor / e.parcelas
            )
          : e.taxa_funcionario
          ? e.valor * Number(e.taxa_funcionario)
          : e.valor / e.parcelas
      )
      .reduce((acumulador, elementoAtual) => acumulador + elementoAtual, 0);

    return (
      AgrupamentoTaxa * TaxaAlimentacao +
      AgrupamentoTaxa +
      ValorFinal
    ).toFixed(2);
  };

  const valorTotalEmpresasFuturo = (files) => {
    let AgrupamentoTaxa = 0;

    const CartaoAlimentacao = (ValorComTaxa) => {
      AgrupamentoTaxa += ValorComTaxa;
      return 0;
    };

    const ValorFinal = duplicateFilter(files)
      .map((e) =>
        e.canal === 'Alimentação' || e.canal === 'Combustível'
          ? CartaoAlimentacao(
              e.taxa_funcionario
                ? e.valor * Number(e.taxa_funcionario)
                : e.valor / e.parcelas
            )
          : e.taxa_funcionario
          ? e.valor * Number(e.taxa_funcionario)
          : e.valor / e.parcelas
      )
      .reduce((acumulador, elementoAtual) => acumulador + elementoAtual, 0);

    return (
      AgrupamentoTaxa * TaxaAlimentacao +
      AgrupamentoTaxa +
      ValorFinal
    ).toFixed(2);
  };

  const GerarArquivo = () => {
    let dataSet = [
      {
        columns: [
          {
            title: 'CÓDIGO',
            width: { wpx: 90 },
            style: {
              alignment: { horizontal: 'center' },
              font: { sz: '14', bold: true },
            },
          },
          {
            title: 'EMPRESA',
            width: { wch: 60 },
            style: {
              alignment: { horizontal: 'center' },
              font: { sz: '14', bold: true },
            },
          },
          {
            title: 'VALOR NO PERÍODO',
            width: { wpx: 140 },
            style: {
              alignment: { horizontal: 'center' },
              font: { sz: '14', bold: true },
            },
          },
          {
            title: 'VALOR TOTAL',
            width: { wpx: 100 },
            style: {
              alignment: { horizontal: 'center' },
              font: { sz: '14', bold: true },
            },
          },
          {
            title: 'VALOR FUTURO',
            width: { wpx: 125 },
            style: {
              alignment: { horizontal: 'center' },
              font: { sz: '14', bold: true },
            },
          },
        ],
        data: [],
      },
    ];

    data
      .filter(function (a) {
        return (
          !this[JSON.stringify(a.empresa_responsavel_id.razao_social)] &&
          (this[JSON.stringify(a.empresa_responsavel_id.razao_social)] = true)
        );
      }, Object.create(null))
      .map((item) =>
        item
          ? dataSet[0].data.push([
              {
                value: `${
                  item.empresa_responsavel_id.registro
                    ? item.empresa_responsavel_id.registro
                    : '-'
                }`,
                style: {
                  font: { sz: '14' },
                  alignment: { horizontal: 'center' },
                },
              },
              {
                value: `${item.empresa_responsavel_id.nome_fantasia}`,
                style: {
                  font: { sz: '14' },
                  alignment: { horizontal: 'center' },
                },
              },
              {
                value: Number(
                  valorTotalEmpresaPeriodo(item.empresa_responsavel_id.cnpj)
                ),
                style: {
                  numFmt: 'R$ #,##0.00;\\(R$#,##0.00\\);\\-;@',
                  font: { sz: '14' },
                  alignment: { horizontal: 'center' },
                },
              },
              {
                value: Number(
                  valorTotalEmpresaFuturo(item.empresa_responsavel_id.cnpj)
                ),
                style: {
                  numFmt: 'R$ #,##0.00;\\(R$#,##0.00\\);\\-;@',
                  font: { sz: '14' },
                  alignment: { horizontal: 'center' },
                },
              },
            ])
          : false
      )
      .filter((e) => e !== false);

    dataSet[0].data.push([
      { value: ' ' },
      {
        value: 'Total à Receber: ',
        style: {
          font: { sz: '14', bold: true },
          alignment: { horizontal: 'right' },
        },
      },
      {
        value: Number(valorTotalEmpresasTotal()),
        style: {
          numFmt: 'R$ #,##0.00;\\(R$#,##0.00\\);\\-;@',
          font: { sz: '14', bold: true },
          alignment: { horizontal: 'center' },
        },
      },
      {
        value: Number(valorTotalEmpresasFuturo(futureData)),
        style: {
          numFmt: 'R$ #,##0.00;\\(R$#,##0.00\\);\\-;@',
          font: { sz: '14', bold: true },
          alignment: { horizontal: 'center' },
        },
      },
    ]);

    return dataSet;
  };

  const duplicateFilterCompany = (array) => {
    return array.filter(function (obj) {
      return (
        !this[JSON.stringify(obj.empresa_responsavel_id.razao_social)] &&
        (this[JSON.stringify(obj.empresa_responsavel_id.razao_social)] = true)
      );
    }, Object.create(null));
  };

  const removeSpace = (corporateName) =>
    corporateName
      .split('')
      .filter((e) => e !== ' ')
      .join(' ');

  const orderBy = (array) =>
    array.sort((a, b) =>
      removeSpace(a.empresa_responsavel_id.nome_fantasia) >
      removeSpace(b.empresa_responsavel_id.nome_fantasia)
        ? 1
        : removeSpace(a.empresa_responsavel_id.nome_fantasia) <
          removeSpace(b.empresa_responsavel_id.nome_fantasia)
        ? -1
        : 0
    );

  return (
    <div className='content'>
      <ToastProvider>
        <Notifications msg={error} clear={() => setError([])} />
      </ToastProvider>
      <Grid fluid>
        <Row>
          <Col md={12} style={{ marginTop: 25 }}>
            <Card
              title={
                <>
                  <Button
                    onClick={() => goTo('Menu')}
                    style={{ backgroundColor: '#008000', color: '#FFF' }}
                    simple={true}
                    type='button'
                    bsSize='sm'
                  >
                    {' '}
                    <i className='fa fa-bars' aria-hidden='true' /> Menu
                  </Button>
                  <br />
                  <p align='center' style={{ fontSize: 20 }}>
                    Relatório de Previsões
                  </p>
                </>
              }
              content={
                <Row>
                  <Col md={2}>
                    <FormGroup>
                      <ControlLabel style={{ color: '#000' }}>
                        Período inicial
                      </ControlLabel>
                      <DatePicker
                        placeholder='DD/MM/YYYY'
                        value={anyIso(initialDate, 'dd/MM/yyyy')}
                        monthLabels={DatePikerConfig.months}
                        dayLabels={DatePikerConfig.week}
                        onChange={(e) =>
                          setInitialDate(isoShortDate(e, 'dd/MM/yyyy'))
                        }
                      />
                    </FormGroup>
                  </Col>

                  <Col md={2}>
                    <FormGroup>
                      <ControlLabel style={{ color: '#000' }}>
                        Período Final
                      </ControlLabel>
                      <DatePicker
                        placeholder='DD/MM/YYYY'
                        value={anyIso(finalDate, 'dd/MM/yyyy')}
                        monthLabels={DatePikerConfig.months}
                        dayLabels={DatePikerConfig.week}
                        onChange={(e) =>
                          setFinalDate(isoShortDate(e, 'dd/MM/yyyy'))
                        }
                      />
                    </FormGroup>
                  </Col>

                  <Col md={2}>
                    <ControlLabel style={{ color: '#000' }}>
                      Canais
                    </ControlLabel>
                    <div
                      onMouseOut={() => setOnSelectedList(false)}
                      onMouseOver={() => setOnSelectedList(true)}
                    >
                      <MultiSelect
                        optionLabel='name'
                        value={selected}
                        style={{
                          height: 40,
                          width: '100%',
                          color: 'gray',
                          opacity: !onSelectedList ? 0.3 : 1,
                        }}
                        options={cartoes}
                        placeholder={`Canais Ramicard`}
                        onChange={(e) => setSelect(e.value)}
                      />
                    </div>
                  </Col>

                  <Col md={3}>
                    <S.WarapperButtons>
                      <Button
                        onClick={() => fetchData()}
                        style={{
                          backgroundColor: '#008000',
                          color: '#FFF',
                          marginRight: 15,
                          fontSize: 16,
                          width: 116,
                        }}
                        simple
                        type='button'
                        bsSize='sm'
                      >
                        <i className='fa fa-search' aria-hidden='true' />{' '}
                        Pesquisar
                      </Button>
                      {data.length > 0 ? (
                        <ReceberEmpresasXlsx
                          buttonName='Baixar xlsx'
                          files={GerarArquivo()}
                        />
                      ) : null}
                    </S.WarapperButtons>
                  </Col>
                </Row>
              }
            />

            {!isLoading ? (
              <Card
                content={
                  <div>
                    <div align='center' style={{ margin: 20 }}>
                      <Table striped hover>
                        <thead>
                          <tr>
                            {cabecalhos.map((prop, key) => {
                              return (
                                <th
                                  key={key}
                                  style={{
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    color: '#000000',
                                  }}
                                >
                                  {prop}
                                </th>
                              );
                            })}
                          </tr>
                        </thead>
                        <tbody align='center'>
                          {data.length > 0 ? (
                            duplicateFilterCompany(orderBy(data)).map(
                              (prop, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{`${
                                      prop.empresa_responsavel_id.registro
                                        ? prop.empresa_responsavel_id.registro
                                        : '-'
                                    }`}</td>
                                    <td>{`${prop.empresa_responsavel_id.nome_fantasia}`}</td>
                                    <td style={{ textAlign: 'center' }}>
                                      {prop.empresa_responsavel_id.cnpj
                                        ? `R$ ${formatarMoeda(
                                            String(
                                              valorTotalEmpresaPeriodo(
                                                prop.empresa_responsavel_id.cnpj
                                              )
                                            )
                                          )}`
                                        : '-'}
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                      {prop.empresa_responsavel_id.cnpj
                                        ? `R$ ${formatarMoeda(
                                            String(
                                              valorTotalEmpresaFuturo(
                                                prop.empresa_responsavel_id.cnpj
                                              )
                                            )
                                          )}`
                                        : '-'}
                                    </td>
                                  </tr>
                                );
                              }
                            )
                          ) : (
                            <tr>
                              <td>-</td>
                              <td>-</td>
                              <td>-</td>
                              <td>-</td>
                            </tr>
                          )}
                          {data.length > 0 ? (
                            <tr>
                              <td />
                              <td
                                style={{
                                  fontWeight: 'bold',
                                  textAlign: 'right',
                                }}
                              >
                                Total Geral:
                              </td>
                              <td style={{ fontWeight: 'bold' }}>
                                {data
                                  ? `R$ ${formatarMoeda(
                                      String(valorTotalEmpresasTotal())
                                    )}`
                                  : '-'}
                              </td>
                              <td style={{ fontWeight: 'bold' }}>
                                {futureData
                                  ? `R$ ${formatarMoeda(
                                      String(
                                        valorTotalEmpresasFuturo(futureData)
                                      )
                                    )}`
                                  : '-'}
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td />
                              <td
                                style={{
                                  fontWeight: 'bold',
                                  textAlign: 'right',
                                }}
                              >
                                Total Geral:
                              </td>
                              <td style={{ fontWeight: 'bold' }}>-</td>
                              <td style={{ fontWeight: 'bold' }}>-</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                }
              />
            ) : (
              <Loading Height='400px' />
            )}
          </Col>
        </Row>
      </Grid>
    </div>
  );
}
