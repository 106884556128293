import React from "react";
import Capitalize from 'capitalize-pt-br';
import MyAPI from "../../../../connections/Api.jsx";
import Card from "../../../../components/Card/Card.jsx";
import DatePicker from "react-16-bootstrap-date-picker";
import Notifications from "../../Components/Toast/Toast.jsx";
import Button from "../../../../components/CustomButton/CustomButton.jsx";

import { MultiSelect } from "primereact/multiselect";
import { ToastProvider } from "react-toast-notifications";
import { DatePikerConfig } from "../../../../utils/Abstractions/Variables.jsx"; // DateZone, TimeZone
import { Grid, Row, Col, FormGroup, ControlLabel, Table } from "react-bootstrap";
import { ReceberEmpresasXlsx } from "../../../../services/Excel/Exportacoes-xlsx.jsx";
import { anyIso, isoShortDate, inReal, grandSum, authorizationDate } from "../../../../utils/Abstractions/Functions.jsx";

export default function Information() {
  const [data, setData] = React.useState([]);
  const [error, setError] = React.useState([]);
  const [cache, setCache] = React.useState([]);
  const [companie, setCompanie] = React.useState([]);
  const [employees, setEmployees] = React.useState([]);
  const [finalDate, setFinalDate] = React.useState("");
  const [initialDate, setInitialDate] = React.useState("");
  const [selectedEmployee, setSelectedEmployee] = React.useState([]);

  const headers = ["Funcionário", "Canal", "Autorização", "Vencimento", "Parcela Atual", "Parcelas Totais", "Valor"];

  React.useEffect(() => {
    async function fetchData() {
      try {
        const response = await MyAPI.getInformation();

        setCompanie(response.data);
      } catch (e) {
        setError([{ message: "Não foi possível buscar os dados de sua empresa!", appearance: "error", auto_dismiss: true }]);
      }
    }
    fetchData();
  }, []);

  async function search() {
    let errors = 0

    if (finalDate === '') {
      errors++
      setError([{ message: "Insira o período final!", appearance: "warning", auto_dismiss: true }, ...error]);
    }

    if (initialDate === '') {
      errors++
      setError([{ message: "Insira o período inicial!", appearance: "warning", auto_dismiss: true }, ...error]);
    }

    try {
      if (errors === 0) {
        const response = await MyAPI.getReports({ initialDate, finalDate, companie: companie._id });

        setData(response.data);
        setCache(response.data);
        setEmployees([...new Set(response.data.map((e) => e.usuario_id.nome))].map((e, key) => { return { name: e, code: key } }));
      }
    } catch (e) {
      setError([{ message: "Não foi possível consultar o consumo deste período!", appearance: "error", auto_dismiss: true }]);
    }
  }

  const searchEmployee = (word) => {
    setSelectedEmployee(word);

    word.length !== 0
      ? setData(
        cache.filter((item) =>
          word.map((e) => e.name).includes(item.usuario_id.nome)
        )
      )
      : setData(cache);
  };

  const bodyData = () => data.map((voucher, key) => {
    return (
      <tr key={key}>
        <td>{Capitalize(voucher.usuario_id.nome)}</td>
        <td>{voucher.canal}</td>
        <td>{authorizationDate(voucher)}</td>
        <td>{voucher.horario_confirmacao}</td>
        <td>{voucher.parcela_atual}</td>
        <td>{voucher.parcelas}</td>
        <td>{inReal(voucher.valor)}</td>
      </tr>
    );
  });

  const footerData = () => (
    <tr>
      <td />
      <td />
      <td />
      <td />
      <td />
      <td>Total</td>
      <td>{inReal(grandSum(data.map(e => e.valor)))}</td>
    </tr>
  );

  const exportExcel = () => {
    return [
      {
        columns: headers.map((columns) => {
          return {
            title: columns,
            width: { wpx: 140 },
            style: {
              alignment: { horizontal: "center" },
              font: { sz: "12", bold: true },
            },
          };
        }),
        data: [
          ...data.map((voucher) => [
            {
              value: Capitalize(voucher.usuario_id.nome),
              style: {
                font: { sz: "12" },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: voucher.canal,
              style: {
                font: { sz: "12" },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: authorizationDate(voucher),
              style: {
                font: { sz: "12" },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: voucher.horario_confirmacao,
              style: {
                font: { sz: "12" },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: voucher.parcela_atual,
              style: {
                font: { sz: "12" },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: voucher.parcelas,
              style: {
                font: { sz: "12" },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: voucher.valor,
              style: {
                numFmt: "R$ #,##0.00;\\(R$#,##0.00\\);\\-;@",
                font: { sz: "12" },
                alignment: { horizontal: "center" },
              },
            },
          ]),
          [
            { value: " " },
            { value: " " },
            { value: " " },
            { value: " " },
            { value: " " },
            {
              value: "Total",
              style: {
                font: { sz: "12", bold: true },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: inReal(grandSum(data.map((e) => e.valor))),
              style: {
                numFmt: "R$ #,##0.00;\\(R$#,##0.00\\);\\-;@",
                font: { sz: "12", bold: true },
                alignment: { horizontal: "center" },
              },
            },
          ],
        ],
      },
    ];
  };

  return (
    <div className="content" style={{ minHeight: "850px" }}>
      <ToastProvider>
        <Notifications msg={error} clear={() => setError([])} />
      </ToastProvider>
      <Grid fluid>
        <Row>
          <Col md={12} style={{ marginTop: 25 }}>
            <Card
              title="Relatório Analítico de Funcionários"
              content={
                <Row>
                  <Col md={2}>
                    <FormGroup>
                      <ControlLabel style={{ color: "#000" }}>
                        Período inicial
                      </ControlLabel>
                      <DatePicker
                        placeholder="DD/MM/YYYY"
                        value={anyIso(initialDate, "dd/MM/yyyy")}
                        monthLabels={DatePikerConfig.months}
                        dayLabels={DatePikerConfig.week}
                        onChange={(e) =>
                          setInitialDate(isoShortDate(e, "dd/MM/yyyy"))
                        }
                      />
                    </FormGroup>
                  </Col>

                  <Col md={2}>
                    <FormGroup>
                      <ControlLabel style={{ color: "#000" }}>
                        Período Final
                      </ControlLabel>
                      <DatePicker
                        placeholder="DD/MM/YYYY"
                        value={anyIso(finalDate, "dd/MM/yyyy")}
                        monthLabels={DatePikerConfig.months}
                        dayLabels={DatePikerConfig.week}
                        onChange={(e) =>
                          setFinalDate(isoShortDate(e, "dd/MM/yyyy"))
                        }
                      />
                    </FormGroup>
                  </Col>

                  <Col md={2} align="center" style={{ marginTop: 25 }}>
                    <Button
                      onClick={() => search()}
                      style={{
                        backgroundColor: "#008000",
                        borderColor: '#008930',
                        color: "#FFF",
                        fontSize: 16,
                      }}
                      type="button"
                      bsSize="small"
                    >
                      <i className="fa fa-search" aria-hidden="true" />{" "}Consultar
                    </Button>
                  </Col>

                  <Col md={1} align="center" style={{ marginTop: 25 }}>
                    <ReceberEmpresasXlsx files={exportExcel()} fileName="Relatório Analítico" />
                  </Col>
                </Row>
              }
            />

            <Card
              content={
                <div>
                  <Row>
                    <Col md={7} />
                    <Col md={5}>
                      <MultiSelect
                        optionLabel="name"
                        value={selectedEmployee}
                        style={{
                          height: 40,
                          width: "100%",
                          color: "gray",
                        }}
                        options={employees}
                        placeholder="Filtro de Funcionários"
                        onChange={(e) => searchEmployee(e.value)}
                      />
                    </Col>
                  </Row>

                  <div align="center" style={{ margin: 20 }}>
                    <Table striped hover>
                      <thead>
                        <tr>
                          {headers.map((prop, key) => {
                            return (
                              <th
                                key={key}
                                style={{
                                  textAlign: "center",
                                  fontWeight: "bold",
                                  color: "#000000",
                                }}
                              >
                                {prop}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody align="center">
                        {data.length === 0 ? <td colSpan={headers.length}><p style={{ marginTop: '60px' }}>Sem resultados!</p></td> : bodyData()}
                        {data.length === 0 ? <></> : footerData()}
                      </tbody>
                    </Table>
                  </div>
                </div>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}
