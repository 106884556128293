import ReactTable from "react-table";
import api from "../../../../services/api.jsx";
import capitalize from "capitalize-pt-br";
import Spinner from "react-spinner-material";
import Card from "../../../../components/Card/Card.jsx";
import { Grid, Row, Col } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import MyModal from "../../../../components/Modal/MyModal.jsx";
import { getToken, checkPermissoes } from "../../../../services/autenticacoes.jsx";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { Formatacao } from "../../../../utils/Functions/Formatacoes.jsx";
import Button from "../../../../components/CustomButton/CustomButton.jsx";
import { ContratoRamicard } from "../../../../services/PDF/Funcionario/Funcionarios.jsx";

import { ToastProvider } from "react-toast-notifications";
import Notifications from "../../Components/Toast.jsx";

// * Novo método de criação, edição e atualização de funcionários.
import { Editar } from "./Edit.jsx";
import { Cadastrar } from "./New.jsx";

export default function Indice() {
  const [paginacao, setPagina] = useState({ pagina: 0, dados: [] });

  const [data, setData] = useState([]);
  const [error, setError] = useState(false);

  const nomePermissao = 'funcionarios';
  const [erroPermissao, setErroPermissao] = useState('');

  const editar = <Tooltip id="edit_tooltip">Editar Funcionário</Tooltip>;
  const resetarSenha = <Tooltip id="remove_tooltip">Resetar senha</Tooltip>;
  const gerarContrato = (
    <Tooltip id="remove_tooltip">
      Clique aqui para gerar o contrato deste Funcionário
    </Tooltip>
  );

  useEffect(() => {
    async function buscarDados() {
      try {
        const resposta = await api.get("/administrativo/funcionario", {
          headers: { Authorization: `Bearer ${getToken()}`, acess: 0 },
        });
        if (resposta.data.error) {

        } else {
          setData(resposta.data);
        }
      } catch (e) {
        setError(true);
      }
    }
    buscarDados();
  }, []);

  async function deletarSenha(cpf) {
    if (
      window.confirm(
        `Deseja realmente resetar a senha do usuário do cpf: ${cpf}?`
      )
    ) {
      try {
        const { data } = await api.delete(
          `/administrativo/funcionario/reset/${cpf.replace(/\D/g, "")}`,
          { headers: { Authorization: `Bearer ${getToken()}`, acess: 0 } }
        );
        if (data.error) {
          alert(data.message);
        } else {
          alert(data.message);
        }
      } catch (error) {
      }
    }
  }

  async function baixarListaEnvios() {
    try {
      const { data } = await api.get(
        `/administrativo/lista-envios`,
        { responseType: 'blob', headers: { Authorization: `Bearer ${getToken()}`, acess: 0 } }
      );

      if (data.error) {
        alert(data.message);
      } else {
        const blob = new Blob([data], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = url;
        link.download = 'lista-envios.csv';
        link.click();

        window.URL.revokeObjectURL(url);
      }

    } catch (error) {
      console.log(error);
    }
  }

  async function baixarListaCompleta() {
    try {
      const { data } = await api.get(
        `/administrativo/lista-completa`,
        { responseType: 'blob', headers: { Authorization: `Bearer ${getToken()}`, acess: 0 } }
      );

      if (data.error) {
        alert(data.message);
      } else {
        const blob = new Blob([data], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = url;
        link.download = 'lista-completa.csv';
        link.click();

        window.URL.revokeObjectURL(url);
      }

    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      {data.length <= 0 ? (
        <div
          style={{
            backgroundColor: "#FFF",
            justifyContent: "center",
            display: "flex",
            flex: 1,
            height: "800px",
            alignItems: "center",
          }}
        >
          {" "}
          <Spinner
            size={120}
            spinnercolor={"#333"}
            spinnerwidth={2}
            visible={true}
          />
        </div>
      ) : paginacao.pagina === 0 ? (
        <div className="content">
          <ToastProvider>
            <Notifications msg={erroPermissao} clear={() => setErroPermissao([])} />
          </ToastProvider>
          <Grid fluid>
            <Row>
              <MyModal show={error} onHide={() => setError(false)} />
              <Col md={12}>
                <Card
                  title={
                    <>
                      Funcionários
                      <br />
                      <br />
                      {/* <FuncionariosXlsx files={data} /> */}
                      <Button
                        onClick={() => baixarListaCompleta()}
                        style={{
                          backgroundColor: `${"#008000"}`,
                          color: "#FFF",
                          marginLeft: "10px",
                        }}
                        bsStyle="success"
                        simple={true}
                        type="button"
                      >
                        {" "}
                        <i
                          className="fa fa-file-text-o"
                          aria-hidden="true"
                        />{" "}
                        Lista Completa
                      </Button>
                      <Button
                        onClick={() => { }}
                        style={{
                          backgroundColor: `${"#008000"}`,
                          color: "#FFF",
                          marginLeft: "10px",
                        }}
                        bsStyle="success"
                        disabled={true}
                        simple={true}
                        type="button"
                      >
                        {" "}
                        <i
                          className="fa fa-file-text-o"
                          aria-hidden="true"
                        />{" "}
                        .csv
                      </Button>
                      <Button
                        onClick={() => baixarListaEnvios()}
                        style={{
                          backgroundColor: `${"#008000"}`,
                          color: "#FFF",
                          marginLeft: "10px",
                        }}
                        bsStyle="success"
                        simple={true}
                        type="button"
                      >
                        {" "}
                        <i
                          className="fa fa-file-text-o"
                          aria-hidden="true"
                        />{" "}
                        Lista de Envios
                      </Button>
                    </>
                  }
                  content={
                    <div>
                      <div>
                        { checkPermissoes(nomePermissao, 'create') ? 
                          <Button
                            onClick={() => setPagina({ pagina: 2, dados: [] })}
                            type="submit"
                            bsStyle="success"
                            variant="contained"
                            style={{
                              marginLeft: "5px",
                              marginBottom: "10px",
                              background: "rgba(35,142,35, .8)",
                              border: 0,
                              borderRadius: 3,
                              boxShadow: "0 1px 1px 1px rgba(0, 0, 0, .2)",
                              color: "white",
                              fontSize: "14px",
                            }}
                          >
                            {" "}
                            Novo Funcionário
                          </Button>                        
                        : <></> }
                      </div>

                      <ReactTable
                        data={data.map((prop, key) => {                          
                          return {
                            id: key,
                            situacao: !prop.empresa_responsavel.ativo ? "SUSPENSO" : prop.suspenso.status
                              ? "SUSPENSO"
                              : prop.registro.toUpperCase(),
                            empresa: `${prop.empresa_responsavel.nome_fantasia.split(
                              " "
                            )[0] !== undefined
                              ? prop.empresa_responsavel.nome_fantasia.split(
                                " "
                              )[0]
                              : ""
                              } ${prop.empresa_responsavel.nome_fantasia.split(
                                " "
                              )[1] !== undefined
                                ? prop.empresa_responsavel.nome_fantasia.split(
                                  " "
                                )[1]
                                : ""
                              } ${prop.empresa_responsavel.nome_fantasia.split(
                                " "
                              )[2] !== undefined
                                ? prop.empresa_responsavel.nome_fantasia.split(
                                  " "
                                )[2]
                                : ""
                              } ${prop.empresa_responsavel.nome_fantasia.split(
                                " "
                              )[3] !== undefined
                                ? prop.empresa_responsavel.nome_fantasia.split(
                                  " "
                                )[3]
                                : ""
                              }`.toUpperCase(),
                            nome: capitalize(
                              prop.nome
                                .normalize("NFD")
                                .replace(/[\u0300-\u036f]/g, "")
                            ).toUpperCase(),
                            cpf:
                              Formatacao("CPF", prop.cpf).length === 14
                                ? Formatacao("CPF", prop.cpf)
                                : "Não Padronizado",
                            contato:
                              Formatacao("CEL", prop.celular).length > 0
                                ? Formatacao("CEL", prop.celular)
                                : "Solicitar Contato",
                            acoes: (
                              <div className="actions-center">
                                <OverlayTrigger
                                  placement="top"
                                  overlay={editar}
                                >
                                  <Button
                                    onClick={() => {
                                      if ( checkPermissoes(nomePermissao, 'read', `${prop.empresa_responsavel.cnpj}` ) ) {
                                        setPagina({ pagina: 1, dados: prop })
                                      } else {
                                        setErroPermissao([{ message: "Acesso aos dados do funcionário não autorizado", appearance: "error", auto_dismiss: true }]);
                                      }
                                    }}
                                    bsStyle="info"
                                    simple
                                    type="button"
                                    bsSize="sm"
                                  >
                                    <div style={{ fontSize: 20 }}>
                                      <i className="fa fa-edit" />
                                    </div>
                                  </Button>
                                </OverlayTrigger>

                                <OverlayTrigger
                                  placement="top"
                                  overlay={gerarContrato}
                                  onClick={() => ContratoRamicard(prop)}
                                >
                                  <Button
                                    onClick={() => { }}
                                    bsStyle="danger"
                                    simple
                                    type="button"
                                    bsSize="sm"
                                  >
                                    <div style={{ fontSize: 20 }}>
                                      <i
                                        className="fa fa-file-pdf-o"
                                        aria-hidden="true"
                                      />
                                    </div>
                                  </Button>
                                </OverlayTrigger>

                                <OverlayTrigger
                                  placement="top"
                                  overlay={resetarSenha}
                                  onClick={() => deletarSenha(prop.cpf)}
                                >
                                  <Button
                                    onClick={() => { }}
                                    bsStyle="success"
                                    simple
                                    type="button"
                                    bsSize="sm"
                                  >
                                    <div style={{ fontSize: 20 }}>
                                      <i className="fa fa-unlock-alt" />
                                    </div>
                                  </Button>
                                </OverlayTrigger>
                              </div>
                            ),
                          };
                        })}
                        filterable
                        columns={[
                          {
                            Header: "Código / Suspenso",
                            accessor: "situacao",
                          },
                          {
                            Header: "EMPRESA",
                            accessor: "empresa",
                          },
                          {
                            Header: "FUNCIONÁRIO",
                            accessor: "nome",
                          },
                          {
                            Header: "CPF",
                            accessor: "cpf",
                          },
                          {
                            Header: "CONTATO",
                            accessor: "contato",
                          },
                          {
                            Header: "",
                            accessor: "acoes",
                            filterable: false,
                            sortable: false,
                          },
                        ]}
                        defaultPageSize={100}
                        showPaginationTop
                        showPaginationBottom={false}
                        className="-striped -highlight text-center"
                      />
                    </div>
                  }
                />
              </Col>
            </Row>
          </Grid>
        </div>
      ) : paginacao.pagina === 1 ? (
        <Editar
          dados_funcionario={paginacao.dados}
          voltar={() => setPagina({ pagina: 0, dados: [] })}
        />
      ) : (
        <Cadastrar
          dados_funcionario={{
            _id: "",
            empresa_responsavel: "",
            nome: "",
            cpf: "",
            rg: "",
            email: "",
            data_nascimento: "",
            telefone: "",
            celular: "",
            whatsapp: false,
            suspenso: {
              motivo: "",
              status: false,
              responsavel: "",
            },
            endereco: "",
            numero: 0,
            bairro: "",
            cidade: "",
            cep: "",
            estado: "",
            valor_recisao: 0,
            observacoes: "",
            ramicard: {
              habilitado: {
                motivo: "Novo funcionário",
                status: true,
                responsavel: `${String(localStorage.getItem("Name"))}`,
              },
              canais: [],
              limite: 0,
              sexo: "",
            },
          }}
          voltar={() => setPagina({ pagina: 0, dados: [] })}
        />
      )}
    </>
  );
}
