import React, { useState, useEffect, useMemo, useCallback } from 'react';
import * as S from './Styles';
import CustomButton from '../Button';
import {  
  Modal,
  Col,
  Row,
  FormGroup,  
  FormControl,
  ControlLabel,
} from 'react-bootstrap';
import Spinner from "react-spinner-material";
import {useDropzone} from 'react-dropzone'
import MyAPI from '../../../../connections/Api.jsx';

var QRCode = require('qrcode.react'); /* eslint-disable import/first */

import { Formatacao } from "../../../../utils/Functions/Formatacoes.jsx";

import pdfIcon from "../../../../assets/img/pdf_icon.png";

export default function RamicashModal({ closeModal, data, ...props }) {
  const [qrCodeURL, setQrCodeURL] = useState('');
  const [ramicash, setRamicash] = useState(undefined);
  const [idxRamicash, setIdxRamicash] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [attachment, setAttachment] = useState('');
  const [motivoRecusa, setMotivoRecusa] = useState('');
  const [loading, setLoading] = useState(true);

  const gravarPagamento = async () => {
    try {      
      const DATA = await MyAPI.postRamicash({
        attachment,
        id: ramicash._id,
        status: 'Pago'
      });

      if (DATA.data.error) {
        alert(DATA.data.error);
      } else {
        if ( (idxRamicash+1) >= data?.length ) {
          closeModal()
        } else {
          proximoRamicash()
        }
      }
    } catch (error) {
      alert('Erro ao gravar Ramicash');
    }
  }

  const recusarPagamento = async() => {
    try {
      const DATA = await MyAPI.postRamicash({
        id: ramicash._id,
        status: 'Negado',
        motivo: motivoRecusa
      });

      if (DATA.data.error) {
        alert(DATA.data.error);
      } else {
        if ( (idxRamicash+1) >= data?.length ) {
          closeModal()  
        } else {
          proximoRamicash()
        }
      }
    } catch (error) {
      alert('Erro ao gravar Ramicash');
    }
  }

  const proximoRamicash = () => {
    setLoading(true)
    const idx = idxRamicash + 1;
    setIdxRamicash(idx)
    setRamicash(data[idx])
    setAttachment('')
    setRefresh(true)    
  }

  useEffect(() => {
    if ( data?.length > 0 ) {      
      setRamicash(data[0]);      
      setRefresh(true);      
    }
  
  }, [data])

  useEffect(() => {
    const generatePixQRCode = async () => {
      if ( ramicash ) {
        try {
          const DATA = await MyAPI.postRamicashQRCode({
            id: ramicash._id
          });
    
          if (DATA.data.error) {
            alert(DATA.data.error);
          } else {
            setQrCodeURL(DATA.data)
          }
        } catch (e) {
          alert('Erro ao gerar QRCode do PIX');
        }
      }
    }

    if ( refresh ) {
      generatePixQRCode();
      setRefresh(false);
    }
    
    if ( loading ) {
      setTimeout(() => {
        setLoading(false)
      }, 1000)
    }
  }, [refresh, loading, ramicash])

  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    height: '100%'    
  };
  
  const focusedStyle = {
    borderColor: '#2196f3'
  };
  
  const acceptStyle = {
    borderColor: '#00e676'
  };
  
  const rejectStyle = {
    borderColor: '#ff1744'
  };
  
  function StyledDropzone() {
    const onDrop = useCallback((acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader()

        reader.onload = () => {
          setAttachment(reader.result.toString());
        }
        reader.readAsDataURL(file)
      })
    }, [])

    const {
      getRootProps,
      getInputProps,
      isFocused,
      isDragAccept,
      isDragReject
    } = useDropzone({onDrop, accept: 'image/*,application/pdf', maxFiles: 1});
  
    const style = useMemo(() => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }), [
      isFocused,
      isDragAccept,
      isDragReject
    ]);

    return (      
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
        <p>Anexe o comprovante do PIX.</p>
        <p>Solte o arquivo ou clique aqui</p>
      </div>
    );
  }

  function ShowAttachment() {
    let isPDFAttachment = (attachment.split(';')[0].indexOf('pdf') >= 0);
    return (
      <div style={{ border: '1px solid #f5f5f5', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        <img style={{ width: '100%' }} src={ isPDFAttachment ? pdfIcon : attachment} />
        <span style={{ flex: '1 1 auto'}}></span>
        <CustomButton
            bsStyle="danger"
            onClick={() => setAttachment('')}
            style={{ marginLeft: '15px', marginBottom: '0px', border: 0, color: "white", background: "rgba(255,0,0, .8)",}}
            content='Remover'
          />
      </div>
    )
  }

  return (
    <Modal {...props} bsSize='large' style={{ margin: 'auto' }}>
      <Modal.Header closeButton onHide={closeModal}>
        <Modal.Title>Ramicash - Pagamento</Modal.Title>        
      </Modal.Header>


      { loading ? 
        <Modal.Body
          style={{
            overflowY: 'scroll',
            whiteSpace: 'nowrap',
          }}
        >
          <Row style={{display: 'flex'}}>
            <Col lg={12} style={{ textAlign: 'center', minWidth: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 24}}>
              <Spinner size={400} spinnercolor={"#333"} spinnerwidth={2} visible={true} />
              <span style={{ fontSize: 20, fontWeight: 200 , marginTop: 24 }}>Carregando Próximo Ramicash para pagamento...</span>
            </Col>
          </Row>
        </Modal.Body> 
      : 
        <Modal.Body
          style={{
            overflowY: 'scroll',
            whiteSpace: 'nowrap',
          }}
        >
          <Row style={{display: 'flex'}}>
            <Col lg={6} style={{ textAlign: 'center', minWidth: '50%'}}>
              <h4 style={{marginTop: 24}}>{ ramicash?.employee_id.nome || '' }</h4>
              <h5> CPF: { Formatacao("CPF",  ramicash?.employee_id.cpf ) }</h5>
              <h3 style={{marginTop: 24}}>{ Number(ramicash?.amount).toFixed(2).replace('.', ',') }</h3>
              { qrCodeURL !== '' ? 
                <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: 24}}>
                  <p>
                    <QRCode
                      style={{ height: '240px', width: '240px' }}
                      value={qrCodeURL}
                    />
                  </p>
                  <p>Aponte sua câmera para o Qrcode</p>
                  <p>Chave PIX: {ramicash?.pix_key}</p>
                </div>
              : <></> }
            </Col>
            <Col lg={6} style={{
              flex: '0 0 50%',
              display: 'flex',
              padding: 24,            
            }}>
              { attachment === '' ? <StyledDropzone /> : <ShowAttachment /> }
            </Col>
          </Row>
          <Row style={{ margin: '8px' }}>
            <Col md={12}>          
              <FormGroup>
                <ControlLabel>Motivo para Recusa do Ramicash</ControlLabel>
                <FormControl                 
                  type="text"
                  required={true}
                  bsClass="form-control"
                  placeholder="Informe o motivo da recusa do Ramicash"
                  rows={3}
                  onChange={(event) =>
                    setMotivoRecusa(event.target.value)
                  }
                />
              </FormGroup>
            </Col>
          </Row>
        </Modal.Body>
      }

      { loading ? <></> : 
        <Modal.Footer>
          <S.Footer>
            <CustomButton  
              disabled={motivoRecusa === '' || motivoRecusa.length < 10 }          
              onClick={() => recusarPagamento()}
              style={{ background: "rgba(255,0,0, .8)", color: "rgb(255,255,255)", marginLeft: '15px', marginBottom: '0px', marginRight: '4px' }}
              content='Recusar Pagamento'
            />
            <CustomButton
              disabled={attachment === ''}
              onClick={() => gravarPagamento()}
              style={{ marginLeft: '15px', marginBottom: '0px' }}
              content='Gravar Pagamento'
            />
          </S.Footer>
        </Modal.Footer>
      }
    </Modal>
  );
}
