import React from "react";
import Card from "../../../../components/Card/Card.jsx";

import { Grid, Row, Col } from "react-bootstrap";

export default function News() {
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              content={
                <div style={{ margin: '20px' }}>
                  <h4 style={{fontWeight: 'bold'}}>{'versão'.toUpperCase()}</h4>
                  <p style={{ color: 'gray' }}>
                    - Ajuste na alteração de dados de cadastro de credenciados.<br />
                    - Melhorado design das tabelas.<br />
                    - Atualizado campos de data para o modelo de autopreenchimento.<br />
                    - Melhorias na tela de cobrança.<br />
                  </p>
                </div>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}
