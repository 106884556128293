import React from "react";
import Loading from '../../../Components/Loading';
import MyAPI from "../../../../../connections/Api.jsx";
import DatePicker from "react-16-bootstrap-date-picker";
import Notifications from "../../../Components/Toast.jsx";
import Card from "../../../../../components/Card/Card.jsx";
import Button from "../../../../../components/CustomButton/CustomButton.jsx";

import { Table } from "react-bootstrap";
import { MultiSelect } from "primereact/multiselect";
import { ToastProvider } from "react-toast-notifications";
import { Grid, Row, Col, FormGroup, ControlLabel } from "react-bootstrap";
import { DatePikerConfig } from "../../../../../utils/Abstractions/Variables.jsx";
import { anyIso, isoShortDate } from "../../../../../utils/Abstractions/Functions.jsx";
import { PagarCredenciadosXlsx } from "../../../../../services/Excel/Exportacoes-xlsx.jsx";

import * as S from './Styles';

export default function Credenciados({ goTo }) {
  const [data, setData] = React.useState([]);
  const [error, setError] = React.useState([]);  
  const [selected, setSelect] = React.useState([]);
  const [selectedSituacoes, setSelectSituacoes] = React.useState([]);
  const [isLoading, setLoading] = React.useState(false);
  const [onSelectedList, setOnSelectedList] = React.useState(false);
  const [onSelectedSituacoes, setOnSelectedSituacoes] = React.useState(false);

  const [finalDate, setFinalDate] = React.useState("");
  const [initialDate, setInitialDate] = React.useState("");

  const cabecalhos = [
    "Código",
    "Credenciado",
    "Valor Bruto",
    "Valor Desconto",
    "Taxa Canal",
    "Valor Total",
  ];

  const cartoes = [
    { name: "Alimentação", code: "AL" },
    { name: "Produtos", code: "PR" },
    { name: "Serviços", code: "SE" },
    { name: "Combustível", code: "CO" }
  ];

  const situacoes = [
    { name: "Aberta", code: "Aberta" },
    { name: "Adiantada", code: "Adiantada" },
    { name: "Cancelado", code: "Cancelado" },
    { name: "Fechado", code: "Fechado" },
    { name: "Pendente", code: "Pendente" },
    { name: "Rescisao", code: "Rescisao" }
  ];

  const fetchData = async () => {
    let errors = 0

    if (selected.length === 0) {
      errors++
      setError([{ message: "Selecione pelo menos um canal!", appearance: "warning", auto_dismiss: true }, ...error]);
    }

    if (finalDate === '') {
      errors++
      setError([{ message: "Insira o período final!", appearance: "warning", auto_dismiss: true }, ...error]);
    }

    if (initialDate === '') {
      errors++
      setError([{ message: "Insira o período inicial!", appearance: "warning", auto_dismiss: true }, ...error]);
    }

    try {
      if (errors === 0) {
        setLoading(true)
        const response = await MyAPI.getVoucherList({ initialDate, finalDate, channels: selected.map(e => e.name), situacoes: selectedSituacoes.map(e => e.name) });
        
        setData(response.data.filter(e => (e.parcela_atual === 1) && ( selected.findIndex(c => c.name == e.canal ) >= 0 ) ))
        setLoading(false)
      }
    } catch (e) {
      setError([{ message: "Não foi possível buscar os dados de sua empresa!", appearance: "error", auto_dismiss: true }]);
    }
  }

  const valorCredenciadosTotal = (cnpj_cpf) => {
    return Number(
      data
        .filter((e) => e.credenciado_id.cnpj_cpf === cnpj_cpf)
        .map((e) => e.valor)
        .reduce((acumulador, elementoAtual) => acumulador + elementoAtual, 0)        
    ).toFixed(2);
  };

  const valorCredenciadosDesconto = (cnpj_cpf) => {

    const TaxaCanal = (guia) => {      
      const {canais_ativos} = guia.credenciado_id;
      const idx = canais_ativos
        .findIndex(c => c.canal.normalize('NFD')
        .replace(/[\u0300-\u036f]/g, "")
        .toUpperCase() === guia.canal.normalize('NFD')
        .replace(/[\u0300-\u036f]/g, "")
        .toUpperCase());

      let taxa = guia.taxa_ramicard;
      if ( idx >= 0 ) {
        taxa = +canais_ativos[idx].taxa;
      }
      
      return taxa/100;
    }

    return Number(
      data
        .filter((e) => e.credenciado_id.cnpj_cpf === cnpj_cpf)
        .filter((e) => e.situacao !== "Cancelado")
        .map((e) => e.valor * TaxaCanal(e))
        .reduce((acumulador, elementoAtual) => acumulador + elementoAtual, 0)
    ).toFixed(2);
  };

  const valorDescondoTotal = (cnpj_cpf) => {
    let AgrupamentoTaxa = 0;

    const CartaoAlimentacao = (ValorComTaxa) => {
      AgrupamentoTaxa += ValorComTaxa;
      return 0;
    };

    data
      .filter((e) => e.credenciado_id.cnpj_cpf === cnpj_cpf)
      .map((e) =>
        e.canal === "Alimentação" || e.canal === "Combustível"
          ? CartaoAlimentacao(e.valor * 0.05)
          : 0
      );

    return AgrupamentoTaxa.toFixed(2);
  };

  const valorTotalCredenciado = (cnpj_cpf) => {
    return Number(
      data
        .filter((e) => e.credenciado_id.cnpj_cpf === cnpj_cpf)
        .filter((e) => e.situacao !== "Cancelado")
        .map((e) => e.valor - e.valor * (e.taxa_ramicard / 100))
        .reduce((acumulador, elementoAtual) => acumulador + elementoAtual, 0)
    ).toFixed(2);
  };

  const aplicarFormatacao = (valorString) => {
    let valorFloat;
    const tamanhoDoValor = valorString.length;

    if (1 === tamanhoDoValor) {
      valorFloat = parseFloat("0.0" + valorString);
    } else if (2 === tamanhoDoValor) {
      valorFloat = parseFloat("0." + valorString);
    } else if (tamanhoDoValor > 2) {
      const centavos = valorString.slice(-2);
      const parteInteira = valorString.slice(0, tamanhoDoValor - 2);

      valorFloat = parseFloat(parteInteira.concat(centavos));
    }

    return isNaN(valorFloat)
      ? valorString
      : valorFloat.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
      });
  };

  const formatarMoeda = (valor) => {
    switch (typeof valor) {
      case "string":
        const valorSemEspacos = valor.trim();
        return isNaN(parseFloat(valorSemEspacos))
          ? valor
          : aplicarFormatacao(valorSemEspacos);
      case "number":
        return aplicarFormatacao(String(valor));
      default:
        return valor;
    }
  };

  const valorTotalCredenciados = () => {
    return Number(
      data
        .map((e) => e.valor - e.valor * (e.taxa_ramicard / 100))
        .reduce((acumulador, elementoAtual) => acumulador + elementoAtual, 0)
    ).toFixed(2);
  };

  const valorTotalCredenciadosDesconto = () => {
    return Number(
      data
        .map((e) => e.valor * (e.taxa_ramicard / 100))
        .reduce((acumulador, elementoAtual) => acumulador + elementoAtual, 0)
    ).toFixed(2);
  };

  const valorTotalCredenciadosTotal = () => {
    return Number(
      data
        .map((e) => e.valor)
        .reduce((acumulador, elementoAtual) => acumulador + elementoAtual, 0)
    ).toFixed(2);
  };

  const valorTotalDescontoTotal = () => {
    let AgrupamentoTaxa = 0;

    const CartaoAlimentacao = (ValorComTaxa) => {
      AgrupamentoTaxa += ValorComTaxa * 0.05;
    };

    data
      .map((e) =>
        e.canal === "Alimentação" || e.canal === "Combustível"
          ? CartaoAlimentacao(
            e.taxa_funcionario
              ? e.valor * Number(e.taxa_funcionario)
              : e.valor
          )
          : 0
      );

    return AgrupamentoTaxa.toFixed(2);
  };

  const duplicateFilter = (array) => {
    return array.filter(function (obj) {
      return (!this[JSON.stringify(obj.credenciado_id.nome_fantasia)] && (this[JSON.stringify(obj.credenciado_id.nome_fantasia)] = true))
    }, Object.create(null))
  }

  const removeSpace = corporateName => corporateName.trim().toUpperCase();

  const orderBy = array => array.sort((a, b) => removeSpace(a.credenciado_id.nome_fantasia) > removeSpace(b.credenciado_id.nome_fantasia) ? 1 : removeSpace(a.credenciado_id.nome_fantasia) < removeSpace(b.credenciado_id.nome_fantasia) ? -1 : 0)

  const GerarArquivo = () => {
    let dataSet = [
      {
        columns: [
          {
            title: "CÓDIGO",
            width: { wpx: 90 },
            style: {
              alignment: { horizontal: "center" },
              font: { sz: "14", bold: true },
            },
          },
          {
            title: "CREDENCIADO",
            width: { wch: 60 },
            style: {
              alignment: { horizontal: "center" },
              font: { sz: "14", bold: true },
            },
          },
          {
            title: "VALOR BRUTO",
            width: { wpx: 103 },
            style: {
              alignment: { horizontal: "center" },
              font: { sz: "14", bold: true },
            },
          },
          {
            title: "VALOR DESCONTO",
            width: { wpx: 125 },
            style: {
              alignment: { horizontal: "center" },
              font: { sz: "14", bold: true },
            },
          },
          {
            title: "TAXA CANAL",
            width: { wpx: 100 },
            style: {
              alignment: { horizontal: "center" },
              font: { sz: "14", bold: true },
            },
          },
          {
            title: "VALOR TOTAL",
            width: { wpx: 100 },
            style: {
              alignment: { horizontal: "center" },
              font: { sz: "14", bold: true },
            },
          },
        ],
        data: [],
      },
    ];

    orderBy(data)
      .filter(function (a) {
        return (
          !this[JSON.stringify(a.credenciado_id.nome_fantasia)] &&
          (this[JSON.stringify(a.credenciado_id.nome_fantasia)] = true)
        );
      }, Object.create(null))
      .map((item) =>
        item
          ? dataSet[0].data.push([
            {
              value: `${item.credenciado_id.registro
                ? item.credenciado_id.registro
                : "-"
                }`,
              style: {
                font: { sz: "14" },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: `${item.credenciado_id.nome_fantasia.toUpperCase()}`,
              style: {
                font: { sz: "14" },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: Number(
                valorCredenciadosTotal(item.credenciado_id.cnpj_cpf)
              ),
              style: {
                numFmt: "R$#,##0.00;\\(R$#,##0.00\\);\\-;@",
                font: { sz: "14" },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: Number(
                valorCredenciadosDesconto(item.credenciado_id.cnpj_cpf)
              ),
              style: {
                numFmt: "R$#,##0.00;\\(R$#,##0.00\\);\\-;@",
                font: { sz: "14" },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: Number(
                valorDescondoTotal(item.credenciado_id.cnpj_cpf)
              ),
              style: {
                numFmt: "R$#,##0.00;\\(R$#,##0.00\\);\\-;@",
                font: { sz: "14" },
                alignment: { horizontal: "center" },
              },
            },
            {
              value: Number(
                valorTotalCredenciado(item.credenciado_id.cnpj_cpf)
              ),
              style: {
                numFmt: "R$#,##0.00;\\(R$#,##0.00\\);\\-;@",
                font: { sz: "14" },
                alignment: { horizontal: "center" },
              },
            },
          ])
          : false
      )
      .filter((e) => e !== false);

    dataSet[0].data.push([
      { value: " " },
      {
        value: "Total à pagar: ",
        style: {
          font: { sz: "14", bold: true },
          alignment: { horizontal: "right" },
        },
      },
      {
        value: Number(valorTotalCredenciadosTotal()),
        style: {
          numFmt: "R$#,##0.00;\\(R$#,##0.00\\);\\-;@",
          font: { sz: "14", bold: true },
          alignment: { horizontal: "center" },
        },
      },
      {
        value: Number(valorTotalCredenciadosDesconto()),
        style: {
          numFmt: "R$#,##0.00;\\(R$#,##0.00\\);\\-;@",
          font: { sz: "14", bold: true },
          alignment: { horizontal: "center" },
        },
      },
      {
        value: Number(valorTotalDescontoTotal()),
        style: {
          numFmt: "R$#,##0.00;\\(R$#,##0.00\\);\\-;@",
          font: { sz: "14", bold: true },
          alignment: { horizontal: "center" },
        },
      },
      {
        value: Number(valorTotalCredenciados()),
        style: {
          numFmt: "R$#,##0.00;\\(R$#,##0.00\\);\\-;@",
          font: { sz: "14", bold: true },
          alignment: { horizontal: "center" },
        },
      },
    ]);

    return dataSet;
  };

  const downloadReport = async (fileName) => {
    try {
      const response = await MyAPI.getVoucherToPay({ 
        initialDate, 
        finalDate, 
        channels: selected.map(e => e.name),
        situacoes: selectedSituacoes.map(e => e.name), 
        fileName });
      
      if (response.error) {
        alert(response.message);
      } else {
        const blob = new Blob([response.data], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = url;
        link.download = `${fileName}.csv`;
        link.click();

        window.URL.revokeObjectURL(url);
      }

    } catch (e) {
      setError([
        {
          message: "Erro ao baixar o arquivo da empresa!",
          appearance: "error",
          auto_dismiss: true,
        },
      ]);
    }
  }

  
  return (
    <div className="content">
      <ToastProvider>
        <Notifications msg={error} clear={() => setError([])} />
      </ToastProvider>
      <Grid fluid>
        <Row>
          <Col md={12} style={{ marginTop: 25 }}>
            <Card
              title={
                <>
                  <Button
                    onClick={() => goTo("Menu")}
                    style={{ backgroundColor: "#008000", color: "#FFF" }}
                    simple={true}
                    type="button"
                    bsSize="sm"
                  >
                    {" "}
                    <i className="fa fa-bars" aria-hidden="true" /> Menu
                  </Button>
                  <br />
                  <p align="center" style={{ fontSize: 20 }}>
                    Relatório de pagamento à Prestadores de Serviços
                  </p>
                </>
              }
              content={
                <Row>
                  <Col md={2}>
                    <FormGroup>
                      <ControlLabel style={{ color: "#000" }}>
                        Período inicial
                      </ControlLabel>
                      <DatePicker
                        placeholder="DD/MM/YYYY"
                        value={anyIso(initialDate, "dd/MM/yyyy")}
                        monthLabels={DatePikerConfig.months}
                        dayLabels={DatePikerConfig.week}
                        onChange={(e) => setInitialDate(isoShortDate(e, "dd/MM/yyyy"))}
                      />
                    </FormGroup>
                  </Col>

                  <Col md={2}>
                    <FormGroup>
                      <ControlLabel style={{ color: "#000" }}>
                        Período Final
                      </ControlLabel>
                      <DatePicker
                        placeholder="DD/MM/YYYY"
                        value={anyIso(finalDate, "dd/MM/yyyy")}
                        monthLabels={DatePikerConfig.months}
                        dayLabels={DatePikerConfig.week}
                        onChange={(e) => setFinalDate(isoShortDate(e, "dd/MM/yyyy"))}
                      />
                    </FormGroup>
                  </Col>

                  <Col md={2}>
                    <ControlLabel style={{ color: "#000" }}>
                      Cartões
                    </ControlLabel>
                    <div
                      onMouseOut={() => setOnSelectedList(false)}
                      onMouseOver={() => setOnSelectedList(true)}
                    >
                      <MultiSelect
                        optionLabel="name"
                        value={selected}
                        style={{
                          height: 40,
                          width: "100%",
                          color: "gray",
                          opacity: !onSelectedList ? 0.3 : 1,
                        }}
                        options={cartoes}
                        placeholder={`Cartões Ramicard`}
                        onChange={(e) => setSelect(e.value)}
                      />
                    </div>
                  </Col>

                  <Col md={2}>
                    <ControlLabel style={{ color: "#000" }}>
                      Situação
                    </ControlLabel>
                    <div
                      onMouseOut={() => setOnSelectedSituacoes(false)}
                      onMouseOver={() => setOnSelectedSituacoes(true)}
                    >
                      <MultiSelect
                        optionLabel="name"
                        value={selectedSituacoes}
                        style={{
                          height: 40,
                          width: "100%",
                          color: "gray",
                          opacity: !onSelectedSituacoes ? 0.3 : 1,
                        }}
                        options={situacoes}
                        placeholder={`Situações das Guias`}
                        onChange={(e) => setSelectSituacoes(e.value)}
                      />
                    </div>
                  </Col>

                  <Col md={3}>
                    <S.WarapperButtons>
                      <Button
                        onClick={() => fetchData()}
                        style={{
                          backgroundColor: "#008000",
                          color: "#FFF",
                          marginRight: 15,
                          fontSize: 16,
                          width: 116,
                        }}
                        simple
                        type="button"
                        bsSize="sm"
                      >
                        <i className="fa fa-search" aria-hidden="true" />{" "}
                        Pesquisar
                      </Button>
                      {data.length > 0 ? 
                        <Button
                          onClick={() => downloadReport('pagamentos_a_prestadores')}
                          style={{
                            backgroundColor: "#008000",
                            color: "#FFF",
                            marginRight: 15,
                            fontSize: 16,
                            width: 116,
                          }}
                          simple
                          type="button"
                          bsSize="sm"
                        >
                          <i className="fa fa-file-text-o" aria-hidden="true" />{" "}
                          Baixar Lista
                        </Button>
                      : null}
                    </S.WarapperButtons>
                  </Col>
                </Row>
              }
            />

            <Card
              ctTableFullWidth
              ctTableResponsive
              content={
                <div>
                  <div align="center" style={{ margin: 20 }}>
                    {isLoading ? <Loading Height='250px' /> :
                      <Table striped hover>
                        <thead>
                          <tr>
                            {cabecalhos.map((prop, key) => <th key={key} style={{ textAlign: "center", fontWeight: "bold", color: "#000000" }}>{prop}</th>)}
                          </tr>
                        </thead>
                        <tbody align="center">
                          {duplicateFilter(orderBy(data)).map((prop, key) => {
                            return (
                              <tr key={key}>
                                <td>{prop.credenciado_id.registro ? prop.credenciado_id.registro.toUpperCase() : "-"}</td>
                                <td>{prop.credenciado_id.nome_fantasia.toUpperCase()}</td>
                                <td style={{ textAlign: "center" }}>{`R$ ${formatarMoeda(String(valorCredenciadosTotal(prop.credenciado_id.cnpj_cpf)))}`}</td>
                                <td style={{ textAlign: "center" }}>{`R$ ${formatarMoeda(String(valorCredenciadosDesconto(prop.credenciado_id.cnpj_cpf)))}`}</td>
                                <td style={{ textAlign: "center" }}>{`R$ ${formatarMoeda(String(valorDescondoTotal(prop.credenciado_id.cnpj_cpf)))}`}</td>
                                <td style={{ textAlign: "center" }}>{`R$ ${formatarMoeda(String(valorTotalCredenciado(prop.credenciado_id.cnpj_cpf)))}`}</td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td />
                            <td style={{ fontWeight: "bold", textAlign: "right" }}>Total Geral:</td>
                            <td style={{ fontWeight: "bold" }}>{`R$ ${formatarMoeda(String(valorTotalCredenciadosTotal()))}`}</td>
                            <td style={{ fontWeight: "bold" }}>{`R$ ${formatarMoeda(String(valorTotalCredenciadosDesconto()))}`}</td>
                            <td style={{ fontWeight: "bold" }}>{`R$ ${formatarMoeda(String(valorTotalDescontoTotal()))}`}</td>
                            <td style={{ fontWeight: "bold" }}>{`R$ ${formatarMoeda(String(valorTotalCredenciados()))}`}</td>
                          </tr>
                        </tbody>
                      </Table>}
                  </div>
                </div>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}
