import axios from 'axios';
import img from '../../../../assets/img/search.png';
import api from '../../../../services/api.jsx';
import Card from '../../../../components/Card/Card.jsx';
import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import capitalize from 'capitalize-pt-br';
import MyModal from '../../../../components/Modal/MyModal.jsx';
import { getToken } from '../../../../services/autenticacoes.jsx';
import Button from '../../../../components/CustomButton/CustomButton.jsx';
import { Tooltip, OverlayTrigger, FormControl } from 'react-bootstrap';
import ModalEmpresas from '../../../../components/Modal/ModalEmpresas.jsx';
import Spinner from 'react-spinner-material';
import { EmpresasXlsx } from '../../../../services/Excel/Exportacoes-xlsx.jsx';
import {
  Grid,
  Row,
  Col,
  FormGroup,
  Image,
  ControlLabel,
} from 'react-bootstrap';

export default function TableList() {
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [cadastro, setcadastro] = useState(false);
  const [editarEmpresa, setEditarEmpresa] = useState(false);
  const [Empresaselecionado, setEmpresaAtual] = useState({});

  // - Variaveis do Card Canal
  const [refresh, setRefresh] = useState(false);
  const [infos_cep, selInformacoes] = useState([]);
  const [canais_only, setcanaisOnly] = useState([]);
  const [dados_empresa, setdadosCredenciados] = useState([]);
  const [canaisSelecionados, setCanaisSelecionados] = useState([]);

  const editar = <Tooltip id='edit_tooltip'>Editar</Tooltip>;
  const gerarContrato = (
    <Tooltip id='remove_tooltip'>
      Clique aqui para gerar o contrato desta Empresa
    </Tooltip>
  );

  useEffect(() => {
    async function listarEmpresas() {
      setdadosCredenciados({
        ativo: true,
        status: true,

        razao_social: dados_empresa.razao_social, // - Obrigatório
        nome_fantasia: dados_empresa.nome_fantasia, // - Obrigatório
        cnpj: dados_empresa.cnpj, // - Obrigatório
        incricao_estadual:
          dados_empresa.incricao_estadual === undefined
            ? ''
            : dados_empresa.incricao_estadual,

        telefone:
          dados_empresa.telefone === undefined ? null : dados_empresa.telefone,
        celular:
          dados_empresa.celular === undefined ? null : dados_empresa.celular,
        whatsapp:
          dados_empresa.whatsapp === undefined ? false : dados_empresa.whatsapp,
        email: dados_empresa.email, // - Obrigatório
        proprietario:
          dados_empresa.proprietario === undefined
            ? ''
            : dados_empresa.proprietario,

        taxa_administrativa:
          dados_empresa.taxa_administrativa === undefined
            ? 0
            : dados_empresa.taxa_administrativa,
        dia_fechamento: dados_empresa.dia_fechamento, // - Obrigatório
        dia_pagamento: dados_empresa.dia_pagamento === undefined ? 10 : dados_empresa.dia_pagamento, // - Obrigatório

        servicos_autorizados:
          dados_empresa.servicos_autorizados === undefined
            ? {}
            : dados_empresa.servicos_autorizados,

        endereco:
          dados_empresa.endereco === undefined ? '' : dados_empresa.endereco,
        numero: dados_empresa.numero === undefined ? '' : dados_empresa.numero,
        bairro: dados_empresa.bairro === undefined ? '' : dados_empresa.bairro,
        cidade: dados_empresa.cidade === undefined ? '' : dados_empresa.cidade,
        cep: dados_empresa.cep === undefined ? '' : dados_empresa.cep,
        estado: dados_empresa.estado === undefined ? '' : dados_empresa.estado,
        anotacoes:
          dados_empresa.anotacoes === undefined ||
          dados_empresa.anotacoes === ''
            ? ''
            : dados_empresa.anotacoes,
      });
      try {
        const resposta = await api.get('/administrativo/empresa', {
          headers: { Authorization: `Bearer ${getToken()}`, acess: 0 },
        });
        if (resposta.data.error) {
          const canais = await api.get('/canais', {
            headers: { Authorization: `Bearer ${getToken()}`, acess: 0 },
          });
          setcanaisOnly(canais.data);
        } else {
          setData(resposta.data);
          const canais = await api.get('/canais', {
            headers: { Authorization: `Bearer ${getToken()}`, acess: 0 },
          });
          setcanaisOnly(canais.data);
        }
      } catch (error) {
        setError(true);
      }
    }
    listarEmpresas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function Cadastrar(event) {
    event.preventDefault();
    dados_empresa.cep = Number(
      String(dados_empresa.cep).replace('.', '').replace('-', '')
    );
    try {
      await api.post(`/administrativo/empresa/cadastro`, dados_empresa, {
        headers: { Authorization: `Bearer ${getToken()}`, acess: 0 },
      });
      setcadastro(!cadastro);
      window.location.reload();
    } catch (err) {
      alert('Ops! Ocorreu um erro.');
      setcadastro(!cadastro);
    }
  }

  useEffect(() => {
    async function listarcredenciados() {
      setRefresh(false);
    }
    listarcredenciados();
  }, [refresh]);

  function localiza(canais) {
    const dado = canaisSelecionados
      .map((element, index) => (element.canal === canais ? index : undefined))
      .filter((item) => item !== undefined);
    return dado[0];
  }

  const selecionar = (canal, parcelas) => {
    let dado = canaisSelecionados;
    const verifica = dado
      .map((element) => (element.canal === canal ? true : undefined))
      .filter((item) => item !== undefined);

    if (verifica.length > 0) {
      dado.splice(
        dado
          .map((element, index) =>
            element.canal === canal ? index : undefined
          )
          .filter((item) => item !== undefined),
        1
      );
      setCanaisSelecionados(dado);
    } else {
      dado.unshift({
        canal: canal,
        parcelas_max: Number(parcelas),
        limite_consumo: 0,
      });
      setCanaisSelecionados(dado);
    }

    dados_empresa.servicos_autorizados = dado;
    setRefresh(true);
  };

  const ativo = (canal) => {
    let dado = canaisSelecionados;
    const verifica = dado
      .map((element) => (element.canal === canal ? true : undefined))
      .filter((item) => item !== undefined);
    return !(verifica.length > 0);
  };

  async function cep(n_cep) {
    try {
      const informacoes = await axios.get(
        `https://viacep.com.br/ws/${n_cep}/json/`
      );
      selInformacoes(informacoes.data);
      dados_empresa.endereco = informacoes.data.logradouro;
      dados_empresa.bairro = informacoes.data.bairro;
      dados_empresa.cidade = informacoes.data.localidade;
      dados_empresa.estado = informacoes.data.uf;
    } catch (err) {
      alert('CEP inválido!', err);
    }
  }

  return (
    <>
      {data.length <= 0 ? (
        <div
          style={{
            backgroundColor: '#FFF',
            justifyContent: 'center',
            display: 'flex',
            flex: 1,
            height: '800px',
            alignItems: 'center',
          }}
        >
          {' '}
          <Spinner
            size={120}
            spinnercolor={'#333'}
            spinnerwidth={2}
            visible={true}
          />
        </div>
      ) : (
        <div className='content'>
          <Grid fluid>
            <Row>
              <MyModal show={error} onHide={() => setError(false)} />
              <ModalEmpresas
                {...Empresaselecionado}
                show={editarEmpresa}
                onHide={() => setEditarEmpresa(false)}
              />
              <Col md={12}>
                <Card
                  title={
                    <>
                      Empresas
                      <br />
                      <br />
                      <EmpresasXlsx files={data} />
                      <Button
                        onClick={() => {}}
                        style={{
                          backgroundColor: `${'#008000'}`,
                          color: '#FFF',
                          marginLeft: '10px',
                        }}
                        bsStyle='success'
                        disabled={true}
                        simple={true}
                        type='button'
                      >
                        <i className='fa fa-file-text-o' aria-hidden='true' />{' '}
                        .csv
                      </Button>
                    </>
                  }
                  content={
                    <div>
                      <div>
                        {!cadastro ? (
                          <Button
                            onClick={() => {
                              setcadastro(!cadastro);
                            }}
                            type='submit'
                            bsStyle='success'
                            variant='contained'
                            style={{
                              marginLeft: '5px',
                              marginBottom: '10px',
                              background: 'rgba(35,142,35, .8)',
                              border: 0,
                              borderRadius: 3,
                              boxShadow: '0 1px 1px 1px rgba(0, 0, 0, .2)',
                              color: 'white',
                              fontSize: '14px',
                            }}
                          >
                            {' '}
                            {!cadastro ? 'Nova Empresa' : 'Voltar'}
                          </Button>
                        ) : (
                          <></>
                        )}
                      </div>
                      {!cadastro ? (
                        // - Lista de Empresas
                        <ReactTable
                          data={data.map((prop, key) => {
                            return {
                              id: key,
                              ativo: `${prop.ativo ? 'ATIVO' : 'SUSPENSO'}`,
                              razao_social: capitalize(
                                prop.razao_social
                              ).toUpperCase(),
                              nome_fantasia: String(
                                prop.nome_fantasia
                              ).toUpperCase(),
                              cnpj: prop.cnpj,
                              contato:
                                String(prop.celular).length <= 0 ||
                                prop.celular === null
                                  ? 'Solicitar Contato'
                                  : String(prop.celular).length === 11
                                  ? `(${String(prop.celular)[0]}${
                                      String(prop.celular)[1]
                                    }) ${String(prop.celular).slice(2)}`
                                  : prop.celular,
                              acoes: (
                                <div className='actions-center'>
                                  <OverlayTrigger
                                    placement='top'
                                    overlay={editar}
                                  >
                                    <Button
                                      onClick={() => {
                                        setEditarEmpresa(true);
                                        setEmpresaAtual([prop]);
                                      }}
                                      bsStyle='info'
                                      simple={true}
                                      type='button'
                                      bsSize='sm'
                                    >
                                      <div style={{ fontSize: 20 }}>
                                        <i className='fa fa-edit' />
                                      </div>
                                    </Button>
                                  </OverlayTrigger>

                                  <OverlayTrigger
                                    placement='top'
                                    overlay={gerarContrato}
                                  >
                                    <Button
                                      onClick={() => {}}
                                      bsStyle='danger'
                                      simple={true}
                                      type='button'
                                      bsSize='sm'
                                    >
                                      <div style={{ fontSize: 20 }}>
                                        <i
                                          className='fa fa-file-pdf-o'
                                          aria-hidden='true'
                                        />
                                      </div>
                                    </Button>
                                  </OverlayTrigger>
                                </div>
                              ),
                            };
                          })}
                          filterable
                          columns={[
                            {
                              Header: 'Status',
                              accessor: 'ativo',
                            },
                            {
                              Header: 'RAZÃO SOCIAL',
                              accessor: 'razao_social',
                            },
                            {
                              Header: 'NAME FANTASIA',
                              accessor: 'nome_fantasia',
                            },
                            {
                              Header: 'CNPJ',
                              accessor: 'cnpj',
                            },
                            {
                              Header: 'CONTATO',
                              accessor: 'contato',
                            },
                            {
                              Header: '',
                              accessor: 'acoes',
                              filterable: false,
                              sortable: false,
                            },
                          ]}
                          defaultPageSize={100}
                          showPaginationTop
                          showPaginationBottom={false}
                          className='-striped -highlight text-center'
                        />
                      ) : (
                        // --> Cadastro de Empresa

                        <form style={{ margin: '10px' }} onSubmit={Cadastrar}>
                          <Row>
                            <Col md={3}>
                              <FormGroup>
                                <ControlLabel style={{ color: '#000' }}>
                                  Razão Social*
                                </ControlLabel>
                                <FormControl
                                  type='text'
                                  required={true}
                                  bsClass='form-control'
                                  placeholder='Razão Social'
                                  onChange={(event) =>
                                    (dados_empresa.razao_social =
                                      event.target.value)
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col md={3}>
                              <FormGroup>
                                <ControlLabel style={{ color: '#000' }}>
                                  Nome Fantasia*
                                </ControlLabel>
                                <FormControl
                                  type='text'
                                  bsClass='form-control'
                                  required={true}
                                  placeholder='Nome Fantasia'
                                  onChange={(event) =>
                                    (dados_empresa.nome_fantasia =
                                      event.target.value)
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col md={2}>
                              <FormGroup>
                                <ControlLabel style={{ color: '#000' }}>
                                  Inscrição Est. ou Mun.
                                </ControlLabel>
                                <FormControl
                                  type='text'
                                  bsClass='form-control'
                                  placeholder='Insc. Estadual/Municipal'
                                  onChange={(event) =>
                                    (dados_empresa.incricao_estadual =
                                      event.target.value)
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col md={2}>
                              <FormGroup>
                                <ControlLabel style={{ color: '#000' }}>
                                  CNPJ*
                                </ControlLabel>
                                <FormControl
                                  type='text'
                                  required={true}
                                  bsClass='form-control'
                                  placeholder='CNPJ'
                                  onChange={(event) =>
                                    (dados_empresa.cnpj = event.target.value)
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col md={2}>
                              <FormGroup>
                                <ControlLabel style={{ color: '#000' }}>
                                  Dia Fechamento*
                                </ControlLabel>
                                <FormControl
                                  type='text'
                                  required={true}
                                  bsClass='form-control'
                                  placeholder='Dia do Fechamento'
                                  onChange={(event) =>
                                    (dados_empresa.dia_fechamento =
                                      event.target.value)
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={2}>
                              <FormGroup>
                                <ControlLabel style={{ color: '#000' }}>
                                  CEP
                                </ControlLabel>
                                <FormControl
                                  type='text'
                                  bsClass='form-control'
                                  placeholder='CEP'
                                  onChange={(event) =>
                                    (dados_empresa.cep = event.target.value)
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col md={1}>
                              <Button
                                onClick={() => cep(dados_empresa.cep)}
                                variant='contained'
                                bsStyle='info'
                                style={{
                                  border: 0,
                                  color: 'white',
                                  borderRadius: 3,
                                  fontSize: '14px',
                                  marginLeft: '10px',
                                  marginTop: '27px',
                                  background: 'rgba(35,142,35, .8)',
                                  boxShadow: '0 1px 1px 1px rgba(0, 0, 0, .2)',
                                }}
                              >
                                <Image
                                  src={img}
                                  style={{ width: '22px', height: '22px' }}
                                ></Image>
                              </Button>
                            </Col>
                            <Col md={2}>
                              <FormGroup>
                                <ControlLabel style={{ color: '#000' }}>
                                  Cidade
                                </ControlLabel>
                                <FormControl
                                  type='text'
                                  bsClass='form-control'
                                  placeholder='Cidade'
                                  defaultValue={
                                    infos_cep.length <= 0
                                      ? ''
                                      : infos_cep.localidade
                                  }
                                  onChange={(event) =>
                                    (dados_empresa.cidade = event.target.value)
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col md={2}>
                              <FormGroup>
                                <ControlLabel style={{ color: '#000' }}>
                                  Estado
                                </ControlLabel>
                                <FormControl
                                  type='text'
                                  bsClass='form-control'
                                  placeholder='Estado'
                                  defaultValue={
                                    infos_cep.length <= 0 ? '' : infos_cep.uf
                                  }
                                  onChange={(event) =>
                                    (dados_empresa.estado = event.target.value)
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col md={2}>
                              <FormGroup>
                                <ControlLabel style={{ color: '#000' }}>
                                  Endereço
                                </ControlLabel>
                                <FormControl
                                  type='text'
                                  bsClass='form-control'
                                  placeholder='Endereço'
                                  defaultValue={
                                    infos_cep.length <= 0
                                      ? ''
                                      : infos_cep.logradouro
                                  }
                                  onChange={(event) =>
                                    (dados_empresa.endereco =
                                      event.target.value)
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col md={1}>
                              <FormGroup>
                                <ControlLabel style={{ color: '#000' }}>
                                  Número
                                </ControlLabel>
                                <FormControl
                                  type='text'
                                  bsClass='form-control'
                                  placeholder='Número'
                                  onChange={(event) =>
                                    (dados_empresa.numero = event.target.value)
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col md={2}>
                              <FormGroup>
                                <ControlLabel style={{ color: '#000' }}>
                                  Bairro
                                </ControlLabel>
                                <FormControl
                                  type='text'
                                  bsClass='form-control'
                                  placeholder='Bairro'
                                  defaultValue={
                                    infos_cep.length <= 0
                                      ? ''
                                      : infos_cep.bairro
                                  }
                                  onChange={(event) =>
                                    (dados_empresa.bairro = event.target.value)
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          {canais_only.length > 0 ? (
                            <Card
                              title='Canais Liberados'
                              content={canais_only.map((item, key) => (
                                <Row key={key}>
                                  <Col md={4}>
                                    <FormGroup>
                                      <ControlLabel style={{ color: '#000' }}>
                                        Nome do Serviço
                                      </ControlLabel>
                                      <FormControl
                                        type='text'
                                        bsClass='form-control'
                                        placeholder='Nome do Serviço'
                                        value={item.canal}
                                        readOnly
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={1}>
                                    <FormGroup>
                                      <ControlLabel style={{ color: '#000' }}>
                                        Parcelas
                                      </ControlLabel>
                                      <FormControl
                                        type='number'
                                        bsClass='form-control'
                                        placeholder='Parcelas'
                                        disabled={ativo(item.canal)}
                                        defaultValue={item.parcelas_max}
                                        onChange={(event) =>
                                          (canaisSelecionados[
                                            localiza(item.canal)
                                          ].parcelas_max = event.target.value)
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={1}>
                                    <FormGroup>
                                      <ControlLabel style={{ color: '#000' }}>
                                        Ativo
                                      </ControlLabel>
                                      <div
                                        style={{
                                          marginTop: '10px',
                                          marginLeft: '10px',
                                        }}
                                      >
                                        <input
                                          type='checkbox'
                                          defaultChecked={!ativo(item.canal)}
                                          onClick={() => {
                                            selecionar(
                                              item.canal,
                                              item.parcelas_max
                                            );
                                          }}
                                        />
                                      </div>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              ))}
                            />
                          ) : (
                            <></>
                          )}
                          <Row>
                            <Col md={12}>
                              <FormGroup
                                controlId='formControlsTextarea'
                                style={{ marginBottom: '-30px' }}
                              >
                                <ControlLabel style={{ color: '#000' }}>
                                  Anotações
                                </ControlLabel>
                                <FormControl
                                  rows='3'
                                  componentClass='textarea'
                                  bsClass='form-control'
                                  placeholder='Anotação'
                                  onChange={(event) =>
                                    (dados_empresa.anotacoes =
                                      event.target.value)
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Button
                            variant='contained'
                            type='submit'
                            bsStyle='info'
                            style={{
                              marginTop: '35px',
                              marginRight: '10px',
                              background: 'rgba(35,142,35, .8)',
                              border: 0,
                              borderRadius: 3,
                              boxShadow: '0 1px 1px 1px rgba(0, 0, 0, .2)',
                              color: 'white',
                              fontSize: '14px',
                            }}
                          >
                            {' '}
                            Cadastrar
                          </Button>
                          <Button
                            onClick={() => {
                              setcadastro(!cadastro);
                            }}
                            variant='contained'
                            bsStyle='info'
                            style={{
                              marginTop: '35px',
                              background: 'rgba(35,142,35, .8)',
                              border: 0,
                              borderRadius: 3,
                              boxShadow: '0 1px 1px 1px rgba(0, 0, 0, .2)',
                              color: 'white',
                              fontSize: '14px',
                            }}
                          >
                            {' '}
                            Cancelar
                          </Button>
                        </form>
                      )}
                    </div>
                  }
                />
              </Col>
            </Row>
          </Grid>
        </div>
      )}
    </>
  );
}
