import React, { useState, useEffect } from 'react';
import Card from '../../../../components/Card/Card.jsx';
import Button from '../../../../components/CustomButton/CustomButton.jsx';
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Table,
} from 'react-bootstrap';

import { simulation } from '../../../../utils/Tax.js';

export default function Simulator() {
  const [valor, setValor] = useState(0);
  const [numParcelas, setNumParcelas] = useState(0);
  const [taxaJuros, setTaxaJuros] = useState(0);

  const [totalParcelas, setTotalParcelas] = useState(0);
  const [totalJuros, setTotalJuros] = useState(0);
  const [simulacao, setSimulacao] = useState([]);

  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    async function refreshScreen() {
      setRefresh(false);
    }
    refreshScreen();
  }, [refresh]);

  const gerarSimulacao = () => {
    const taxa =
      taxaJuros.indexOf(',') > 0 ? taxaJuros.replace(',', '') : taxaJuros;
    const result = simulation(
      Number(valor),
      Number(taxa) / 100,
      Number(numParcelas)
    );

    setTotalParcelas(
      result.installments.reduce((acc, val) => (acc += val.value), 0)
    );
    setTotalJuros(result.totalInterest);
    setSimulacao(result.installments);

    setRefresh(true);
  };

  return (
    <div className='content'>
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title='Simule um Financiamento ou Empréstimo'
              content={
                <div>
                  <Row>
                    <Col md={4}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Valor do Empréstimo ou Financiamento
                        </ControlLabel>
                        <FormControl
                          type='text'
                          autoFocus
                          required={true}
                          bsClass='form-control'
                          placeholder='Exemplo: 10000'
                          onChange={(event) => setValor(event.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Numero de Parcelas
                        </ControlLabel>
                        <FormControl
                          type='text'
                          required={true}
                          bsClass='form-control'
                          placeholder='Exemplo: 10'
                          defaultValue={0}
                          onChange={(event) =>
                            setNumParcelas(event.target.value)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: '#000' }}>
                          Taxa de Juros (Mês) %
                        </ControlLabel>
                        <FormControl
                          type='number'
                          required={true}
                          bsClass='form-control'
                          placeholder='Exemplo: 3,5'
                          defaultValue={0}
                          onChange={(event) => setTaxaJuros(event.target.value)}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={2}>
                      <FormGroup>
                        <Button
                          type='submit'
                          variant='contained'
                          bsStyle='info'
                          style={{
                            marginTop: '26px',
                            marginLeft: '10px',
                            background: 'rgba(35,142,35, .8)',
                            border: 0,
                            borderRadius: 3,
                            boxShadow: '0 1px 1px 1px rgba(0, 0, 0, .2)',
                            color: 'white',
                            fontSize: '14px',
                          }}
                          onClick={() => gerarSimulacao()}
                        >
                          {' '}
                          Calcular
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row style={{ height: '30px' }}></Row>

                  {simulacao === undefined ||
                  simulacao === null ||
                  simulacao === [] ? (
                    <></>
                  ) : (
                    <Row>
                      <Table striped hover>
                        <thead align='center'>
                          <tr>
                            <td>
                              <b>#</b>
                            </td>
                            <td>
                              <b>Parcela&nbsp;*</b>
                            </td>
                            <td>
                              <b>Amortizações</b>
                            </td>
                            <td>
                              <b>Juros</b>
                            </td>
                            <td>
                              <b>Saldo Devedor</b>
                            </td>
                          </tr>
                        </thead>
                        <tbody align='center'>
                          {simulacao.map((prop, key) => {
                            return (
                              <tr key={key}>
                                <td>{prop.installment}</td>
                                <td>{`R$ ${prop.value
                                  .toFixed(2)
                                  .replace('.', ',')}`}</td>
                                <td>{`R$ ${prop.amortization
                                  .toFixed(2)
                                  .replace('.', ',')}`}</td>
                                <td>{`R$ ${prop.interest
                                  .toFixed(2)
                                  .replace('.', ',')}`}</td>
                                <td>{`R$ ${prop.debitBalance
                                  .toFixed(2)
                                  .replace('.', ',')}`}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                        <tfoot align='center'>
                          <tr>
                            <td>
                              <b>Totais</b>
                            </td>
                            <td>
                              <b>{`R$ ${totalParcelas
                                .toFixed(2)
                                .replace('.', ',')}`}</b>
                            </td>
                            <td>
                              <b>{`R$ ${Number(valor)
                                .toFixed(2)
                                .replace('.', ',')}`}</b>
                            </td>
                            <td>
                              <b>{`R$ ${totalJuros
                                .toFixed(2)
                                .replace('.', ',')}`}</b>
                            </td>
                            <td></td>
                          </tr>
                        </tfoot>
                      </Table>
                    </Row>
                  )}
                </div>
              }
              legend={
                <div>
                  <i>
                    Fixas (Tabela Price) <br /> * Parcela = (Amortização +
                    Juros)
                  </i>
                </div>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}
