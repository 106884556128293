import { printer } from '../../../utils/Relatorios.js';
var QRCode = require('qrcode.react'); /* eslint-disable import/first */
import socketio from 'socket.io-client';
import img from '../../../assets/img/print.png';
import React, { useEffect, useState } from 'react';
import { serverAddress } from '../../../utils/Variaveis.js';
import { Modal, Button, Image, Table } from 'react-bootstrap';

export default function MyModal(props) {
  const [credenciadoID, idCredenciado] = useState(undefined);
  const [imprimir, imprimirComprovante] = useState(null);

  const cabecalhos = [
    'Canal',
    'Data & Hora',
    'Valor',
    'Nº de Protocolo',
    'Imprimir',
  ];

  useEffect(() => {
    if (props.show === true) {
      idCredenciado(props.credenciado_data);
      const credenciado_id = props.informacoes;

      async function fetchData() {
        const socket = socketio(serverAddress, {
          query: { user_id: credenciado_id },
        });

        socket.on('Confirmacao_Pagamento', (dataFiles) => {
          imprimirComprovante([
            { dados: dataFiles, codigo: props.codigo_transfere },
          ]);
        });
      }

      fetchData();
    }
  }, [props, imprimir]);

  const clearExit = (props) => {
    idCredenciado(undefined);
    imprimirComprovante(null);
    props.onHide();
    props.onCleared();
  };

  return imprimir === null ? (
    // - Modal de Confirmação
    <Modal
      {...props}
      centered={`${true}`}
      style={{ margin: 'auto' }}
      onExit={() => clearExit(props)}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div style={{ textAlign: 'center', color: '#ff0000' }}>
            Aguardando confirmação de pagamento...
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ textAlign: 'center' }}>
          <p>
            <QRCode
              style={{ height: '160px', width: '160px' }}
              value={props.codigo_transfere}
            />
          </p>
          <p>Aponte sua câmera para o Qrcode Acima!</p>
          <p>{`Codigo de Venda: ${props.codigo_transfere}`}</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => props.onHide()}>Cancelar</Button>
      </Modal.Footer>
    </Modal>
  ) : (
    // - Modal de Impressão
    <Modal
      {...props}
      centered={`${true}`}
      style={{ margin: 'auto' }}
      onExit={() => clearExit(props)}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div style={{ textAlign: 'center', color: '#228b22' }}>
            Pagamento efetuado com sucesso!
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ textAlign: 'center' }}>
          <Table striped hover>
            <thead align='center'>
              <tr>
                {cabecalhos.map((prop, key) => {
                  return <th key={key}>{prop}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {imprimir.map((prop, key) => {
                return (
                  <tr key={key}>
                    <td>{prop.dados.Canal}</td>
                    <td>{`${prop.dados.Data}`}</td>
                    <td>{`R$ ${prop.dados.Venda.toFixed(2).replace(
                      '.',
                      ','
                    )}`}</td>
                    <td>{prop.dados.Codigo}</td>
                    <td>
                      <Button
                        onClick={() => {
                          printer(
                            prop.dados.Codigo,
                            prop.dados.Canal,
                            prop.dados.Data,
                            prop.dados.Venda,
                            credenciadoID.fantasia === undefined
                              ? credenciadoID.razao
                              : credenciadoID.fantasia,
                            'Ramicard',
                            prop.dados.Empresa,
                            prop.dados.Usuario,
                            prop.dados.Parcelas,
                            prop.dados.Obs,
                            false,
                            false,
                            prop.dados.Taxa_funcionario
                          );
                        }}
                        variant='contained'
                        bsStyle='info'
                        style={{
                          border: 0,
                          color: 'white',
                          borderRadius: 3,
                          fontSize: '14px',
                          background: 'rgba(35,142,35, .8)',
                          boxShadow: '0 1px 1px 1px rgba(0, 0, 0, .2)',
                        }}
                      >
                        <Image
                          src={img}
                          style={{ width: '20px', height: '20px' }}
                        ></Image>
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => props.onHide()}>Cancelar</Button>
      </Modal.Footer>
    </Modal>
  );
}
