import React from "react";
import ReactTable from "react-table";
import capitalize from "capitalize-pt-br";
import Loading from '../../Components/Loading';
import MyAPI from "../../../../connections/Api.jsx";
import Card from "../../../../components/Card/Card.jsx";
import Notifications from "../../Components/Toast/Toast.jsx";
import Button from "../../../../components/CustomButton/CustomButton.jsx";

import { ToastProvider } from "react-toast-notifications";
import { Formatacao } from "../../../../utils/Functions/Formatacoes.jsx";
import { Tooltip, OverlayTrigger, Grid, Row, Col } from "react-bootstrap";
import { FuncionariosXlsx } from "../../../../services/Excel/Exportacoes-xlsx.jsx";

import { Editar } from "./Edit.jsx";

export default function Indice() {
  const [error, setError] = React.useState([]);
  const [employees, setEmployees] = React.useState(null)
  const [paginacao, setPagina] = React.useState({ pagina: 0, dados: [] });

  React.useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await MyAPI.getInformation();
        const response = await MyAPI.getEmployees(data._id);

        setEmployees(response.data);
      } catch (e) {
        setError([{ message: "Não foi possível buscar os dados de sua empresa ou seus funcionários!", appearance: "error", auto_dismiss: true }]);
      }
    }
    fetchData();
  }, []);

  if (employees === null) { return <Loading /> }

  if (paginacao.pagina === 1) {
    return <Editar
      dados_funcionario={paginacao.dados}
      voltar={() => setPagina({ pagina: 0, dados: [] })}
    />
  }

  return (
    <div className="content">
      <ToastProvider>
        <Notifications msg={error} clear={() => setError([])} />
      </ToastProvider>
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title={
                <>
                  Lista de Funcionários
                  <br />
                  <br />
                  <FuncionariosXlsx files={employees} buttonName="Download" />
                </>
              }
              content={
                <ReactTable
                  data={employees.map((prop, key) => {
                    return {
                      id: key,
                      registro: prop.registro.toUpperCase(),
                      nome: capitalize(prop.nome.normalize("NFD").replace(/[\u0300-\u036f]/g, "")).toUpperCase(),
                      limite: String(prop.ramicard.limite.toFixed(2)).replace('.', ','),
                      cpf: Formatacao("CPF", prop.cpf).length === 14 ? Formatacao("CPF", prop.cpf) : "Solicite uma atualização!",
                      email: prop.email.split('').length > 0 ? prop.email.toUpperCase() : 'Solicite uma atualização!',
                      contato: Formatacao("CEL", prop.celular).length > 0 ? Formatacao("CEL", prop.celular) : "Solicite uma atualização!",
                      acoes: (
                        <div className="actions-center">
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="edit_tooltip">Mais informações</Tooltip>}>
                            <Button
                              onClick={() => setPagina({ pagina: 1, dados: prop })}
                              bsStyle="info"
                              type="button"
                            >
                              <div style={{ fontSize: 20, fontWeight: 'bold' }}>
                                <i className="fa fa-info" />
                              </div>
                            </Button>
                          </OverlayTrigger>
                        </div>
                      ),
                    };
                  })}
                  filterable
                  columns={[
                    {
                      Header: "Registro",
                      accessor: "registro",
                    },
                    {
                      Header: "FUNCIONÁRIO",
                      accessor: "nome",
                    },
                    {
                      Header: "LIMITE DE PARCELAMENTO",
                      accessor: "limite",
                    },
                    {
                      Header: "CPF",
                      accessor: "cpf",
                    },
                    {
                      Header: "EMAIL",
                      accessor: "email",
                    },
                    {
                      Header: "CONTATO",
                      accessor: "contato",
                    },
                    {
                      Header: "",
                      accessor: "acoes",
                      filterable: false,
                      sortable: false,
                    },
                  ]}
                  defaultPageSize={15}
                  showPaginationTop
                  showPaginationBottom={false}
                  className="-striped -highlight text-center"
                />
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}
