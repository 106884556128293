import React, { useState, useEffect } from 'react'
import GoogleMapReact from 'google-map-react'

import './map.css'

const getInfoWindowString = (endereco) => `
    <div>
      <div style="font-size: 16px;">
        ${endereco}
      </div>      
    </div>`;

    
const Map = ({ location, zoomLevel, updateLocation }) => {
  const [storeLocation, setStoreLocation ] = useState(undefined);
  
  useEffect(() => {
    setStoreLocation(location);
  }, [location])  

  const storeMapState = (map, maps) => {
    let marker = new maps.Marker({
      position: {lat: storeLocation.lat, lng: storeLocation.lng},
      map,
      title: storeLocation.address,
      infoWindow: storeLocation.address,
      draggable: true
    });

    let info = new maps.InfoWindow({
      content: getInfoWindowString(storeLocation.address)
    })

    marker.addListener('click', () => {
      info.open(map, marker);
    });

    marker.addListener('dragend', () => {
      updateLocation(marker.getPosition().lat(), marker.getPosition().lng());
    })

    return marker;
  }

  return (
    <div className="map">
      <div className="google-map">
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyC4zHKqfSDvzufq7RVNXCvKR4IZpwGgtvo' }}
          defaultCenter={location}
          defaultZoom={zoomLevel}
          yesIWantToUseGoogleMapApiInternals={true}
          onGoogleApiLoaded={({ map, maps }) => storeMapState(map, maps)}
        >
        </GoogleMapReact>
      </div>
    </div>
  )
}

export default Map