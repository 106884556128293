import Employes from "../views/Companie/Pages/Employes/Index.jsx";
import Information from "../views/Companie/Pages/Information/Index.jsx";
import Settings from "../views/Companie/Pages/Companie/Index.jsx";

const Routes = [
  {
    path: "/configuracoes",
    name: "Empresa",
    icon: "pe-7s-display2",
    component: Settings,
    layout: "/empresa",
  },
  {
    path: "/funcionarios",
    name: "Funcionários",
    icon: "pe-7s-id",
    component: Employes,
    layout: "/empresa",
  },
  {
    path: "/relatorio",
    name: "Relatorio Analítico",
    icon: "pe-7s-graph3",
    component: Information,
    layout: "/empresa",
  },
  {
    path: "/suporte",
    name: "Fale Conosco",
    icon: "fa fa-whatsapp",
    layout: "/empresa",
    upgrade: 'active-pro'
  }
];

export default Routes;
