import Vendas from "../views/Credenciados/Pages/Sales.jsx";
import Extratos from "../views/Credenciados/Pages/Extracts.jsx";
import InfoMaps from "../views/Credenciados/Pages/Location.jsx";
import Estornos from "../views/Credenciados/Pages/Reverse.jsx";
import Configuracoes from "../views/Credenciados/Pages/Settings.jsx";

const Routes = [
  {
    path: "/servicos",
    name: "Vendas",
    icon: "pe-7s-shopbag",
    component: Vendas,
    layout: "/credenciado",
  },

  {
    path: "/extratos",
    name: "Extratos",
    icon: "pe-7s-graph1",
    component: Extratos,
    layout: "/credenciado",
  },

  {
    path: "/relatorios",
    name: "Estornos",
    icon: "pe-7s-refresh-2",
    component: Estornos,
    layout: "/credenciado",
  },

  {
    path: "/infomaps",
    name: "Info Maps",
    icon: "pe-7s-map-marker",
    component: InfoMaps,
    layout: "/credenciado",
  },

  {
    path: "/configuracoes",
    name: "Configurações",
    icon: "pe-7s-config",
    component: Configuracoes,
    layout: "/credenciado",
  },
  {
    path: "/suporte",
    name: "Fale Conosco",
    icon: "fa fa-whatsapp",
    layout: "/credenciado",
    upgrade: 'active-pro'
  }
];

export default Routes;
