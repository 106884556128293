import React, { useState } from "react";
import InputMask from "react-input-mask";
import Notifications from "../Components/Toast/Toast";
import MyAPI from "../../../connections/Api.jsx";
import logo from "../../../assets/img/new_logo_ramicard.png";
import wallpaper from "../../../assets/img/wallpaper_01.jpg";

import { makeStyles } from "@material-ui/core/styles";
import { ToastProvider } from "react-toast-notifications";
import {
  justNumbers,
  getStatusCode,
  saveItens,
} from "../../../utils/Abstractions/Functions.jsx";

import {
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
} from "react-bootstrap";
import { Link } from "@material-ui/core";

export default function Index({ history }) {
  const [page, setPage] = useState(0);
  const [error, setError] = useState([]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [document, setDocument] = useState("");
  const [fantasyName, setFantasyName] = useState("");

  const styles = useStyles();

  const clearForm = (page) => {
    setEmail("");
    setPassword("");
    setDocument("");
    setFantasyName("");
    setPage(page);
  };

  async function Login(event) {
    event.preventDefault();

    let validations = 0;

    if (justNumbers(password).length === 0) {
      validations++;
      setError([
        ...error,
        {
          message: "Por favor insira a SENHA!",
          appearance: "error",
          auto_dismiss: true,
        },
      ]);
    }

    if (justNumbers(document).length === 0) {
      validations++;
      setError([
        ...error,
        {
          message: "Por favor insira o CNPJ!",
          appearance: "error",
          auto_dismiss: true,
        },
      ]);
    }

    try {
      if (validations === 0) {
        let filesForSave = [];
        const response = await MyAPI.postLogin({ document, password });

        filesForSave.push({ name: "Token", value: response.data.token });
        filesForSave.push({ name: "Empresa", value: response.data.empresa.nome_fantasia });
        
        saveItens(filesForSave);

        history.push("/empresa/funcionarios");
      }
    } catch (e) {
      setError([
        ...error,
        {
          message:
            getStatusCode(e) === "404"
              ? "CNPJ ou SENHA não correspondem ao cadastrado ou ainda não foram registrados!"
              : "Ocorreu um problema na solicitação, mas já estamos trabalhando para uma solução! Agradecemos a compreenção.",
          appearance: getStatusCode(e) === "404" ? "error" : "info",
          auto_dismiss: getStatusCode(e) === "404" ? true : false,
        },
      ]);
    }
  }

  async function Register(event) {
    event.preventDefault();

    let validations = 0;

    if (password.length === 0) {
      validations++;
      setError([
        ...error,
        {
          message: "Por favor insira a SENHA!",
          appearance: "error",
          auto_dismiss: true,
        },
      ]);
    }

    if (justNumbers(document).length === 0) {
      validations++;
      setError([
        ...error,
        {
          message: "Por favor insira o CNPJ!",
          appearance: "error",
          auto_dismiss: true,
        },
      ]);
    }

    if (fantasyName.length === 0) {
      validations++;
      setError([
        ...error,
        {
          message: "Por favor insira o NOME FANTASIA!",
          appearance: "error",
          auto_dismiss: true,
        },
      ]);
    }

    if (email.length === 0) {
      validations++;
      setError([
        ...error,
        {
          message: "Por favor insira o EMAIL!",
          appearance: "error",
          auto_dismiss: true,
        },
      ]);
    }

    try {
      if (validations === 0) {
        await MyAPI.postRegister({ email, fantasyName, document, password });
        clearForm(0);
        setError([
          ...error,
          {
            message: "Registro realizado com sucesso!",
            appearance: "success",
            auto_dismiss: true,
          },
        ]);
      }
    } catch (e) {
      setError([
        ...error,
        {
          message:
            getStatusCode(e) === "500"
              ? "Ocorreu um problema na solicitação, dados inválidos ou já existentes!"
              : "Ocorreu um problema na solicitação, mas já estamos trabalhando para uma solução! Agradecemos a compreenção.",
          appearance: "error",
          auto_dismiss: false,
        },
      ]);
    }
  }

  return (
    <div className={styles.container}>
      <ToastProvider>
        <Notifications msg={error} clear={() => setError([])} />
      </ToastProvider>
      <div className={styles.left} />
      <div className={styles.right}>
        <div className={styles.head}>
          <img src={logo} alt="" className={styles.logo} />
          <div className={styles.legend}>
            <div style={{ fontFamily: "Roboto" }}>Empresarial</div>
          </div>
        </div>
        {
          {
            0: (
              <div className={styles.body}>
                <form style={{ margin: "10px" }} onSubmit={Login}>
                  <Row>
                    <Col md={3} style={{ display: "flex" }}>
                      <FormGroup>
                        <ControlLabel
                          style={{ color: "#696969", fontWeight: "normal" }}
                        >
                          CNPJ
                        </ControlLabel>
                        <InputMask
                          type="text"
                          onFocus={true}
                          style={{ width: 300 }}
                          mask="99.999.999/9999-99"
                          className="form-control"
                          placeholder="00.000.000/0000-00"
                          onChange={(e) =>
                            setDocument(justNumbers(e.target.value))
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3} style={{ display: "flex" }}>
                      <FormGroup>
                        <ControlLabel
                          style={{ color: "#696969", fontWeight: "normal" }}
                        >
                          Senha de acesso
                        </ControlLabel>
                        <FormControl
                          type="password"
                          style={{ width: 300, fontFamily: "Roboto" }}
                          className="form-control"
                          placeholder="* * * *"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{ display: "flex", flexDirection: "column" }}>
                    <Col md={12}>
                      <Button
                        type="submit"
                        style={{
                          borderColor: "#008000",
                          backgroundColor: "#008000",
                          color: "#FFF",
                          width: "100%",
                          fontFamily: "Roboto",
                          marginTop: "5px",
                        }}
                        simple={true}
                      >
                        Conectar-se
                      </Button>
                    </Col>
                    <Col
                      md={12}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "left",
                      }}
                    >
                      <div
                        style={{
                          color: "gray",
                          borderWidth: 0,
                          backgroundColor: "#FFF",
                          fontFamily: "Roboto",
                          marginTop: "15px",
                          marginBottom: '100px',
                          fontSize: 12,
                        }}
                      >
                        Primeiro acesso?{" "}
                        <Link
                          onClick={() => clearForm(1)}
                          style={{
                            color: "#1761C5",
                            fontWeight: "bold",
                            opacity: 0.9,
                          }}
                        >
                          Registrar-se
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </form>
              </div>
            ),
            1: (
              <div className={styles.body}>
                <form style={{ margin: "10px" }} onSubmit={Register}>
                  <Row>
                    <Col md={3} style={{ display: "flex" }}>
                      <FormGroup>
                        <ControlLabel
                          style={{ color: "#696969", fontWeight: "normal" }}
                        >
                          E-mail
                        </ControlLabel>
                        <FormControl
                          type="email"
                          style={{ width: 300, fontFamily: "Roboto" }}
                          className="form-control"
                          placeholder="Escolha um email que utilize!"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3} style={{ display: "flex" }}>
                      <FormGroup>
                        <ControlLabel
                          style={{ color: "#696969", fontWeight: "normal" }}
                        >
                          CNPJ
                        </ControlLabel>
                        <InputMask
                          type="text"
                          onFocus={true}
                          style={{ width: 300 }}
                          mask="99.999.999/9999-99"
                          className="form-control"
                          placeholder="00.000.000/0000-00"
                          onChange={(e) =>
                            setDocument(justNumbers(e.target.value))
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3} style={{ display: "flex" }}>
                      <FormGroup>
                        <ControlLabel
                          style={{ color: "#696969", fontWeight: "normal" }}
                        >
                          Nome Fantasia
                        </ControlLabel>
                        <FormControl
                          type="text"
                          style={{ width: 300, fontFamily: "Roboto" }}
                          className="form-control"
                          placeholder="Como devemos chamar sua empresa?"
                          onChange={(e) => setFantasyName(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3} style={{ display: "flex" }}>
                      <FormGroup>
                        <ControlLabel
                          style={{ color: "#696969", fontWeight: "normal" }}
                        >
                          Senha para acesso
                        </ControlLabel>
                        <FormControl
                          type="text"
                          style={{ width: 300, fontFamily: "Roboto" }}
                          className="form-control"
                          placeholder="Sua melhor senha"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{ display: "flex", flexDirection: "column" }}>
                    <Col md={12}>
                      <div
                        style={{
                          width: 300,
                          textAlign: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <a
                          href="https://www.ramicard.com.br/privacy-policy"
                          style={{ fontSize: 12 }}
                        >
                          Aceito as Políticas de Privacidade e os Termos e
                          Condições de Uso.
                        </a>
                      </div>
                      <Button
                        type="submit"
                        style={{
                          borderColor: "#008000",
                          backgroundColor: "#008000",
                          color: "#FFF",
                          width: "100%",
                          fontFamily: "Roboto",
                          marginTop: "5px",
                        }}
                        simple={true}
                      >
                        Registrar-se
                      </Button>
                    </Col>
                    <Col md={12}>
                      <Button
                        onClick={() => clearForm(0)}
                        style={{
                          borderColor: "transparent",
                          backgroundColor: "transparent",
                          color: "#444",
                          width: "100%",
                          fontFamily: "Roboto",
                          marginTop: "5px",
                        }}
                        simple={true}
                      >
                        Voltar
                      </Button>
                    </Col>
                  </Row>
                </form>
              </div>
            ),
          }[page]
        }
        <div className={styles.footer}>
          © 2021 Ramicard!
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles({
  container: {
    height: "100vh",
    display: "flex",
  },

  left: {
    flex: 0.6,
    display: "flex",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundImage: `url(${wallpaper})`,
  },

  right: {
    flex: 0.4,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },

  head: {
    flex: 0.3,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  logo: {
    height: 158,
    width: 300,
  },

  legend: {
    marginLeft: 180,
    fontWeight: "bold",
    fontSize: 16,
  },

  body: {
    flex: 0.45,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  cnpj: {
    height: 40,
    width: 350,
    borderWidth: 1,
    borderColor: "#D3D3D3",
    borderRadius: "5px",
    padding: 20,
  },

  footer: {
    flex: 0.05,
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
  },
});
