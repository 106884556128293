import jsPDF from "jspdf";
import moment from "moment";
import { Formatacao } from "../../../utils/Functions/Formatacoes.jsx";
import Capitalize from "capitalize-pt-br";
import {
  contrato_01,
  contrato_02,
  contrato_03,
} from "../../PDF/Funcionario/ModeloBase.jsx";

export function ContratoRamicard(props) {
  // * Remoção dos campos undefined, false e null do campo value, junto a limitação de caracteres do campo
  const notNull = (value, length = String(value).length) =>
    String(value)
      .replace("undefined", "")
      .replace("false", "")
      .replace("null", "")
      .slice(0, length);

  // * Array de meses disponíveis durante o ano.
  let meses =
    "janeiro_fevereiro_março_abril_maio_junho_julho_agosto_setembro_outubro_novembro_dezembro".split(
      "_"
    );

  let contrato = new jsPDF();

  contrato.setProperties({ title: `${Capitalize(props.nome)}.pdf` });

  // #NOTA: 1º Página do contrato de funcionário.
  contrato.addImage(contrato_01, "PNG", 0, 0, 210, 297);

  contrato.setFontSize(10); // * Tamanho da fonte em 12
  contrato.setFont("times"); // * Estilo de fonte "Times New Roman"
  contrato.setFontType("normal"); // * Estilização da fonte "Normal"
  contrato.setTextColor("#474A51"); // * Cor da fonte "#474A51", (Cinza).

  contrato.text(24, 51, notNull(Capitalize(props.nome), 60));
  contrato.text(181, 51, notNull(Capitalize(props.data_nascimento)));

  contrato.text(19.5, 61.5, notNull(Capitalize(props.rg)));
  contrato.text(115, 61.5, notNull(Capitalize(Formatacao("CPF", props.cpf))));

  contrato.text(28.5, 72.5, notNull(Capitalize(props.endereco), 55));
  contrato.text(137, 72.5, notNull(Capitalize(props.numero)));
  contrato.text(176.5, 72.5, notNull(Capitalize(""), 12)); //#IMPORTANTE: Adicionar campo "complemento" no model de funcionário.

  contrato.text(24, 83, notNull(Capitalize(props.bairro), 19));
  contrato.text(69, 83, notNull(Capitalize(props.cep)));
  contrato.text(119, 83, notNull(Capitalize(props.cidade), 16));
  contrato.text(165.5, 83.3, notNull(props.estado));

  contrato.text(
    14.5,
    106,
    notNull(Capitalize(props.telefone.split(")")[0].replace("(", "")))
  );
  contrato.text(20, 106, notNull(Capitalize(props.telefone.split(")")[1])));
  contrato.text(
    108.5,
    106,
    notNull(Capitalize(props.celular.split(")")[0].replace("(", "")))
  );
  contrato.text(114, 106, notNull(Capitalize(props.celular.split(")")[1])));

  contrato.text(24, 114, notNull(Capitalize(props.email)));

  // #NOTA: 2º Página do contrato de funcionário.
  contrato.addPage();
  contrato.addImage(contrato_02, "PNG", 0, 0, 210, 297);

  contrato.text(24, 237.5, moment().format("DD"));
  contrato.text(45, 237.5, meses[Number(moment().format("MM") - 1)]);
  contrato.text(75, 237.5, moment().format("YYYY"));

  // #NOTA: 3º Página do contrato de funcionário.
  contrato.addPage();
  contrato.addImage(contrato_03, "PNG", 0, 0, 210, 297);

  contrato.text(27, 31, notNull(Capitalize(props.nome), 60));

  contrato.text(23, 41.8, notNull(Capitalize(props.rg)));
  contrato.text(117.6, 41.8, notNull(Capitalize(Formatacao("CPF", props.cpf))));

  contrato.text(
    31,
    52.2,
    notNull(Capitalize(props.empresa_responsavel.nome_fantasia), 60)
  );

  contrato.text(27, 154.5, moment().format("DD"));
  contrato.text(45, 154.5, meses[Number(moment().format("MM") - 1)]);
  contrato.text(73.5, 154.5, moment().format("YYYY"));

  window.open(contrato.output("bloburl"));
}
