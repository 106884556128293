import React from "react";
import Loading from '../../Components/Loading';
import NotFound from '../../Components/NotFound';
import MyAPI from "../../../../connections/Api.jsx";
import Notifications from "../../Components/Toast.jsx";
import Card from "../../../../components/Card/Card.jsx";

import { DateTime, Interval } from "luxon";
import { Button } from "react-bootstrap";
import { Grid, Row, Col, Table } from "react-bootstrap";
import { ToastProvider } from "react-toast-notifications";
import { inReal, forCNPJ } from "../../../../utils/Abstractions/Functions.jsx";
import { TimeZone, DateZone } from "../../../../utils/Abstractions/Variables.jsx";

const ButtonGenerate = {
    border: 0,
    color: "white",
    borderRadius: 3,
    marginRight: 10,
    marginBottom: 20,
    marginTop: 10,
    fontSize: "14px",
    background: "#4169E1",
    boxShadow: "0 1px 1px 1px rgba(0, 0, 0, .2)",
}

export default function LateTicket({ refresh }) {
    const headers = ["Codigo", "Empresa", "CNPJ", "valor", "Periodo", "Vencimento", "Atrasado em", "Situação"];

    const [data, setData] = React.useState(null);
    const [error, setError] = React.useState([]);

    React.useEffect(() => {
        async function fetchData() {
            try {
                const response = await MyAPI.getListOfCharges();

                setData(response.data.filter(e => e.situacao === 'Atrasado'));
            } catch (e) {
                setError([{ message: "Não foi possível buscar os dados solicitados!", appearance: "error", auto_dismiss: true }]);
            }
        }
        fetchData();
    }, []);

    if (data === null) return <Loading Heigth="350px" />

    const offsetBillet = async (id, status, key) => {
        const queryData = { situacao: status, alteracoes: { data: DateTime.local().setZone(DateZone).toFormat(TimeZone), responsavel: localStorage.getItem('Name'), motivo: status } }

        try {
            if (window.confirm('O boleto será gerado com vencimento para daqui 2 dias')) {
                const response = await MyAPI.postUpdateReceipt(id, queryData);

                if (response.data.situacao || false) data[key].situacao = response.data.situacao;
                if (response.data) setError([{ message: "Sucesso ao atualizar o status deste boleto!", appearance: "success", auto_dismiss: true }]);

                refresh();
            }
        } catch (e) {
            setError([{ message: "Erro ao atualizar o status deste boleto!", appearance: "error", auto_dismiss: true }]);
        }
    };

    const delayedDays = dueDate => {
        const currentDate = DateTime.now().setZone("America/Sao_paulo").toJSDate();
        const referenceDate = DateTime.fromFormat(`${dueDate} 00:00:00`, "dd/MM/yyyy HH:mm:ss").toJSDate();

        const dateRange = Interval.fromDateTimes(referenceDate, currentDate);

        return dateRange.length('days').toFixed(0);
    }

    const bodyData = () => data.map((voucher, key) => {
        return (
            <tr key={key}>
                <td>{`${voucher.empresa_id.registro}`.toUpperCase()}</td>
                <td>{`${voucher.empresa_id.nome_fantasia.split('').length > 0 ? voucher.empresa_id.nome_fantasia : voucher.empresa_id.razao_social}`.toUpperCase()}</td>
                <td>{forCNPJ(`${("00000000000000" + voucher.empresa_id.cnpj).slice(-14)}`)}</td>
                <td style={{ color: 'red' }}>{inReal(voucher.valor_boleto)}</td>
                <td>Mês {DateTime.fromFormat(voucher.vencimento_em, "dd/MM/yyyy").plus({ month: -1 }).toFormat('MM')}</td>
                <td>{voucher.vencimento_em}</td>
                <td style={{ color: 'red' }}>{delayedDays(voucher.vencimento_em)} dias em atraso</td>
                <td>{voucher.situacao}</td>
                <td>{<Button disabled={voucher.situacao === "Boleto Compensado" || voucher.situacao === "Boleto Gerado"} onClick={() => offsetBillet(voucher._id, "Boleto Gerado", key)} style={ButtonGenerate}>GERAR NOVO BOLETO</Button>}</td>
            </tr>
        );
    });


    return (
        <div className="content">
            <ToastProvider>
                <Notifications msg={error} clear={() => setError([])} />
            </ToastProvider>
            <Grid fluid>
                <Row>
                    <Col md={12}>
                        <Card
                            content={
                                <div align="left" style={{ margin: 20 }}>
                                    {
                                        data.length === 0 ?
                                            <NotFound Message="Não há boletos atrasados!" /> :
                                            <Table striped hover>
                                                <thead>
                                                    <tr>
                                                        {headers.map((prop, key) => {
                                                            return (
                                                                <th key={key} style={{ textAlign: "left", fontWeight: "bold", color: "#000000" }}>
                                                                    {prop}
                                                                </th>
                                                            );
                                                        })}
                                                    </tr>
                                                </thead>
                                                <tbody align="left">
                                                    {bodyData()}
                                                </tbody>
                                            </Table>
                                    }
                                </div>
                            }
                        />
                    </Col>
                </Row>
            </Grid>
        </div>
    );
}
