import socketio from 'socket.io-client';
import InputMask from 'react-input-mask';
import { Dropdown } from 'primereact/dropdown';
import img from '../../../assets/img/print.png';
import ApiRest from '../../../services/api.jsx';
import React, { useState, useEffect } from 'react';
import { printer } from '../../../utils/Relatorios.js';
import { serverAddress } from '../../../utils/Variaveis.js';
import Confirmar from '../../../assets/img/icon_confirm.png';
import Cancelar from '../../../assets/img/icon_cancelRed.png';
import lerQrcode from '../../../assets/img/icon_read_qrcode.png';
import gerarQrcode from '../../../assets/img/icon_create_qrcode.png';
import { yesterdayDate } from '../../../utils/Global.js';
import { calcInstallment } from '../../../utils/Tax.js';

import { Modal, Button, Image, Table, FormControl } from 'react-bootstrap';

export default function Payment(props) {
  const [temp, setTemp] = useState([]);
  const [cpf, setCpf] = useState(null);

  const [etapa, setEtapa] = useState(0);
  const [valor, setValor] = useState(0);
  const [canal, setCanal] = useState('');
  const [senha, setSenha] = useState(null);
  const [tokenUser, getTokenUser] = useState(null);
  const [fakePassword, setFakePassword] = useState([]);
  const [parcelsReleased, setParcels] = useState(null);
  const [selecionado, setSelecionado] = useState(null);
  const [imprimir, imprimirComprovante] = useState(null);
  const [credenciadoID, idCredenciado] = useState(undefined);

  const cabecalhos = [
    'Canal',
    'Data & Hora',
    'Valor',
    'Nº de Protocolo',
    'Imprimir',
  ];

  const GerarQrcode = (props) => {
    props.onHide();
    props.onQrcode();
  };

  async function buscarDados(props) {
    try {
      const DATA = await ApiRest.post('/usuario/login', { cpf, senha });

      if (DATA.data.error) {
        alert(`${DATA.data.message}, tente novamente.`);
      } else {
        try {
          getTokenUser(String(DATA.data.token));

          const DATAUser = await ApiRest.get('/usuario', {
            headers: { Authorization: `Bearer ${DATA.data.token}`, acess: 1 },
          });

          localStorage.setItem('user', String(DATAUser.data[0]._id));
          localStorage.setItem(
            'company',
            String(DATAUser.data[0].empresa_responsavel._id)
          );

          const DATAPAY = await ApiRest.get(
            `/usuario/validar-cobranca/${props.codigo_transfere
              .split('')
              .reverse()
              .join('')}`,
            {
              headers: { Authorization: `Bearer ${DATA.data.token}`, acess: 1 },
            }
          );

          if (DATAPAY.data.error) {
            alert(`${DATAPAY.data.message}`);
            setEtapa(0);
          } else {
            setCanal(DATAPAY.data.canal);
            setValor(DATAPAY.data.valor);
            let releasedParcels = [];

            for (
              let i = 0;
              i <
              (DATAPAY.data.max_parcelas <
              DATAUser.data[0].ramicard.canais
                .map((item) =>
                  item.canal === DATAPAY.data.canal ? item.parcelas_max : false
                )
                .filter((e) => e !== false)[0]
                ? DATAPAY.data.max_parcelas
                : DATAUser.data[0].ramicard.canais
                    .map((item) =>
                      item.canal === DATAPAY.data.canal
                        ? item.parcelas_max
                        : false
                    )
                    .filter((e) => e !== false)[0]);
              i++
            ) {
              if (i + 1 >= (props.canal.juros_a_partir || 5)) {
                releasedParcels.push(
                  `${Number(i + 1)}x - R$ ${calcInstallment(
                    DATAPAY.data.valor,
                    props.canal.taxa_parcelamento > 0
                      ? Number(props.canal.taxa_parcelamento) / 100
                      : 0.035,
                    i + 1
                  )
                    .toFixed(2)
                    .replace('.', ',')}*`
                );
              } else {
                releasedParcels.push(
                  `${Number(i + 1)}x - R$ ${(DATAPAY.data.valor / Number(i + 1))
                    .toFixed(2)
                    .replace('.', ',')}`
                );
              }
            }

            const ParcelasLiberadas = releasedParcels.map((item, key) =>
              item ? { name: item, key: key } : null
            );

            setParcels(ParcelasLiberadas);

            setEtapa(3);
          }
        } catch (err) {
          alert(
            `Não foi possível conectar-se ao servidor. Por favor, tente novamente em alguns minutos... ${err}`
          );
          setEtapa(0);
        }
      }
    } catch (err) {
      alert(
        `Não foi possível conectar-se ao servidor. Por favor, tente novamente em alguns minutos... ${err}`
      );
      setEtapa(0);
    }
  }

  const clearExit = (props) => {
    setCpf(null);
    setSenha(null);
    setEtapa(0);
    setSelecionado(null);
    setValor(0);
    setCanal('');
    setParcels([]);
    props.onHide();
    props.onCleared();
  };

  useEffect(() => {
    if (props.show === true) {
      idCredenciado(props.credenciado_data);
      const credenciado_id = props.informacoes;

      async function fetchData() {
        const socket = socketio(serverAddress, {
          query: { user_id: credenciado_id },
        });
        socket.on('Confirmacao_Pagamento', (dataFiles) => {
          console.log(dataFiles);
          imprimirComprovante([
            { dados: dataFiles, codigo: props.codigo_transfere },
          ]);
          setEtapa(4);
        });
      }

      fetchData();
    }
  }, [props]);

  async function Finalizar(selecionado, props) {
    if (selecionado === null || selecionado === undefined) {
      alert('Ops! Selecione o número de parcelas');
    } else {
      try {
        const DATA = await ApiRest.post(
          `/usuario/${await localStorage.getItem(
            'user'
          )}/pagar-cobranca/${props.codigo_transfere
            .split('')
            .reverse()
            .join('')}`,
          {
            canal: props.canal._id,
            parcelas: selecionado.key + 1,
            horario_confirmacao: yesterdayDate,
            confirmcao_pagamento: true,
            empresa_responsavel_id: await localStorage.getItem('company'),
            parcelamentoSelecionado: selecionado,
          },
          { headers: { Authorization: `Bearer ${tokenUser}`, acess: 1 } }
        );
        if (DATA.data.error) alert(`${DATA.data.message}`);
      } catch (err) {
        alert(
          `Não foi possível conectar-se ao servidor. Por favor, tente novamente em alguns minutos... ${err}`
        );
      }
    }
  }

  const islocked = (password) => {
    const pass = password
      .split('')
      .filter((e) => e !== '*' && e !== '')
      .join('');

    if (temp.length <= password.split('').length) {
      setTemp([...temp, pass]);
    } else {
      temp.pop();
      setTemp(temp);
    }

    setFakePassword(
      password.replace(
        `${password}`,
        `${password
          .split('')
          .map(() => '*')
          .join('')}`
      )
    );

    setSenha([...temp, pass].join(''));
  };

  return (
    // - Modal de Confirmação
    etapa === 0 ? (
      <Modal
        {...props}
        centered={`${true}`}
        style={{ margin: 'auto' }}
        onExit={() => clearExit(props)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div style={{ textAlign: 'center', color: 'rgba(35,142,35, .8)' }}>
              Selecione o método de pagamento!
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              justifyContent: 'center',
              flexDirection: 'row',
              textAlign: 'center',
            }}
          >
            <Button
              onClick={() => GerarQrcode(props)}
              style={{
                color: '#FFFFFF',
                fontWeight: 'bold',
                fontSize: 20,
                textAlign: 'center',
                height: 200,
                width: 200,
                backgroundColor: 'rgba(35,142,35, .8)',
                borderRadius: 10,
                borderColor: 'rgba(35,142,35, .8)',
                margin: 10,
              }}
            >
              <div
                style={{
                  justifyContent: 'center',
                  flexDirection: 'column',
                  marginBottom: 10,
                }}
              >
                <Image
                  src={gerarQrcode}
                  style={{ width: '60px', height: '60px' }}
                ></Image>
              </div>
              Gerar QRCode
              <p style={{ margin: 0, padding: 0 }}>Online</p>
            </Button>

            <Button
              onClick={() => setEtapa(1)}
              style={{
                color: '#FFFFFF',
                fontWeight: 'bold',
                fontSize: 20,
                textAlign: 'center',
                height: 200,
                width: 200,
                backgroundColor: 'rgba(35,142,35, .8)',
                borderRadius: 10,
                borderColor: 'rgba(35,142,35, .8)',
                margin: 10,
              }}
            >
              <div
                style={{
                  justifyContent: 'center',
                  flexDirection: 'column',
                  marginBottom: 10,
                }}
              >
                <Image
                  src={lerQrcode}
                  style={{ width: '60px', height: '60px' }}
                ></Image>
              </div>
              Pagar via CPF
              <p>Offline</p>
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    ) : etapa === 1 ? (
      <Modal
        {...props}
        centered={`${true}`}
        style={{ margin: 'auto' }}
        onExit={() => clearExit(props)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div style={{ textAlign: 'center', color: 'rgba(35,142,35, .8)' }}>
              Digite o CPF do Cliente
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div align='center'>
            <Table striped hover>
              <tbody align='center'>
                <tr>
                  <td>{`CPF: `}</td>
                  <td key={2}>
                    <InputMask
                      mask='999.999.999-99'
                      style={{ borderColor: '#000000', maxWidth: '200px' }}
                      className='form-control'
                      required={true}
                      placeholder={`000.000.000-00`}
                      onChange={(event) =>
                        setCpf(event.target.value.replace(/\D+/g, ''))
                      }
                    />
                  </td>
                  <td>
                    <Button
                      onClick={() => {
                        setEtapa(2);
                      }}
                      variant='contained'
                      bsStyle='info'
                      style={{
                        background: 'rgba(35,142,35, .8)',
                        border: 0,
                        borderRadius: 3,
                        boxShadow: '0 1px 1px 1px rgba(0, 0, 0, .2)',
                        color: 'white',
                        fontSize: '14px',
                      }}
                    >
                      {' '}
                      Confirmar
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setEtapa(0)}>Voltar</Button>
        </Modal.Footer>
      </Modal>
    ) : etapa === 2 ? (
      <Modal
        {...props}
        centered={`${true}`}
        style={{ margin: 'auto' }}
        onExit={() => clearExit(props)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div style={{ textAlign: 'center', color: 'rgba(35,142,35, .8)' }}>
              Insira a senha do cliente
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div align='center'>
            <Table striped hover>
              <tbody align='center'>
                <tr>
                  <td>{`Senha: `}</td>
                  <td key={1}> 
                    <FormControl
                      type='password'
                      variant='outlined'
                      placeholder='* * * *'
                      autoFocus={true}
                      onChange={(event) => setSenha(event.target.value)}
                    />
                  </td>
                  <td>
                    <Button
                      onClick={() => buscarDados(props)}
                      variant='contained'
                      bsStyle='info'
                      style={{
                        background: 'rgba(35,142,35, .8)',
                        border: 0,
                        borderRadius: 3,
                        boxShadow: '0 1px 1px 1px rgba(0, 0, 0, .2)',
                        color: 'white',
                        fontSize: '14px',
                      }}
                    >
                      Validar
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
            <span style={{ color: 'red' }}>
              Obs.: Deve-se utilizar a mesma senha do aplicativo do cliente.
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setEtapa(1)}>Voltar</Button>
        </Modal.Footer>
      </Modal>
    ) : etapa === 3 ? (
      <Modal
        {...props}
        centered={`${true}`}
        style={{ margin: 'auto' }}
        onExit={() => clearExit(props)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div style={{ textAlign: 'center', color: 'rgba(35,142,35, .8)' }}>
              Finalize a transação
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              justifyContent: 'center',
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: 30,
            }}
          >
            {`${canal} - R$ ${valor.toFixed(2).replace('.', ',')}`}
          </div>

          <div
            style={{
              justifyContent: 'center',
              textAlign: 'center',
              marginBottom: 20,
              marginTop: 20,
            }}
          >
            <Dropdown
              optionLabel='name'
              placeholder='Selecione o Nº de parcelas'
              value={selecionado}
              options={parcelsReleased}
              onChange={(e) => setSelecionado(e.value)}
            />
          </div>

          <div
            style={{
              justifyContent: 'center',
              flexDirection: 'row',
              textAlign: 'center',
            }}
          >
            <Button
              onClick={() => clearExit(props)}
              style={{
                color: '#FFFFFF',
                fontWeight: 'bold',
                fontSize: 16,
                textAlign: 'center',
                height: 100,
                width: 100,
                backgroundColor: 'rgba(255,0,0, .8)',
                borderRadius: 10,
                borderColor: 'rgba(255,0,0, .8)',
                margin: 10,
              }}
            >
              <div
                style={{
                  justifyContent: 'center',
                  flexDirection: 'column',
                  marginBottom: 10,
                }}
              >
                <Image
                  src={Cancelar}
                  style={{ width: '40px', height: '40px' }}
                ></Image>
              </div>
              Cancelar
            </Button>
            <Button
              onClick={() => Finalizar(selecionado, props)}
              style={{
                color: '#FFFFFF',
                fontWeight: 'bold',
                fontSize: 16,
                textAlign: 'center',
                height: 100,
                width: 100,
                backgroundColor: 'rgba(35,142,35, .8)',
                borderRadius: 10,
                borderColor: 'rgba(35,142,35, .8)',
                margin: 10,
              }}
            >
              <div
                style={{
                  justifyContent: 'center',
                  flexDirection: 'column',
                  marginBottom: 10,
                }}
              >
                <Image
                  src={Confirmar}
                  style={{ width: '40px', height: '40px' }}
                ></Image>
              </div>
              Confirmar
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => clearExit(props)}>Cancelar</Button>
        </Modal.Footer>
      </Modal>
    ) : etapa === 4 ? (
      <Modal
        {...props}
        centered={`${true}`}
        style={{ margin: 'auto' }}
        onExit={() => clearExit(props)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div style={{ textAlign: 'center', color: '#228b22' }}>
              Pagamento efetuado com sucesso!
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ textAlign: 'center' }}>
            <Table striped hover>
              <thead align='center'>
                <tr>
                  {cabecalhos.map((prop, key) => {
                    return <th key={key}>{prop}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {imprimir.map((prop, key) => {
                  return (
                    <tr key={key}>
                      <td>{prop.dados.Canal}</td>
                      <td>{`${prop.dados.Data}`}</td>
                      <td>{`R$ ${prop.dados.Venda.toFixed(2).replace(
                        '.',
                        ','
                      )}`}</td>
                      <td>{prop.dados.Codigo}</td>
                      <td>
                        <Button
                          onClick={() => {
                            printer(
                              prop.dados.Codigo,
                              prop.dados.Canal,
                              prop.dados.Data,
                              prop.dados.Venda,
                              credenciadoID.fantasia === undefined
                                ? credenciadoID.razao
                                : credenciadoID.fantasia,
                              'Ramicard',
                              prop.dados.Empresa,
                              prop.dados.Usuario,
                              prop.dados.Parcelas,
                              prop.dados.Obs,
                              false,
                              false,
                              prop.dados.Taxa_funcionario
                            );
                          }}
                          variant='contained'
                          bsStyle='info'
                          style={{
                            border: 0,
                            color: 'white',
                            borderRadius: 3,
                            fontSize: '14px',
                            background: 'rgba(35,142,35, .8)',
                            boxShadow: '0 1px 1px 1px rgba(0, 0, 0, .2)',
                          }}
                        >
                          <Image
                            src={img}
                            style={{ width: '20px', height: '20px' }}
                          ></Image>
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => props.onHide()}>Sair</Button>
        </Modal.Footer>
      </Modal>
    ) : (
      <>ERROR</>
    )
  );
}
