import React from "react";
import styled from 'styled-components';

import Loading from '../../Components/Loading';
import LateTicket from '../Charges/LateTicket.jsx'
import MyAPI from "../../../../connections/Api.jsx";
import Notifications from "../../Components/Toast.jsx";
import ListOfCharges from '../Charges/ListOfCharges.jsx';

import { Grid, Row, Col } from "react-bootstrap";
import { ToastProvider } from "react-toast-notifications";
import { inReal } from "../../../../utils/Abstractions/Functions.jsx";
import { StatsCard } from "../../../../components/StatsCard/StatsCard.jsx";

export const Content = styled.div`
    cursor: pointer;
`;

export default function Charges({ history }) {

    const [data, setData] = React.useState(null);
    const [error, setError] = React.useState([]);
    const [toOmit, setToOmit] = React.useState(false);
    const [page, setPage] = React.useState("ListOfCharges");
    const [refresh, setRefresh] = React.useState(false);

    React.useEffect(() => {
        async function fetchData() {
            try {
                const response = await MyAPI.getListOfCharges();

                setData(response.data);
            } catch (e) {
                setError([{ message: "Não foi possível buscar os dados solicitados!", appearance: "error", auto_dismiss: true }]);
            }
        }
        fetchData();
    }, [refresh]);

    if (data === null) return <Loading Heigth="350px" />

    const tickets = (situation = null, revert = false) =>
        data.filter(ticket => situation === null ? true : revert ? ticket.situacao !== situation : ticket.situacao === situation);

    return (
        <div className="content">
            <ToastProvider>
                <Notifications msg={error} clear={() => setError([])} />
            </ToastProvider>
            <Grid fluid>
                <Row>
                    <Col lg={3} sm={6}>
                        <Content onClick={() => setPage('ListOfCharges')}>
                            <StatsCard
                                isSelected={page === 'ListOfCharges'}
                                bigIcon={<i className="fa fa-file-text-o" aria-hidden="true" />}
                                statsText="Processador de boletos"
                                statsValue={`${tickets('Boleto Compensado', true).length} Boletos`}
                                statsIcon={<i className="fa fa-exclamation-circle" />}
                                statsIconText={`${tickets('Pendente').length} Boleto${tickets('Pendente') > 1 ? 's' : ''} aguardando confirmação`}
                            />
                        </Content>
                    </Col>
                    <Col lg={3} sm={6}>
                        <Content onClick={() => setPage('LateTicket')}>
                            <StatsCard
                                isSelected={page === 'LateTicket'}
                                bigIcon={<i style={{ color: data.length > 0 ? 'red' : 'black' }} className="fa fa-exclamation-triangle" aria-hidden="true" />}
                                statsText="Boletos Atrasados"
                                statsValue={`${tickets('Atrasado').length} Boleto${tickets('Atrasado') > 1 ? 's' : ''}`}
                                statsIcon={<i className="fa fa-exclamation-circle" />}
                                statsIconText="Mais informações"
                            />
                        </Content>
                    </Col>
                    <Col lg={3} sm={6}>
                        <Content onClick={() => setToOmit(!toOmit)}>
                            <StatsCard
                                bigIcon={toOmit? <i className="fa fa-pie-chart" aria-hidden="true" />:<i className="fa fa-eye-slash" />}
                                statsText="Total Recebido"
                                statsValue={toOmit? `${inReal(tickets('Boleto Compensado').map(e => e.valor_boleto).reduce((acumulador, elementoAtual) => acumulador + elementoAtual, 0))}`: '-'}
                                statsIcon={<i className="fa fa-exclamation-circle" />}
                                statsIconText={toOmit? `${inReal(tickets('Boleto Compensado', true).map(e => e.valor_boleto).reduce((acumulador, elementoAtual) => acumulador + elementoAtual, 0))} em aberto`: 'Clique aqui para visualizar'}
                            />
                        </Content>
                    </Col>
                    <Col lg={3} sm={6}>
                        <Content onClick={() => history.push("/administrativo/relatorios")}>
                            <StatsCard
                                bigIcon={<i className="fa fa-bar-chart" aria-hidden="true" />}
                                statsText="Todas as Cobranças"
                                statsValue="Relatórios"
                                statsIcon={<i className="fa fa-exclamation-circle" />}
                                statsIconText="Histórico de cobranças"
                            />
                        </Content>
                    </Col>
                </Row>
            </Grid>
            {{
                ListOfCharges: <ListOfCharges refresh={() => setRefresh(!refresh)} />,
                LateTicket: <LateTicket refresh={() => setRefresh(!refresh)} />
            }[page]}
        </div>
    );
}