import React, { useState, useEffect } from 'react';
import * as S from './Styles';
import CustomButton from '../Button';
import {  
  Modal,
  Col,
  Row,
  FormGroup,  
  FormControl,
  ControlLabel,
  Table
} from 'react-bootstrap';
import Spinner from "react-spinner-material";

import api from '../../../../services/api.jsx';
import { OnlyNumbers } from '../../../../utils/CompareStrings.js';

export default function RecisaoModal({ closeModal, data, ...props }) {
  const [infoRecisao, setInfoRecisao] = useState(undefined);  
  const [observacoesRecisao, setObservacoesRecisao] = useState('');
  const [loading, setLoading] = useState(true);  

  const gravarRecisao = async () => {
    try {
      debugger
      const response = await api.post(
        `https://7kwt37owscnmeeccvhrhrqbwu40acslu.lambda-url.sa-east-1.on.aws/rescission/${ OnlyNumbers(data.cpf)}`, {observacoesRecisao}
      );

      if (response.data.error) {
        alert(response.data.error);
      } else {
        alert('Rescisão gravada com sucesso!');
        closeModal()          
      }  
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if ( data ) {
      setInfoRecisao(data);
      setLoading(false);      
    }
  
  }, [data])

  return (
    <Modal {...props} bsSize='large' style={{ margin: 'auto' }}>
      <Modal.Header closeButton onHide={closeModal}>
        <Modal.Title>Ramicard - Calculo de Recisão</Modal.Title>        
      </Modal.Header>

      { loading ? 
        <Modal.Body
          style={{
            overflowY: 'scroll',
            whiteSpace: 'nowrap',
          }}
        >
          <Row style={{display: 'flex'}}>
            <Col lg={12} style={{ 
                textAlign: 'center', minWidth: '50%', 
                display: 'flex', flexDirection: 'column', 
                alignItems: 'center', justifyContent: 'center', 
                padding: 24}}>
              <Spinner size={400} spinnercolor={"#333"} spinnerwidth={2} visible={true} />
              <span style={{ fontSize: 20, fontWeight: 200 , marginTop: 24 }}>Carregando dados de Recisão...</span>
            </Col>
          </Row>
        </Modal.Body> 
      : 
        <Modal.Body
          style={{
            overflowY: 'scroll',
            whiteSpace: 'nowrap',
          }}
        >
          { infoRecisao.transactions_count === 0 || (! infoRecisao.transactions_list) ? 
            <Row style={{display: 'flex'}}>
              <Col lg={12} style={{ textAlign: 'center', padding: 24}}>
              <span style={{ fontSize: 20, fontWeight: 200 }}>Sem parcelas em aberto para recisão do funcionário.</span>
              </Col>            
            </Row>
          : 
            <>
              <Row style={{display: 'flex'}}>
                <Col lg={12} style={{ textAlign: 'center', minWidth: '50%'}}>
                  <Table striped hover>
                    <thead align='center'>
                      <tr>
                        <td>
                          <b>Data da Compra</b>
                        </td>
                        <td>
                          <b>Credenciado</b>
                        </td>
                        <td>
                          <b>Parcela</b>
                        </td>
                        <td>
                          <b>Valor</b>
                        </td>
                      </tr>
                    </thead>
                    <tbody align='center'>
                      { infoRecisao.transactions_list.map((prop, key) => {
                        return (
                          <tr key={key}>
                            <td>{prop.data_transacao}</td>                            
                            <td>{prop.credenciado}</td>
                            <td>{prop.parcela_atual}/{prop.parcelas}</td>
                            <td>{`R$ ${prop.valor
                              .toFixed(2)
                              .replace('.', ',')}`}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot align='center'>
                      <tr>
                        <td></td>
                        <td></td>
                        <td>
                          <b>Total em Aberto</b>
                        </td>                        
                        <td>
                          <b>{`R$ ${infoRecisao.total_value
                            .toFixed(2)
                            .replace('.', ',')}`}</b>
                        </td>                        
                      </tr>
                    </tfoot>
                  </Table>
                </Col>            
              </Row>
              <Row style={{ margin: '8px' }}>
                <Col md={12}>          
                  <FormGroup>
                    <ControlLabel>Observações da Recisão</ControlLabel>
                    <FormControl                 
                      type="text"
                      required={true}
                      bsClass="form-control"
                      rows={3}
                      onChange={(event) =>
                        setObservacoesRecisao(event.target.value)
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>            
            </>
          }
        </Modal.Body>
      }

      { loading ? <></> : 
        <Modal.Footer>
          <S.Footer>
            <CustomButton  
              onClick={() => closeModal()}
              style={{ background: "rgba(255,0,0, .8)", color: "rgb(255,255,255)", marginLeft: '15px', marginBottom: '0px', marginRight: '4px' }}
              content='Cancelar'
            />
            { infoRecisao.transactions_count > 0 ?
              <CustomButton
                onClick={() => gravarRecisao()}
                style={{ marginLeft: '15px', marginBottom: '0px' }}
                content='Registrar Recisão'
              />            
            : <></> }
          </S.Footer>
        </Modal.Footer>
      }
    </Modal>
  );
}
