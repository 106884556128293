import styled from 'styled-components';

export const Content = styled.div`
    flex: 1; 
    margin: 20px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    justify-content: center;
    flex-direction: column ;
    background-color: #FFF;
    height: ${(props) => props.heigth || '800px'};
`;