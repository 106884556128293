import "../../assets/css/styleApp.css";
import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import api from "../../services/api.jsx";
import Button from "@material-ui/core/Button";
import { FormControl } from "react-bootstrap";
import Image from "../../assets/img/logo_rami.png";
import image from "../../assets/img/background.jpeg";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import { login, howisadmin } from "../../services/autenticacoes.jsx";

export default function SimpleCard({ history }) {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [error, setError] = useState("");

  async function confimarEnvio(event) {
    event.preventDefault();

    try {
      const response = await api.post("/administrativo/login", {
        email,
        senha,
      });

      if (response.data.error) {
        setError(response.data.message);
      } else {
        login(response.data.token);
        howisadmin(response.data.administrador);
        history.push("/administrativo/dashboard");
      }
    } catch (err) {
      alert("Ops! Erro ao tentar acessar o servidor.");
    }
  }

  return (
    <div className={classes.background}>
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <img src={Image} alt="" className={classes.media} />
          <Typography
            variant="body2"
            component="p"
            align="center"
            style={{ fontSize: "14px", fontFamily: "Roboto" }}
          >
            Seja bem vindo! Faça <strong>login</strong> para acessar nosso
            sistema
          </Typography>
          <p
            align="Center"
            style={{ fontSize: "12px", color: "red", margin: "auto" }}
          >
            {error}
          </p>
          <form
            className={classes.form}
            onSubmit={confimarEnvio}
            style={{ fontFamily: "Roboto", fontSize: "15px" }}
          >
            <label style={{ fontWeight: "normal" }}>E-mail *</label>
            <FormControl
              type="email"
              name="email"
              bsClass="form-control"
              autoComplete="email"
              margin="dense"
              variant="outlined"
              required={true}
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
            <label style={{ fontWeight: "normal" }}>Senha *</label>
            <FormControl
              type="password"
              variant="outlined"
              required={true}
              value={senha}
              onChange={(event) => setSenha(event.target.value)}
            />
            <Button
              variant="contained"
              className={classes.button}
              type="submit"
            >
              Confirmar
            </Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}

const useStyles = makeStyles({
  background: {
    backgroundImage: `url(${image})`,
    position: "absolute",
    height: "100vh",
    width: "100vw",
    display: "block",
    top: 0,
    left: 0,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    margin: "auto",
  },

  card: {
    background: `#FFF`,
    display: "block",
    alignItems: "auto",
    borderradius: "8px",
    boxshadow: "1px 2px 8px rgba(0, 0, 0, 0.65)",
    height: "500px",
    width: "329px",
    margin: "auto",
    marginTop: "35px",
  },

  form: {
    display: "flex",
    flexDirection: "column",
  },

  input: {
    width: "100%",
    fontSize: "18px",
  },

  media: {
    width: "300px",
  },

  button: {
    marginTop: "10px",
    background: "rgb(35, 165, 3)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0 3px 3px 2px rgba(0, 0, 0, .2)",
    color: "white",
    height: 40,
    padding: "0 20px",
    fontSize: "14px",
  },
});
