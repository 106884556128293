import React, { useState, useEffect } from 'react';
import * as S from './Styles';
import CustomButton from '../Button';
import MyAPI from '../../../../connections/Api.jsx';
import { NormalizeString } from '../../../../utils/CompareStrings';

import {
  ListGroup,  
  FormGroup,
  FormControl,
  Checkbox,
  Modal,
  Col,
  Row,
} from 'react-bootstrap';
import Spinner from "react-spinner-material";

export default function PontosRamicardModal({ closeModal, usarLocalSelecionado, ...props }) {
  const [pontos, setPontos] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(true);
  const [selecionado, setSelecionado] = useState(undefined)

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await MyAPI.getPontosRamicard();

        setPontos(response.data.filter(ponto => ponto.credenciado_id === undefined || ponto.credenciado_id === null ));
        setFiltered(response.data.filter(ponto => ponto.credenciado_id === undefined || ponto.credenciado_id === null ));

        setLoading(false)
      } catch (e) {
        console.log(e);
        setLoading(false)
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFiltered(
      filter.trim() === '' ? pontos : pontos.filter( ponto => NormalizeString(`${ponto.titulo} ${ponto.endereco} ${ponto.categoria} ${ponto.especialidade}`).includes(NormalizeString(filter)))
    );
  }, [filter, pontos])

  const usarSelecionado = () => {
    usarLocalSelecionado(filtered[selecionado])
    closeModal()
  }

  return (
    <Modal {...props} style={{ margin: 'auto' }}>
      <Modal.Header closeButton onHide={closeModal}>
        <Modal.Title>Pontos Ramicard</Modal.Title>
        <Row style={{marginTop: '24px'}}>
          <Col md={12}>
            <FormGroup bsSize="large">
              <FormControl type="text" placeholder="Pesquisar..." onChange={(e) => setFilter(e.target.value) }/>
            </FormGroup>
          </Col>
        </Row>
      </Modal.Header>
      
      { loading ? 
        <Modal.Body
          style={{
            overflowY: 'scroll',
            whiteSpace: 'nowrap',
          }}
        >
          <Row style={{display: 'flex'}}>
            <Col lg={12} style={{ textAlign: 'center', minWidth: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 24}}>
              <Spinner size={400} spinnercolor={"#333"} spinnerwidth={2} visible={true} />
              <span style={{ fontSize: 20, fontWeight: 200 , marginTop: 24 }}>Carregando Pontos Cadastrados...</span>
            </Col>
          </Row>
        </Modal.Body> 
      : 
        <Modal.Body
          style={{
            overflowY: 'scroll',
            maxHeight: '60vh',
            whiteSpace: 'nowrap',
          }}
        >
          <Row style={{display: 'flex'}}>
            <Col md={12}>
              <ListGroup componentClass="ul">
              {filtered.map(
                (ponto, key) => (
                  <li key={key} className="list-group-item" onClick={() => {}}>
                    <Row>
                      <Col md={1}>
                        <Checkbox value={key} checked={selecionado === key} inline onChange={ () => setSelecionado(key) }></Checkbox>
                      </Col>
                      <Col md={11}>
                        <Row>
                          <Col>
                            <b>{ponto.titulo}</b> ({ponto.categoria}, {ponto.especialidade})
                          </Col>
                          <Col>
                            {ponto.endereco}<br/>
                            {ponto.telefone}
                          </Col>
                        </Row>
                      </Col>                      
                    </Row>                    
                  </li>         
                ))
              }            
              </ListGroup>
            </Col>
          </Row>
        </Modal.Body>
      }

      <Modal.Footer>
        <S.Footer>
          <CustomButton            
            onClick={() => usarSelecionado() }
            style={{ marginLeft: '15px', marginBottom: '0px' }}
            content='Usar Selecionado'
          />
        </S.Footer>
      </Modal.Footer>      
    </Modal>
  );
}
