import styled from 'styled-components';

export const Image = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 20px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-top: 10px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  margin-top: 4px;
  margin-left: 12px;
  padding-left: 34.5px;

  border-left: 2px solid darkgray;
`;

export const Card = styled.div`
  width: 100%;
  display: flex;

  flex-direction: column;
  justify-content: space-between;

  padding: 15px;
  margin-top: 10px;
  margin-bottom: 10px;

  border-radius: 10px;
  box-shadow: 1px 4px 4px 0 rgba(0, 0, 0, 0.4);
`;

export const JSON = styled.div`
  margin-bottom: 15px;
  padding-left: 10px;
  border-left: 2px solid ${(props) => props.tagColor};
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
