import React from 'react';
import { Content, Text } from './Styles/styles'
import Spinner from "react-spinner-material";

export default function Loading({ ...props }) {
    return (
        <Content {...props}>
            <Spinner size={120} spinnercolor={"#333"} spinnerwidth={2} visible={true} />
            <Text>Carregando...</Text>
        </Content>
    )
}