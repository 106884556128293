import React from 'react';
import { Modal, Button } from "react-bootstrap";

export default function MyModal(props) {
   return (
      <Modal {...props} centered={`${true}`} style={{ margin: 'auto' }}>
         <Modal.Header closeButton>
            <Modal.Title>
               Ops! algo deu errado...
            </Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <p>Sua sessão expirou, por favor faça login novamente.</p>
         </Modal.Body>
         <Modal.Footer>
            <Button href="/login/administrativo">Fazer Login</Button>
         </Modal.Footer>
      </Modal>
   );
}