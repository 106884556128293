import "../../assets/css/styleApp.css";
import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import api from "../../services/api.jsx";
import Button from "@material-ui/core/Button";
import { FormControl } from "react-bootstrap";
import Image from "../../assets/img/logo_rami.png";
import image from "../../assets/img/background.jpeg";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import { login, howisadmin } from "../../services/autenticacoes.jsx";

export default function LoginCredenciado({ history }) {
  const [erro, envErro] = useState("");
  const [erro2, envErro2] = useState("");
  const [senha, setSenha] = useState("");
  const [cnpj_cpf, setcnpj_cpf] = useState("");
  const [fistAcess, FistAcess] = useState(false);
  const [nomeCompleto, setNomeCompleto] = useState("");

  const classes = useStyles();

  async function confimarEnvio(event) {
    event.preventDefault();

    try {
      const resposta = await api.post("/credenciado/login", {
        cnpj_cpf,
        senha,
      });
      if (resposta.data.error) {
        envErro(resposta.data.message);
      } else {
        login(resposta.data.token);
        localStorage.setItem(
          "Credenciado",
          String(resposta.data.credenciado.nome)
        );
        howisadmin(resposta.data.credenciado._id);
        history.push("/credenciado/servicos");
      }
    } catch (error) {
      envErro("Servidor offline, Tente novamente mais tarde!");
    }
  }

  async function confirmaCadastro(event) {
    event.preventDefault();
    try {
      const resposta = await api.post("/credenciado/cadastro", {
        nome: nomeCompleto,
        cnpj_cpf,
        senha,
      });
      if (resposta.data.error) {
        envErro2(resposta.data.message);
      } else {
        alert("Cadastro realizado com sucesso! Faça login para continuar.");
        setSenha("");
        setNomeCompleto("");
        setcnpj_cpf("");
        envErro2("");
        FistAcess(false);
      }
    } catch (error) {
      envErro2("Servidor offline, Tente novamente mais tarde!");
    }
  }

  return (
    <div className={classes.background}>
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <img src={Image} alt="" className={classes.media} />
          {!fistAcess ? (
            <>
              <Typography
                variant="body2"
                component="p"
                align="center"
                style={{
                  fontSize: "14px",
                  fontFamily: "Roboto",
                  marginBottom: "19px",
                }}
              >
                Seja bem vindo! Faça <strong>login</strong> para acessar nosso
                sistema.
              </Typography>

              {erro && (
                <p
                  align="Center"
                  style={{
                    fontSize: "12px",
                    color: "red",
                    margin: "auto",
                    marginTop: "-18px",
                    width: "100%",
                  }}
                >
                  {erro}
                </p>
              )}

              <form className={classes.form} onSubmit={confimarEnvio}>
                <label style={{ fontWeight: "normal" }}>CPF/CNPJ</label>
                <FormControl
                  type="number"
                  name="cnpj_cpf"
                  margin="dense"
                  variant="outlined"
                  required={true}
                  value={cnpj_cpf}
                  onChange={(event) => setcnpj_cpf(event.target.value)}
                />
                <label style={{ fontWeight: "normal" }}>Senha</label>
                <FormControl
                  type="password"
                  variant="outlined"
                  required={true}
                  value={senha}
                  onChange={(event) => setSenha(event.target.value)}
                />
                <Button
                  variant="contained"
                  className={classes.button}
                  type="submit"
                >
                  Confirmar
                </Button>

                <div
                  style={{
                    width: "100%",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <Button
                    className={classes.buttonFistAcess}
                    onClick={() => {
                      FistAcess(true);
                      setSenha("");
                      setcnpj_cpf("");
                      envErro("");
                    }}
                  >
                    Primeiro acesso
                  </Button>
                  <Button
                    className={classes.buttonResetAcess}
                    onClick={() => {}}
                  >
                    Resetar senha
                  </Button>
                </div>
              </form>
            </>
          ) : (
            <>
              <form className={classes.form2} onSubmit={confirmaCadastro}>
                {erro2 && (
                  <p
                    align="Center"
                    style={{
                      fontSize: "12px",
                      color: "red",
                      margin: "auto",
                      marginTop: "-18px",
                      width: "100%",
                    }}
                  >
                    {erro2}
                  </p>
                )}
                <label style={{ fontWeight: "normal" }}>Nome Fantasia *</label>
                <FormControl
                  type="text"
                  bsClass="form-control"
                  name="cadastro_NOME"
                  placeholder="Nome Fantasia"
                  margin="dense"
                  variant="outlined"
                  required={true}
                  value={nomeCompleto}
                  onChange={(event) => setNomeCompleto(event.target.value)}
                />
                <label style={{ fontWeight: "normal", marginTop: "8px" }}>
                  CNPJ/CPF *
                </label>
                <FormControl
                  type="number"
                  bsClass="form-control"
                  name="cadastro_CPF_CNPJ"
                  margin="dense"
                  placeholder="CPF ou CNPJ"
                  variant="outlined"
                  required={true}
                  value={cnpj_cpf}
                  onChange={(event) => setcnpj_cpf(event.target.value)}
                />
                <label style={{ fontWeight: "normal", marginTop: "8px" }}>
                  Senha *
                </label>
                <FormControl
                  type="text"
                  name="cadastro_SENHA"
                  variant="outlined"
                  placeholder="Sua melhor senha!"
                  required={true}
                  value={senha}
                  onChange={(event) => setSenha(event.target.value)}
                />
                <Typography
                  variant="body2"
                  component="p"
                  align="center"
                  style={{
                    fontSize: "14px",
                    fontFamily: "Roboto",
                    marginBottom: "8px",
                    marginTop: "8px",
                  }}
                >
                  <a href="https://www.ramicard.com.br/privacy-policy">
                    Aceito as Políticas de Privacidade e os Termos e Condições
                    de Uso.
                  </a>
                </Typography>
                <Button
                  variant="contained"
                  className={classes.button2}
                  type="submit"
                >
                  Cadastrar
                </Button>

                <Button
                  className={classes.buttonFistAcess}
                  onClick={() => {
                    FistAcess(false);
                    setSenha("");
                    setcnpj_cpf("");
                    setNomeCompleto("");
                    envErro2("");
                  }}
                >
                  Voltar
                </Button>
              </form>
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
}

const useStyles = makeStyles({
  CNPJ_INPUT: {
    WebkitAppearance: "none",
    borderColor: "#000000",
  },

  background: {
    margin: "auto",
    width: "100vw",
    height: "100vh",
    display: "block",
    position: "absolute",
    backgroundSize: "cover",
    backgroundImage: `url(${image})`,
    backgroundPosition: "center center",
  },

  card: {
    height: "600",
    width: "329px",
    margin: "auto",
    display: "block",
    marginTop: "35px",
    background: `#FFF`,
    alignItems: "auto",
    borderradius: "8px",
    boxshadow: "1px 2px 8px rgba(0, 0, 0, 0.65)",
  },

  form: {
    display: "flex",
    flexDirection: "column",
  },

  form2: {
    display: "flex",
    flexDirection: "column",
  },

  input: {
    width: "100%",
    fontSize: "18px",
  },

  media: {
    width: "300px",
  },

  button: {
    border: 0,
    height: 40,
    color: "white",
    borderRadius: 3,
    fontSize: "14px",
    marginTop: "10px",
    padding: "0 20px",
    background: "rgb(35, 165, 3)",
    boxShadow: "0 3px 3px 2px rgba(0, 0, 0, .2)",
  },

  button2: {
    border: 0,
    height: 40,
    color: "white",
    borderRadius: 3,
    fontSize: "14px",
    marginTop: "10px",
    padding: "0 20px",
    background: "rgb(35, 165, 3)",
    boxShadow: "0 3px 3px 2px rgba(0, 0, 0, .2)",
  },

  buttonFistAcess: {
    height: 40,
    color: "black",
    fontSize: "12px",
    marginTop: "10px",
    padding: "0 20px",
    textDecoration: "underline",
  },

  buttonResetAcess: {
    height: 40,
    color: "black",
    fontSize: "12px",
    marginTop: "10px",
    padding: "0 20px",
  },
});
