import api from '../../../services/api.jsx';
import Card from '../../../components/Card/Card.jsx';
import React, { useState, useEffect } from 'react';
import { getToken } from '../../../services/autenticacoes.jsx';
import Capitalize from 'capitalize-pt-br';
import MyModal from '../../../components/Modal/ModalCredenciado.jsx';
import {
  Grid,
  Row,
  Col,
  Table,
  FormControl,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';
import Button from '../../../components/CustomButton/CustomButton.jsx';

import MetodoPagamento from '../Components/Payment.jsx';
import ModalConfirmacao from '../Components/Confirmation.jsx';

export default function Vendas() {
  const [data, setData] = useState([]);
  const [valor, setValor] = useState(0);
  const [info, setinfo] = useState({});

  const [vendas, setVendas] = useState([]);
  const [observacao, setObservacao] = useState(null);
  const [error, setError] = useState(false);
  const [visivel, setVisivel] = useState(false);
  const [metodo, setMetodo] = useState(false);
  const [selecionado, setSelecionado] = useState(null);
  const [canais, setCanais] = useState(null);
  const [faixas, setFaixas] = useState(null);

  const cabecalhos = ['Canal', 'Valor de Venda', 'Observação', 'Confirmar'];

  const valor_da_Venda = (
    <Tooltip id='edit_tooltip'>Permitido o uso de "," (Virgula)</Tooltip>
  );
  const nota_de_observacao = (
    <Tooltip id='edit_tooltip'>
      Adicione uma observação de no máx. 50 caracteres
    </Tooltip>
  );
  const efeturar_Venda = <Tooltip id='remove_tooltip'>Vender</Tooltip>;

  useEffect(() => {
    async function buscarDados() {
      try {
        const response = await api.get('/credenciado', {
          headers: { Authorization: `Bearer ${getToken()}`, acess: 3 },
        });
        setData(response.data);
        setVendas(response.data[0].canais_ativos);

        const canais = await api.get('/canais', {
          headers: { Authorization: `Bearer ${getToken()}`, acess: 3 },
        });
        setCanais(canais.data);

        const layers = await api.get('/layers', {
          headers: { Authorization: `Bearer ${getToken()}`, acess: 3 },
        });
        setFaixas(layers.data);
      } catch (error) {
        alert(error);
        setError(true);
      }
    }
    buscarDados();
  }, []);

  const faixaDoCanal = (idFaixa) => {
    let idx = faixas.findIndex( faixa => faixa._id === idFaixa )
    let faixa;
    if ( idx < 0 ) {
      faixa = faixas.find( faixa => faixa.faixa_padrao === true )            
    } else {
      faixa = faixas[idx]
    }

    return faixa
  }

  async function ConfirmacaoFinal(
    canal,
    parcelas_max,
    valor_de_venda,
    observacao
  ) {
    // TODO: Pegar taxa de canais
    // const taxa_da_ramicard = vendas.filter((item) =>
    //   item.canal === canal ? item.taxa : 0
    // );
    debugger
    const idx = vendas.findIndex((item) => item.canal === canal);
    const taxa_da_ramicard = vendas[idx].taxa || 0;

    const idxCanal = canais.findIndex((_canal) => _canal.canal === canal);

    const faixaCanal = faixaDoCanal(canais[idxCanal]['layer_id']);

    const idxFaixaParcela = faixaCanal['faixas'].findIndex(
      (f) => Number(valor_de_venda) >= Number(f.valorMinimo) && ( (Number(f.valorMaximo) === 0) || (Number(valor_de_venda) <= Number(f.valorMaximo)))
    );

    const max_parcelas =
      faixaCanal['faixas'][idxFaixaParcela]['parcelas'];

    const parcelas_liberadas =
      max_parcelas < parcelas_max ? max_parcelas : parcelas_max;

    const response = await api.post(
      `/credenciado/${data[0]._id}/gerar-cobranca/`,
      {
        valor: valor_de_venda,
        canal,
        max_parcelas: parcelas_liberadas,
        taxa_ramicard: Number(taxa_da_ramicard),
        observacao: observacao,
      },
      { headers: { Authorization: `Bearer ${getToken()}`, acess: 3 } }
    );

    setinfo({
      informacoes: data[0]._id,
      codigo_transfere: String(response.data._id).split('').reverse().join(''),
      canal: canais[idxCanal],
      credenciado_data: {
        razao: data[0].razao_social,
        fantasia: data[0].nome_fantasia,
      },
    });
    setMetodo(true);
  }

  const correction = (value) => value.replace(',', '.').replace('R$ ', '');

  return (
    <div className='content'>
      <Grid fluid>
        <Row>
          <MyModal show={error} onHide={() => setError(false)} />
          <MetodoPagamento
            {...info}
            show={metodo}
            onHide={() => setMetodo(false)}
            onQrcode={() => setVisivel(true)}
            onCleared={() => {
              setValor(null);
              setObservacao(null);
              setSelecionado(-1);
            }}
          />

          <ModalConfirmacao
            {...info}
            show={visivel}
            onHide={() => setVisivel(false)}
            onCleared={() => {
              setValor(null);
              setObservacao(null);
              setSelecionado(-1);
            }}
          />

          <Col md={12}>
            <Card
              title={data.length > 0 ? 'Vendas' : ''}
              ctTableFullWidth
              ctTableResponsive
              content={
                <div>
                  {vendas.length > 0 ? (
                    <div>
                      <Table striped hover>
                        <thead>
                          <tr>
                            {cabecalhos.map((prop, key) => {
                              return (
                                <th
                                  style={{ color: '#000', textAlign: 'center' }}
                                  key={key}
                                >
                                  {prop}
                                </th>
                              );
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {vendas.map((prop, key) => {
                            return (
                              <tr key={key}>
                                <td align='center'>{Capitalize(prop.canal)}</td>
                                <td align='center'>
                                  <OverlayTrigger
                                    placement='top'
                                    overlay={valor_da_Venda}
                                  >
                                    <FormControl
                                      key={prop.canal}
                                      type='text'
                                      style={{
                                        borderColor: '#000000',
                                        maxWidth: '200px',
                                      }}
                                      required={true}
                                      onFocusCapture={() => {
                                        setSelecionado(key);
                                        setValor(null);
                                        setObservacao(null);
                                      }}
                                      value={key === selecionado ? valor : ''}
                                      bsClass='form-control'
                                      placeholder={`R$ 00,00`}
                                      onChange={(event) =>
                                        setValor(event.target.value)
                                      }
                                    />
                                  </OverlayTrigger>
                                </td>
                                <td align='center'>
                                  <OverlayTrigger
                                    placement='top'
                                    overlay={nota_de_observacao}
                                  >
                                    <FormControl
                                      key={prop.canal}
                                      type='text'
                                      maxLength={50}
                                      style={{
                                        borderColor: '#000000',
                                        maxWidth: '300px',
                                      }}
                                      onFocusCapture={() => {
                                        setSelecionado(key);
                                        setObservacao(null);
                                      }}
                                      value={
                                        key === selecionado ? observacao : ''
                                      }
                                      bsClass='form-control'
                                      placeholder={`Observações`}
                                      onChange={(event) =>
                                        setObservacao(event.target.value)
                                      }
                                    />
                                  </OverlayTrigger>
                                </td>
                                <td align='center'>
                                  <OverlayTrigger
                                    placement='top'
                                    overlay={efeturar_Venda}
                                    onClick={() => {
                                      valor <= 0
                                        ? alert(
                                            `Digite um valor válido para realizar a venda em ${Capitalize(
                                              prop.canal
                                            )}`
                                          )
                                        : ConfirmacaoFinal(
                                            prop.canal,
                                            prop.parcelas_max,
                                            correction(valor),
                                            observacao
                                          );
                                    }}
                                  >
                                    <Button
                                      disabled={
                                        key === selecionado ? false : true
                                      }
                                      variant='contained'
                                      bsStyle='info'
                                      style={{
                                        background: 'rgba(35,142,35, .8)',
                                        border: 0,
                                        borderRadius: 3,
                                        boxShadow:
                                          '0 1px 1px 1px rgba(0, 0, 0, .2)',
                                        color: 'white',
                                        fontSize: '14px',
                                      }}
                                    >
                                      {' '}
                                      Confirmar Venda
                                    </Button>
                                  </OverlayTrigger>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}
