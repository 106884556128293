/**
 * Calculate the installment value
 * @param {*} presentValue Loan's value ( 1000 ) 
 * @param {*} rate The % tax rate ( 0,035 = 3,5%)
 * @param {*} qtyInstallments Number of installments (5, 10 ...)
 */
 export const calcInstallment = function(presentValue, rate, qtyInstallments) {
    const val1 = Math.pow((1 + rate), qtyInstallments);
    return presentValue * ( (val1 * rate) / ( val1 - 1) );
}

/**
 * Calculate the index for a given tax or installment value
 * @param {*} presentValue Loan's value ( 1000 ) 
 * @param {*} rate The % tax rate ( 0,035 = 3,5%)
 * @param {*} qtyInstallments Number of installments (5, 10 ...)
 * @param {*} installmentValue (Optional) Value of installment
 */
export const calcInstallmentIndex = function(presentValue, rate, qtyInstallments, installmentValue = calcInstallment(presentValue, rate, qtyInstallments)) {
    return (installmentValue / presentValue).toFixed(8);
}

/**
 * Generates a full simulation for a loan containing the tax and amortization at any installment. 
 * It returns also the total interest of the loan and the installment index
 * @param {*} presentValue Loan's value ( 1000 ) 
 * @param {*} rate The % tax rate ( 0,035 = 3,5%)
 * @param {*} qtyInstallments Number of installments (5, 10 ...)
 */
export const simulation = function(presentValue, rate, qtyInstallments) {
    const installmentValue = calcInstallment(presentValue, rate, qtyInstallments);
    let installments = [];
    let totalInterest = 0;
    let installmentIndex = calcInstallmentIndex(presentValue, rate, qtyInstallments, installmentValue);
    
    for (let idx = 0; idx < qtyInstallments; idx++) {
        const interest = presentValue * rate
        const amortization = installmentValue - interest;
        const debitBalance = presentValue - amortization;

        installments.push({ installment: idx+1, value: installmentValue, interest, amortization, debitBalance })
        presentValue -= amortization;
        totalInterest += interest;
    }

    return {installments, installmentIndex, totalInterest};
}

export default { calcInstallment, calcInstallmentIndex, simulation };