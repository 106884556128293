import styled from "styled-components";

export const WarapperButtons = styled.div`
  margin-top: 25px;
`;

export const Icon = styled.i`
  font-size: 14px;
  color: #ffffff;
`;
