import React, { useState, useEffect } from 'react';
import * as S from './Styles';
import CustomButton from '../Button';
import MapSection from './Map.jsx';

import {
  Modal,
  Col,
  Row,
} from 'react-bootstrap';
import Spinner from "react-spinner-material";

import axios from 'axios';

export default function MapaModal({ closeModal, data, usarLocalSelecionado, ...props }) {
  const [loading, setLoading] = useState(true);
  const [location, setLocation] = useState('');

  useEffect(() => {
    async function fetchData() {
      try {
        if ( data.latitude && data.longituee ) {
          setLocation({
            address: `${data.endereco}`,
            lat: data.latitude,
            lng: data.longitude
          })
        } else {
          if ( data.endereco ) {
            const result = await axios.get(
              `https://maps.googleapis.com/maps/api/geocode/json?address=${data.endereco},SP,BR&key=AIzaSyC4zHKqfSDvzufq7RVNXCvKR4IZpwGgtvo`
            )
    
            setLocation({
              address: `${data.endereco}`,
              lat: result.data.results[0].geometry.location.lat,
              lng: result.data.results[0].geometry.location.lng
            });
          }
        }
        setLoading(false)
      } catch (e) {
        console.log(e);
        setLoading(false)
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  
  const usarSelecionado = () => {
    usarLocalSelecionado(location);
    closeModal()
  }

  const updateLocation = (lat, lng) => {
    setLocation({...location, lat, lng});
  }

  return (
    <Modal {...props} bsSize='large' style={{ margin: 'auto' }}>
      <Modal.Header closeButton onHide={closeModal}>
        <Modal.Title>Localizar Credenciado</Modal.Title>        
      </Modal.Header>
      
      { loading ? 
        <Modal.Body
          style={{
            overflowY: 'scroll',
            whiteSpace: 'nowrap',
          }}
        >
          <Row style={{display: 'flex'}}>
            <Col lg={12} style={{ textAlign: 'center', minWidth: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 24}}>
              <Spinner size={400} spinnercolor={"#333"} spinnerwidth={2} visible={true} />
              <span style={{ fontSize: 20, fontWeight: 200 , marginTop: 24 }}>Obtendo localização pelo endereço...</span>
            </Col>
          </Row>
        </Modal.Body> 
      : 
        <Modal.Body
          style={{
            overflowY: 'scroll',
            maxHeight: '80vh',
            whiteSpace: 'nowrap',
          }}
        >
          <Row style={{display: 'flex'}}>
            <Col md={12}>
              <MapSection updateLocation={ (lat, lng) => updateLocation(lat, lng) } location={location} zoomLevel={17} />
            </Col>
          </Row>
        </Modal.Body>
      }

      <Modal.Footer>
        <S.Footer>
          <CustomButton            
            onClick={() => usarSelecionado() }
            style={{ marginLeft: '15px', marginBottom: '0px' }}
            content='Coletar Localização'
          />
        </S.Footer>
      </Modal.Footer>      
    </Modal>
  );
}
