import jsPDF from 'jspdf';
import Capitalize from 'capitalize-pt-br';
import { img64 } from '../variables/Variables.jsx';

const printer = (
  Codigo,
  Canal,
  Data,
  Venda,
  local,
  metodo,
  empresa,
  cliente,
  Parcelas,
  observacao,
  parcela_atual,
  segunda_via,
  taxa_ramicard
) => {
  let doc;

  if (segunda_via) {
    doc = new jsPDF('r', 'mm', [315, 600]);
  } else {
    doc = new jsPDF('l', 'mm', [841.79, 595.28]);
  }

  console.log(empresa);

  const date = new Date();

  const totalBruto =
    Venda > 0
      ? taxa_ramicard === null
        ? Venda / Parcelas
        : Venda * taxa_ramicard
      : Venda;

  // - 1º Via do Recibo Ramicard

  doc.setFont('Courier');
  doc.setFontType('normal');

  doc.addImage(String(img64), 'PNG', 1, 1, 297, 210);

  doc.setFontSize(12);
  doc.text(33, 21.5, Codigo);

  doc.setFontSize(8);
  doc.text(
    41.7,
    15,
    `${
      String(date.getDate()).length === 1
        ? `0${date.getDate()}`
        : `${date.getDate()}`
    }/${
      String(date.getMonth() + 1).length === 1
        ? `0${date.getMonth() + 1}`
        : `${date.getMonth() + 1}`
    }/${date.getFullYear()} ${date.toTimeString().split(' ')[0]}`
  );

  doc.setFontSize(12);
  doc.text(11.5, 38, Capitalize(String(local).slice(0, 35)));

  doc.setFontSize(12);
  doc.text(12, 62.4, Capitalize(Canal));

  doc.setFontSize(8);
  doc.text(41.7, 72, Data);

  doc.setFontSize(10);
  doc.text(
    65.3,
    62.5,
    `${!parcela_atual ? '1' : parcela_atual}/${
      Parcelas === undefined ? '1' : Parcelas
    }`
  );
  doc.text(84.4, 62.4, `${String(totalBruto.toFixed(2).replace('.', ','))}`);

  doc.text(78, 92.3, `R$ ${String(Venda.toFixed(2).replace('.', ','))}`);

  doc.text(50, 81.5, `${metodo}`);

  doc.text(28, 113.6, `${String(empresa).slice(0, 35)}`);
  doc.text(27, 124.8, `${String(cliente).slice(0, 35)}`);

  doc.setFontSize(10); // - Tamanho da fonte
  doc.text(11, 133, `Observações:`);

  doc.setFontSize(8); // - Tamanho da fonte
  doc.text(
    14,
    138,
    `${observacao ? String(observacao).slice(0, 34) : 'Sem observações'}`
  ); // - Nova Atualização: Campo de observação.
  doc.text(14, 142, `${observacao ? String(observacao).slice(34) : ''}`);

  // - 2º Via do Recibo Ramicard

  doc.setFontSize(12);
  doc.text(138, 21.5, Codigo);

  doc.setFontSize(8);
  doc.text(
    145.7,
    15,
    `${
      String(date.getDate()).length === 1
        ? `0${date.getDate()}`
        : `${date.getDate()}`
    }/${
      String(date.getMonth() + 1).length === 1
        ? `0${date.getMonth() + 1}`
        : `${date.getMonth() + 1}`
    }/${date.getFullYear()} ${date.toTimeString().split(' ')[0]}`
  );

  doc.setFontSize(12);
  doc.text(116, 38, Capitalize(String(local).slice(0, 35)));

  doc.setFontSize(12);
  doc.text(120, 62.4, Capitalize(Canal));

  doc.setFontSize(8);
  doc.text(145.7, 72, Data);

  doc.setFontSize(10);
  doc.text(
    171,
    62.5,
    `${!parcela_atual ? '1' : parcela_atual}/${
      Parcelas === undefined ? '1' : Parcelas
    }`
  );
  doc.text(190, 62.4, `${String(totalBruto.toFixed(2).replace('.', ','))}`);

  doc.text(184, 92.3, `R$ ${String(Venda.toFixed(2).replace('.', ','))}`);

  doc.text(155.3, 81.5, `${metodo}`);

  doc.text(134, 113.6, `${String(empresa).slice(0, 35)}`);
  doc.text(133, 124.8, `${String(cliente).slice(0, 35)}`);

  doc.setFontSize(10); // - Tamanho da fonte
  doc.text(117, 133, `Observações:`);

  doc.setFontSize(8); // - Tamanho da fonte
  doc.text(
    117,
    138,
    `${observacao ? String(observacao).slice(0, 34) : 'Sem observações'}`
  ); // - Nova Atualização: Campo de observação.
  doc.text(117, 142, `${observacao ? String(observacao).slice(34) : ''}`);

  window.open(doc.output('bloburl'));
};

export { printer };
