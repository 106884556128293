import moment from "moment";
import { DateTime } from "luxon";
// eslint-disable-next-line
import api from "../../../services/api.jsx";
import React, { useState, useEffect } from "react";
import Card from "../../../components/Card/Card.jsx";
import DatePicker from "react-16-bootstrap-date-picker";
import MyModal from "../../../components/Modal/MyModal.jsx";
import { DatePikerConfig } from "../../../utils/Abstractions/Variables.jsx";
import {
  generalReport,
  detailedReport,
} from "../../../services/PDF/Credenciado/Credenciado.jsx";
import { getToken } from "../../../services/autenticacoes.jsx";
import {
  Grid,
  Row,
  Col,
  Button,
  Table,
  FormGroup,
  ControlLabel,
} from "react-bootstrap";

export default function TableList() {
  const [vendas, setVendas] = useState([]);
  const [error, setError] = useState(false);
  const [accredited, setAccredited] = useState([]);
  const [dataFinal, setDataFinal] = useState(null);
  const [dataInicial, setDataInicial] = useState(null);
  
  async function listarCredenciados() {
    try {
      const response = await api.get("/credenciado", {
        headers: { Authorization: `Bearer ${getToken()}`, acess: 3 },
      });
      if (response.data.error) {
      } else {
        setAccredited(response.data[0]);
        
        const credenciado = await api.get(
          `/historico/${response.data[0]._id}?dataInicial=${dataInicial}&dataFinal=${dataFinal}`,
          { headers: { Authorization: `Bearer ${getToken()}`, acess: 3 } }
        );

        setVendas(
          credenciado.data
            .filter((item) => item.confirmcao_pagamento !== null)
            .filter((item) => item.parcela_atual === 1)
            .reverse()
        );              
      }
    } catch (error) {
      setError(true);
    }
  }    

  const cabecalhos = [    
    "Data Consumo",
    "Nº de Operações",
    "Valor bruto",
    "Taxa Ramicard",
    "Valor líquido",
  ];

  const buscarTaxa = (canal) =>
    accredited.canais_ativos
      .map((e) => (e.canal === canal ? e.taxa : null))
      .filter((e) => e !== null)[0] / 100;

  const totaly_value = (model) => {
    if (model === "brut") {
      return vendas        
        .filter((item) => item.situacao !== "Cancelado")
        .reduce((somador, atual) => somador + atual.valor, 0)
        .toLocaleString("pt-br", { style: "currency", currency: "BRL" });
    } else if (model === "desc") {
      return vendas        
        .filter((item) => item.situacao !== "Cancelado")
        .reduce(
          (somador, atual) => somador + atual.valor * buscarTaxa(atual.canal),
          0
        )
        .toLocaleString("pt-br", { style: "currency", currency: "BRL" });
    } else {
      return vendas        
        .filter((item) => item.situacao !== "Cancelado")
        .reduce(
          (somador, atual) =>
            somador + (atual.valor - atual.valor * buscarTaxa(atual.canal)),
          0
        )
        .toLocaleString("pt-br", { style: "currency", currency: "BRL" });
    }
  };

  const trasacoesTotais = (data) => {
    return `${
      vendas
        .filter((e) => e.data_transacao.split(' ')[0] === data)
        .filter((e) => e.situacao !== "Cancelado").length
    }`;
  };

  const valorTotalEmpresasTotal = (data, model) => {
    if (model === "brut") {
      return Number(
        vendas
          .filter((e) => e.data_transacao.split(' ')[0] === data)
          .filter((e) => e.situacao !== "Cancelado")
          .map((e) => e.valor)
          .reduce((acumulador, elementoAtual) => acumulador + elementoAtual, 0)
      ).toLocaleString("pt-br", { style: "currency", currency: "BRL" });
    } else if (model === "desc") {
      return Number(
        vendas
          .filter((e) => e.data_transacao.split(' ')[0] === data)
          .filter((e) => e.situacao !== "Cancelado")
          .map((e) => e.valor * (e.taxa_ramicard / 100))
          .reduce((acumulador, elementoAtual) => acumulador + elementoAtual, 0)
      ).toLocaleString("pt-br", { style: "currency", currency: "BRL" });
    } else {
      return Number(
        vendas
          .filter((e) => e.data_transacao.split(' ')[0] === data)
          .filter((e) => e.situacao !== "Cancelado")
          .map((e) => e.valor - e.valor * (e.taxa_ramicard / 100))
          .reduce((acumulador, elementoAtual) => acumulador + elementoAtual, 0)
      ).toLocaleString("pt-br", { style: "currency", currency: "BRL" });
    }
  };

  const vendasPorEmpresaGeral = () =>
    vendas
      .filter((item) => item.situacao !== "Cancelado")
      .filter(function (a) {
        return (
          !this[JSON.stringify(a.data_transacao.split(' ')[0])] &&
          (this[JSON.stringify(a.data_transacao.split(' ')[0])] = true)
        );
      }, Object.create(null));

  const vendasPorEmpresaDetalhado = () =>
    vendas.filter((item) => item.situacao !== "Cancelado");

  const selectDate = (value, name) => {
    if (name === "dataInicial") {
      setDataInicial(DateTime.fromISO(value).toFormat("dd/MM/yyyy"));
    } else if (name === "dataFinal") {
      setDataFinal(DateTime.fromISO(value).toFormat("dd/MM/yyyy"));
    }
  };

  return (
    <div className="content" id="print">
      <Grid fluid>
        <Row>
          <MyModal show={error} onHide={() => setError(false)} />
          <Col md={12}>
            <Card
              title={
                <>
                  <p align="center" style={{ fontSize: 20 }}>
                    Extrato de vendas de{" "}
                    <strong>
                      {dataFinal === null || dataFinal.length <= 0
                        ? moment().format("MMMM/YYYY").toUpperCase()
                        : moment(dataFinal.split("/").reverse().join(""))
                            .format("MMMM/YYYY")
                            .toUpperCase()}
                    </strong>
                  </p>
                </>
              }
              content={
                <>
                  <Row>
                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: "#000" }}>
                          Período inicial
                        </ControlLabel>

                        <DatePicker
                          showClearButton={false}
                          placeholder="dd/mm/aaaa (Inicio)"
                          value={
                            dataInicial === null
                              ? ""
                              : DateTime.fromFormat(
                                  dataInicial,
                                  "dd/MM/yyyy"
                                ).toISO()
                          }
                          monthLabels={DatePikerConfig.months}
                          dayLabels={DatePikerConfig.week}
                          onChange={(e) => selectDate(e, "dataInicial")}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={2}>
                      <FormGroup>
                        <ControlLabel style={{ color: "#000" }}>
                          Período Final
                        </ControlLabel>
                        <DatePicker
                          showClearButton={false}
                          placeholder="dd/mm/aaaa (Fim)"
                          value={
                            dataFinal === null
                              ? ""
                              : DateTime.fromFormat(
                                  dataFinal,
                                  "dd/MM/yyyy"
                                ).toISO()
                          }
                          monthLabels={DatePikerConfig.months}
                          dayLabels={DatePikerConfig.week}
                          onChange={(e) => selectDate(e, "dataFinal")}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={8} align="left" style={{ marginTop: 25 }}>
                      <Button
                        disabled={dataFinal === null || dataInicial === null}
                        onClick={() => listarCredenciados()}
                        style={{
                          backgroundColor: `${"rgba(35,142,35, .8)"}`,
                          color: "#FFFFFF",
                          fontSize: 16,
                          marginRight: 10,
                          borderColor: "rgba(35,142,35, .8)",
                        }}
                        simple={true}
                        type="button"
                        bsSize="sm"
                      >
                        <i className="fa fa-search" aria-hidden="true" />{" "}
                        Pesquisar
                      </Button>

                      <Button
                        disabled={
                          !vendas.filter(
                            (item) => item.situacao !== "Cancelado"
                          ).length > 0
                        }
                        onClick={() => {
                          vendas.length <= 0
                            ? alert(
                                "Carregando guias, tente novamente em alguns minutos!"
                              )
                            : generalReport(
                                vendasPorEmpresaGeral(),
                                vendas,
                                accredited,
                                dataInicial,
                                dataFinal
                              );
                        }}
                        style={{
                          backgroundColor: `${"rgba(35,142,35, .8)"}`,
                          color: "#FFFFFF",
                          fontSize: 16,
                          borderColor: "rgba(35,142,35, .8)",
                          marginRight: 10,
                        }}
                        simple={true}
                        type="button"
                        bsSize="sm"
                      >
                        <i className="fa fa-print" aria-hidden="true" /> Relat.
                        Simples
                      </Button>

                      <Button
                        disabled={
                          !vendas.filter(
                            (item) => item.situacao !== "Cancelado"
                          ).length > 0
                        }
                        onClick={() => {
                          vendas.length <= 0
                            ? alert(
                                "Carregando guias, tente novamente em alguns minutos!"
                              )
                            : detailedReport(
                                vendasPorEmpresaDetalhado().sort((a, b) =>
                                  a.usuario_id.nome > b.usuario_id.nome
                                    ? 1
                                    : a.usuario_id.nome < b.usuario_id.nome
                                    ? -1
                                    : 0
                                ),
                                vendas.sort((a, b) =>
                                  a.usuario_id.nome > b.usuario_id.nome
                                    ? 1
                                    : a.usuario_id.nome < b.usuario_id.nome
                                    ? -1
                                    : 0
                                ),
                                accredited,
                                dataInicial,
                                dataFinal
                              );
                        }}
                        style={{
                          backgroundColor: `${"rgba(35,142,35, .8)"}`,
                          color: "#FFFFFF",
                          fontSize: 16,
                          borderColor: "rgba(35,142,35, .8)",
                        }}
                        simple={true}
                        type="button"
                        bsSize="sm"
                      >
                        <i className="fa fa-print" aria-hidden="true" /> Relat.
                        Detalhado
                      </Button>
                    </Col>
                  </Row>
                </>
              }
            />

            <Card
              content={
                <div>
                  <div align="center" style={{ margin: 20 }}>
                    <Table striped hover>
                      <thead>
                        <tr>
                          {cabecalhos.map((prop, key) => {
                            return (
                              <th
                                key={key}
                                style={{
                                  textAlign: "center",
                                  fontWeight: "bold",
                                  color: "#000000",
                                }}
                              >
                                {prop}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody align="center">
                        {vendas
                          .filter(function (a) {
                            return (
                              !this[
                                JSON.stringify(
                                  a.data_transacao.split(' ')[0]
                                )
                              ] &&
                              (this[
                                JSON.stringify(
                                  a.data_transacao.split(' ')[0]
                                )
                              ] = true)
                            );
                          }, Object.create(null))                          
                          .map((prop, key) => {                            
                            return (
                              <tr key={key}>                              
                                <td>{prop.data_transacao.split(' ')[0]}</td>
                                <td>
                                  {trasacoesTotais(
                                    prop.data_transacao.split(' ')[0]
                                  ) === 1 ? (
                                    `${trasacoesTotais(
                                      prop.data_transacao.split(' ')[0]
                                    )} venda(s) finalizada`
                                  ) : trasacoesTotais(
                                      prop.data_transacao.split(' ')[0]
                                    ) <= 0 ? (
                                    <div
                                      style={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                        color: "red",
                                      }}
                                    >
                                      Transações Canceladas
                                    </div>
                                  ) : (
                                    `${trasacoesTotais(
                                      prop.data_transacao.split(' ')[0]
                                    )} vendas finalizadas`
                                  )}
                                </td>
                                <td>
                                  {valorTotalEmpresasTotal(
                                    prop.data_transacao.split(' ')[0],
                                    "brut"
                                  )}
                                </td>
                                <td>
                                  {valorTotalEmpresasTotal(
                                    prop.data_transacao.split(' ')[0],
                                    "desc"
                                  )}
                                </td>
                                <td>
                                  {valorTotalEmpresasTotal(
                                    prop.data_transacao.split(' ')[0]
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        <tr>                          
                          <td />
                          <td />
                          <td>
                            Total Bruto:
                            <p style={{ fontWeight: "bold" }}>
                              {totaly_value("brut")}
                            </p>
                          </td>
                          <td>
                            Taxa Ramicard Total:
                            <p style={{ fontWeight: "bold" }}>
                              {totaly_value("desc")}
                            </p>
                          </td>
                          <td>
                            Total Líquido:
                            <p style={{ fontWeight: "bold" }}>
                              {totaly_value()}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}
